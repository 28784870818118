import _ from "lodash";

//All our application constants wll be stored here

/* ----------------ENDPOINTS ----------------------- */
    const ENDPOINT_FOR_AUTH_SLICE = "/auth";
    const ENDPOINT_FOR_CAMPAIGN_SLICE = "/campaigns";
    const ENDPOINT_FOR_CANDIDATE_SERVICES = "/candidates";
    const ENDPOINT_FOR_CLIENT_SLICE = "/clients";
    const ENDPOINT_FOR_INVOICE_SLICE = "/invoices";
    const ENDPOINT_FOR_PENDING_QUOTE_SLICE = "/pending-quotes";
    const ENDPOINT_FOR_USER_SLICE = "/users";
    

/* ----------------END OF ENDPOINTS ----------------------- */

/* ----------------QUERY SETTINGS -------------------------- */
    const PAGINATION_LIMIT = 10;
    const SORT_ORDER_ASCEND = "asc";
    const SORT_ORDER_DESCEND = "desc";
    const REFETCH_THRESHOLD_IN_MINUTES = 10;
/* ----------------END OF QUERY SETTINGS -------------------------- */

/* ----------------REQUEST A QUOTE CONSTANTS -------------------- */
    const QUOTE_ENTRY_FORM_FRIENDLY = 1;
    const QUOTE_ENTRY_FORM_FRIENDLY_TEXT = "friendly";
    const QUOTE_ENTRY_FORM_FRIENDLY_URL_STEP = "friendly-form";

    const QUOTE_ENTRY_FORM_REGULAR = 2;
    const QUOTE_ENTRY_FORM_REGULAR_TEXT = "regular";
    const QUOTE_ENTRY_FORM_REGULAR_URL_STEP = "regular-entry-form";

    const QUOTE_FRIENDLY_STEP_1 = "step-1";
    const QUOTE_FRIENDLY_STEP_2 = "step-2";
    const QUOTE_FRIENDLY_STEP_3 = "step-3";
    const QUOTE_FRIENDLY_STEP_4 = "step-4";
    const QUOTE_FRIENDLY_STEP_5 = "step-5";
    const QUOTE_FRIENDLY_STEP_6 = "step-6";
    const QUOTE_FRIENDLY_STEP_7 = "step-7";
    const QUOTE_FRIENDLY_STEP_8 = "step-8";
    const QUOTE_FRIENDLY_STEP_9 = "step-9";
    const QUOTE_FRIENDLY_STEP_10 = "step-10";
    const QUOTE_FRIENDLY_STEP_11 = "step-11";

    const PUBLICATION_ADDED_TYPE_MANUAL = 1;
    const PUBLICATION_ADDED_TYPE_CONTRACT = 2;
    const PUBLICATION_ADDED_TYPE_RECOMMENDATION = 3;

    const PUBLICATION_TYPE_ONLINE = 1;
    const PUBLICATION_TYPE_PRINT = 2;
    const PUBLICATION_TYPE_BOTH = 3;

    const PUBLICATION_PRINT_TYPE_LINE_AD = 1;
    const PUBLICATION_PRINT_TYPE_DISPLAY = 2;


    const salaryType = {
        SALARY:1,
        HOURLY:2,
        NOT_SPECIFIED:3,
    }

    const getSalaryTypeList = ()=>{
        return salaryType;
    }

    const getSalaryLabel = salaryType=>{
        let returnData = "";
        switch(salaryType){
            case 1:returnData = "Starting Salary"; break;
            case 2:returnData = "Hourly Rate"; break;
            case 3:returnData = "Not specified"; break;
            default: returnData = ""; 
        }

        return returnData;
    }

    const jobType = {
        FULL_TIME:1,
        PART_TIME:2,
        CONTRACTOR:3,
        NOT_AVAILABLE:4,
        TEMPORARY:5,
    }

    const getJobTypeList = ()=>{
        return jobType;
    }

    const getJobTypeLabel = jobType=>{
        let returnData = "";
        //console.log(jobType);
        switch(jobType){
            case 1:returnData = "Full Time"; break;
            case 2:returnData = "Part Time"; break;
            case 3:returnData = "Contractor"; break;
            case 4:returnData = "Not Available"; break;
            default: returnData = ""; 
        }
        //console.log(returnData);

        return returnData;
    }

    const applicationDeadlineType = {
        SELECT_DEADLINE:"select-deadline",
        OPEN_UNTIL_FILLED:"open-until-filled",
    }

    const getApplicationDeadlineTypeList = ()=>{
        return applicationDeadlineType;
    }

    const startDateType = {
        IMMEDIATELY:"immediately",
        FUTURE_DATE:"future-date",
    }

    const getStartDateTypeList = ()=>{
        return startDateType;
    }

    const startDateTypeDbValue = {
        IMMEDIATELY:1,
        FUTURE_DATE:2,
    }

    const getStartDateTypeDbList = ()=>{
        return startDateTypeDbValue;
    }

    const endDateType = {
        MAXIMIZE_RUNTIME:"maximize-runtime",
        STOP_ON_APPLICATION_DEADLINE:"stop-ads-on-application-deadline",
        STOP_ON_FUTURE_DATE:"stop-ads-on-selected-date",
        
    }

    const getEndDateTypeList = ()=>{
        return endDateType;
    }

    const endDateTypeDbValue = {
        MAXIMIZE_RUNTIME:1,
        STOP_ON_APPLICATION_DEADLINE:2,
        STOP_ON_FUTURE_DATE:3,
    }

    const getEndDateTypeDbList = ()=>{
        return endDateTypeDbValue;
    }


    
/* ----------------END OF REQUEST A QUOTE CONSTANTS -------------------- */


/* ----------------CAMPAIGN RELATED CONSTANTS -------------------- */

    const CAMPAIGN_GET_FOR_ALL_VIEWS= "get-campaigns-for-all-views";
    const CAMPAIGN_ALL = "All";
    const CAMPAIGN_STATUS_ACTIVE = "Active";
    const CAMPAIGN_STATUS_DRAFT = "Draft";
    const CAMPAIGN_STATUS_ARCHIVED = "Archived";

    const CAMPAIGN_TAB_ACTIVE = "Active";
    const CAMPAIGN_TAB_ALL = "All";
    const CAMPAIGN_TAB_ARCHIVED= "Archived";
    const CAMPAIGN_TAB_DRAFT= "Draft";

    /*Campaign detail url view */
    const CAMPAIGN_DETAILS_URL_READY_FOR_APPROVAL = "ready-for-approval";
    const CAMPAIGN_DETAILS_URL_REVIEW_BY_JE = "review-in-progress";
    const CAMPAIGN_DETAILS_URL_PROCESSING = "processing";
    const CAMPAIGN_DETAILS_URL_PUBLISHED = "published";
    const CAMPAIGN_DETAILS_URL_CANCELED = "canceled";
    const CAMPAIGN_DETAILS_URL_EXPIRED = "expired";
    const CAMPAIGN_DETAILS_URL_ALL = "show-all";


    /*Campaign detail tabs */
    const CAMPAIGN_DETAILS_TAB_READY_FOR_APPROVAL = "Ready for Approval";
    const CAMPAIGN_DETAILS_TAB_REVIEW_BY_JE = "Review in Progress";
    const CAMPAIGN_DETAILS_TAB_PROCESSING = "Processing";
    const CAMPAIGN_DETAILS_TAB_PUBLISHED = "Published";
    const CAMPAIGN_DETAILS_TAB_CANCELED = "Canceled";
    const CAMPAIGN_DETAILS_TAB_EXPIRED = "Expired";
    const CAMPAIGN_DETAILS_TAB_ALL = "Show All";

    /*Campaign detail Badge display tabs */
    const campaignItemBadgeDisplayMapping = {
        [CAMPAIGN_DETAILS_TAB_READY_FOR_APPROVAL]:"Review",
        [CAMPAIGN_DETAILS_TAB_REVIEW_BY_JE]:"Review By JE",
        [CAMPAIGN_DETAILS_TAB_PROCESSING]:"Processing",
        [CAMPAIGN_DETAILS_TAB_PUBLISHED]:"Published",
        [CAMPAIGN_DETAILS_TAB_CANCELED]:"Canceled",
        [CAMPAIGN_DETAILS_TAB_EXPIRED]:"Expired",
    }

    /* Campaign detail url mapping*/
    const campaignItemUrlByStatusTabMapping = {
        [CAMPAIGN_DETAILS_TAB_READY_FOR_APPROVAL]:CAMPAIGN_DETAILS_URL_READY_FOR_APPROVAL,
        [CAMPAIGN_DETAILS_TAB_REVIEW_BY_JE]:CAMPAIGN_DETAILS_URL_REVIEW_BY_JE,
        [CAMPAIGN_DETAILS_TAB_PROCESSING]:CAMPAIGN_DETAILS_URL_PROCESSING,
        [CAMPAIGN_DETAILS_TAB_PUBLISHED]:CAMPAIGN_DETAILS_URL_PUBLISHED,
        [CAMPAIGN_DETAILS_TAB_CANCELED]:CAMPAIGN_DETAILS_URL_CANCELED,
        [CAMPAIGN_DETAILS_TAB_EXPIRED]:CAMPAIGN_DETAILS_URL_EXPIRED,
        [CAMPAIGN_DETAILS_TAB_ALL]:CAMPAIGN_DETAILS_URL_ALL,
    }    

    /* Quote item status in the database */
    const CAMPAIGN_ITEM_STATUS_DB_REVIEW_IN_PROGRESS = 1;
    const CAMPAIGN_ITEM_STATUS_DB_REVIEW_COMPLETE = 2;
    const CAMPAIGN_ITEM_STATUS_DB_DO_NOT_PUBLISH_AD = 3;
    const CAMPAIGN_ITEM_STATUS_DB_PUBLISH_AD = 4;
    const CAMPAIGN_ITEM_STATUS_DB_PUBLISHED = 5;
    const CAMPAIGN_ITEM_STATUS_DB_MODIFIED_BY_CLIENT = 6;

    /* Quote item status display on the site */
    const CAMPAIGN_ITEM_STATUS_DISPLAY_REVIEW_IN_PROGRESS = "Review in progres";
    const CAMPAIGN_ITEM_STATUS_DISPLAY_REVIEW_COMPLETE = "Review complete";
    const CAMPAIGN_ITEM_STATUS_DISPLAY_DO_NOT_PUBLISH_AD = "Ad not approved for publishing";
    const CAMPAIGN_ITEM_STATUS_DISPLAY_PUBLISH_AD = "Ad approved to publish";
    const CAMPAIGN_ITEM_STATUS_DISPLAY_PUBLISHED = "Ad published";
    const CAMPAIGN_ITEM_STATUS_DISPLAY_MODIFIED_BY_CLIENT = "Ad modified - Republish";


    /* Mapping of campaign item status data values to display values */
    const campaignItemStatusMapping = {
        [CAMPAIGN_ITEM_STATUS_DB_REVIEW_IN_PROGRESS]:CAMPAIGN_ITEM_STATUS_DISPLAY_REVIEW_IN_PROGRESS,
        [CAMPAIGN_ITEM_STATUS_DB_REVIEW_COMPLETE]:CAMPAIGN_ITEM_STATUS_DISPLAY_REVIEW_COMPLETE,
        [CAMPAIGN_ITEM_STATUS_DB_DO_NOT_PUBLISH_AD]:CAMPAIGN_ITEM_STATUS_DISPLAY_DO_NOT_PUBLISH_AD,
        [CAMPAIGN_ITEM_STATUS_DB_PUBLISH_AD]:CAMPAIGN_ITEM_STATUS_DISPLAY_PUBLISH_AD,
        [CAMPAIGN_ITEM_STATUS_DB_PUBLISHED]:CAMPAIGN_ITEM_STATUS_DISPLAY_PUBLISHED,
        [CAMPAIGN_ITEM_STATUS_DB_MODIFIED_BY_CLIENT]:CAMPAIGN_ITEM_STATUS_DISPLAY_MODIFIED_BY_CLIENT,
    }

    /* --- Ad status is in the database --*/
    const AD_STATUS_DB_AWAITING_APPROVAL = "Awaiting Approval";
    const AD_STATUS_DB_PROCESSING = "Processing";
    const AD_STATUS_DB_PUBLISHED = "Published";
    const AD_STATUS_DB_UPDATE_IN_PROGRESS = "Modified";
    const AD_STATUS_DB_UPDATED = "Updated";
    const AD_STATUS_DB_REMOVAL_IN_PROGRESS = "Stop";
    const AD_STATUS_DB_STOPPED = "Stopped";
    const AD_STATUS_DB_CANCELED = "Canceled";
    const AD_STATUS_DB_ERROR = "Error";


    /* --- Display ad status on the site --*/
    const AD_STATUS_DISPLAY_AWAITING_APPROVAL = "Awaiting Approval";
    const AD_STATUS_DISPLAY_PROCESSING = "Processing";
    const AD_STATUS_DISPLAY_PUBLISHED = "Published";
    const AD_STATUS_DISPLAY_UPDATE_IN_PROGRESS = "Update in Progress";
    const AD_STATUS_DISPLAY_UPDATED = "Updated";
    const AD_STATUS_DISPLAY_REMOVAL_IN_PROGRESS = "Removal in Progress";
    const AD_STATUS_DISPLAY_STOPPED = "Stopped";
    const AD_STATUS_DISPLAY_CANCELED = "Canceled";
    const AD_STATUS_DISPLAY_ERROR = "Processing";

    /* Mapping of ad status data values to display values */
    const adStatusMapping = {
        [AD_STATUS_DB_AWAITING_APPROVAL]:AD_STATUS_DISPLAY_AWAITING_APPROVAL,
        [AD_STATUS_DB_PROCESSING]:AD_STATUS_DISPLAY_PROCESSING,
        [AD_STATUS_DB_PUBLISHED]:AD_STATUS_DISPLAY_PUBLISHED,
        [AD_STATUS_DB_UPDATE_IN_PROGRESS]:AD_STATUS_DISPLAY_UPDATE_IN_PROGRESS,
        [AD_STATUS_DB_UPDATED]:AD_STATUS_DISPLAY_UPDATED,
        [AD_STATUS_DB_REMOVAL_IN_PROGRESS]:AD_STATUS_DISPLAY_REMOVAL_IN_PROGRESS,
        [AD_STATUS_DB_STOPPED]:AD_STATUS_DISPLAY_STOPPED,
        [AD_STATUS_DB_CANCELED]:AD_STATUS_DISPLAY_CANCELED,
        [AD_STATUS_DB_ERROR]:AD_STATUS_DISPLAY_ERROR,
    }

    const getAdStatusForDisplay = status=>{
        return adStatusMapping[status];
    }

    const getCampaignStatusForDisplay = status=>{
        return campaignItemStatusMapping[status];
    }

    const getCampaignItemBadgeForDisplay = statusTab=>{
        return campaignItemBadgeDisplayMapping[statusTab];
    }

    const getCampaignDetailUrlByStatus = statusTab=>{
        return campaignItemUrlByStatusTabMapping[statusTab];
    }

    const MODIFY_AD_SINGLE_AD = "update-single-ad";
    const MODIFY_AD_WHOLE_CAMPAIGN = "update-whole-campaign";
    

/* ----------_END OF CAMPAIGN RELATED CONSTANTS ------------------------------- */

/* ------------- CONTRACT RELATED CONSTANTS -------------------------------*/
    
    const CONTRACT_TAB_OPEN = "Open";
    const CONTRACT_TAB_CLOSED = "Expired";

/* -----------END OF CONTRACT RELATED CONSTANTS -------------------------------*/

/* ------------- INVOICE RELATED CONSTANTS -------------------------------*/
    const INVOICE_TAB_OPEN = "Open";
    const INVOICE_TAB_CLOSED = "Closed";
    const INVOICE_ALL = "All";

    const INVOICE_CREDIT_CARD_SURCHARGE = 0.03;

    /* Invoice status in the database */
    const INVOICE_STATUS_OPEN = 0;
    const INVOICE_STATUS_CLOSED = 1;

    /* Invoice status display on the site */
    const INVOICE_STATUS_DISPLAY_OPEN = "Unpaid";
    const INVOICE_ITEM_STATUS_DISPLAY_CLOSED = "Paid";

    /* Mapping of campaign item status data values to display values */
    const invoiceStatusMapping = {
        [INVOICE_STATUS_OPEN]:INVOICE_STATUS_DISPLAY_OPEN,
        [INVOICE_STATUS_CLOSED]:INVOICE_ITEM_STATUS_DISPLAY_CLOSED,
    }

    /* Mapping of invoite tab data values to status values */
    const invoiceTabToStatusMapping = {
        [INVOICE_TAB_OPEN]:INVOICE_STATUS_OPEN,
        [INVOICE_TAB_CLOSED]:INVOICE_STATUS_CLOSED,
    }

    const invoiceStatusToTabMapping = {
        [INVOICE_STATUS_OPEN]:INVOICE_TAB_OPEN,
        [INVOICE_STATUS_CLOSED]:INVOICE_TAB_CLOSED,
    }

    const getInvoiceStatusForDisplay = status=>{
        return invoiceStatusMapping[status];
    }

    const getInvoiceStatusByTab = tab=>{
        return invoiceTabToStatusMapping[tab]
    }

    const getInvoiceTabByStatus = tab=>{
        return invoiceStatusToTabMapping[tab]
    }
/* ------------- END OF INVOICE RELATED CONSTANTS -------------------------------*/

/* ------------- MANAGE ACCOUNT RELATED CONSTANTS -------------------------------*/
    const USER_ACTIVE = 1;
    const USER_INACTIVE = 0;

    const MANAGE_ACCOUNT_TAB_PROFILE = "Profile Settings";
    const MANAGE_ACCOUNT_TAB_COMPANY = "Company Profile";
    const MANAGE_ACCOUNT_TAB_USERS = "Manage Users";
    const MANAGE_ACCOUNT_TAB_NOTIFICATIONS = "Notifications";


    const MANAGE_ACCOUNT_ROUTE_TAB_PROFILE = "profile";
    const MANAGE_ACCOUNT_ROUTE_TAB_COMPANY = "company-profile";
    const MANAGE_ACCOUNT_ROUTE_TAB_USERS = "users";
    const MANAGE_ACCOUNT_ROUTE_TAB_NOTIFICATIONS = "notifications";

    /* User roles in the database */
    const ROLE_DB_CLIENT_ADMINISTRATOR = 2;
    const ROLE_DB_USER = 1;
    const ROLE_DB_BILLING = 3;
    const ROLE_DB_CUSTOM = 6;

    const ROLE_CLIENT_ADMINISTRATOR = "Administrator"; //Client administrator
    const ROLE_USER = "Limited User";
    const ROLE_BILLING = "Full User";
    const ROLE_CUSTOM = "Custom";

    const roleMapping = {
        [ROLE_DB_CLIENT_ADMINISTRATOR]:ROLE_CLIENT_ADMINISTRATOR,
        [ROLE_DB_BILLING]:ROLE_BILLING,
        [ROLE_DB_USER]:ROLE_USER,
        [ROLE_DB_CUSTOM]:ROLE_CUSTOM,
    }

    const getRolesMapping = ()=>{
        return roleMapping
    }

    const roleMappingReverse = {
        [ROLE_CLIENT_ADMINISTRATOR]:ROLE_DB_CLIENT_ADMINISTRATOR,
        [ROLE_BILLING]:ROLE_DB_BILLING,
        [ROLE_USER]:ROLE_DB_USER,
        [ROLE_CUSTOM]:ROLE_DB_CUSTOM,
    }

    const getRolesMappingReverse = ()=>{
        return roleMappingReverse
    }


    
    const roleValueList = [
        {value:ROLE_DB_CLIENT_ADMINISTRATOR,label:ROLE_CLIENT_ADMINISTRATOR},
        {value:ROLE_DB_BILLING,label:ROLE_BILLING},
        {value:ROLE_DB_USER,label:ROLE_USER},
        {value:ROLE_DB_CUSTOM,label:ROLE_CUSTOM},
        
    ]

    const getRolesForDisplay = ()=>{
        return roleValueList
    }

    /* SMS settings */
    const mobileCarrierSettings = [
        {name: 'AT&T',mailPostfix:'@txt.att.net'},
        {name: 'T-Mobile',mailPostfix:'@tmomailnet'},
        {name: 'Verizon',mailPostfix:'@vtext.com'},
        {name: 'Sprint',mailPostfix:'@messaging.sprintpcs.com'},
        {name: 'Xfinity Mobile',mailPostfix:'@vtext.com'},
        {name: 'Virgin Mobile',mailPostfix:'@vmobl.com'},
        {name: 'Tracfone',mailPostfix:'@mmst5.tracfone.com'},
        {name: 'Simple Mobile',mailPostfix:'@smtext.com'},
        {name: 'Mint Mobile',mailPostfix:'@mailmymobile.net'},
        {name: 'Red Pocket',mailPostfix:'@vtext.com'},
        {name: 'Metro PCS',mailPostfix:'@mymetropcs.com'},
        {name: 'Boost Mobile',mailPostfix:'@sms.myboostmobile.com'},
        {name: 'Cricket',mailPostfix:'@sms.cricketwireless.net'},
        {name: 'Republic Wireless',mailPostfix:'@text.republicwireless.com'},
        {name: 'Google Fi',mailPostfix:'@msg.fi.google.com'},
        {name: 'U.S. Cellular',mailPostfix:'@email.uscc.net'},
        {name: 'Ting',mailPostfix:'@message.ting.com'},
        {name: 'Consumer Cellular',mailPostfix:'@mailmymobile.net'},
        {name: 'C-Spire',mailPostfix:'@cspire1.com'},
        {name: 'Page Plus',mailPostfix:'@vtext.com'},
        {name: 'Rogers',mailPostfix:'@pcs.rogers.com'},
    ]
    
    const getMobileCarrierSettings = ()=>{
        return mobileCarrierSettings
    }

    const NOTIFICATION_READY_FOR_APPROVAL = 'Ready for Approval';
    const NOTIFICATION_AD_LIVE = 'Ad Live';
    const NOTIFICATION_AD_UPDATED = 'Ad Updated';
    const NOTIFICATION_AD_EXPIRATION = 'Ad Expiration';

    const NOTIFICATION_CORRESPONDENCE_TYPE_TEXT = 'Text';
    const NOTIFICATION_CORRESPONDENCE_TYPE_EMAIL = 'Email';
    
    const NOTIFICATION_FREQUENCY_HOURLY = 'Hourly';
    const NOTIFICATION_FREQUENCY_WEEKLY = 'Weekly';
    const NOTIFICATION_FREQUENCY_DAILY = 'Daily';
    
    
    const userNotificationType = [
        //NOTIFICATION_READY_FOR_APPROVAL,
        NOTIFICATION_AD_LIVE,
        NOTIFICATION_AD_UPDATED,
        NOTIFICATION_AD_EXPIRATION,
    ]

    const getUserNotificationType = ()=>{
        return userNotificationType
    }

    const userNotificationMappingToView = {
        //[NOTIFICATION_READY_FOR_APPROVAL]:"jobReadyForApproval",
        [NOTIFICATION_AD_LIVE]:"jobLive",
        [NOTIFICATION_AD_UPDATED]:"jobUpdated",
        [NOTIFICATION_AD_EXPIRATION]:"jobExpiration",
        
    }

    const frequencyList = [
        {
            id:1,
            name:NOTIFICATION_FREQUENCY_HOURLY,
        },
        {
            id:2,
            name:NOTIFICATION_FREQUENCY_DAILY,
        },
        {
            id:3,
            name:NOTIFICATION_FREQUENCY_WEEKLY,
        }
    ];

    const getNotificationFrequencyList  = ()=>{
        return frequencyList
    }

    const USER_ALERT_INVOICE_OVERDUE = 'INVOICE_OVERDUE';
    const USER_ALERT_AD_REVIEW = 'AD_REVIEW';
    const USER_ALERT_QUOTE_SUBMITTED = 'QUOTE_SUBMITTED';
    const USER_ALERT_TEARSHEET_FOR_AD = 'TEARSHEET_FOR_AD';
    const USER_ALERT_CAMPAIGN_EXPIRING = 'CAMPAIGN_EXPIRING';
    
    const userAlerts = [
        'INVOICE_OVERDUE','AD_REVIEW','CAMPAIGN_EXPIRING','QUOTE_SUBMITTED','TEARSHEET_FOR_AD'
    ]

    const getUserAlertType = ()=>{
        return userAlerts
    }



/* SMS settings */

    

/* ------------- END OF MANAGE ACCOUNT RELATED CONSTANTS -------------------------------*/

/* ------------- CANDIDATE QUESTIONNAIRE RELATED CONSTANTS -------------------------------*/
    const CANDIDATE_TAB_QUESTIONNAIRE_LIST = "Questionnaire List";
    const CANDIDATE_TAB_REPORTS = "Reports";
    const CANDIDATE_TAB_EXPORTS = "Export Settings";
    const CANDIDATE_TAB_QUESTION_BANK= "Question Bank";
    const CANDIDATE_TAB_PUBLICATIONS= "Publications";

    const CANDIDATE_TAB_ROUTE_QUESTIONNAIRE_LIST  = "index";
    const CANDIDATE_TAB_ROUTE_REPORTS = "report-index";
    const CANDIDATE_TAB_ROUTE_EXPORTS = "export-settings";
    const CANDIDATE_TAB_ROUTE_QUESTION_BANK = "question-bank";
    const CANDIDATE_TAB_ROUTE_PUBLICATIONS = "publications";
    
    const ACTION_QUESTIONNAIRE_ACTIVATE_QUESTION= "activateQuestion";
    const ACTION_QUESTIONNAIRE_CREATE_NEW_QUESTION= "createNewQuestion";
    const ACTION_QUESTIONNAIRE_CREATE_NEW_QUESTIONNAIRE = "createNewQuestionnaire";
    const ACTION_QUESTIONNAIRE_DELETE_QUESTIONNAIRE = "deleteQuestionnaire";
    const ACTION_QUESTIONNAIRE_DELETE_QUESTION = "deleteQuestion";
    const ACTION_QUESTIONNAIRE_DELETE_STEP = "deleteQuestionnaireStep";
    const ACTION_QUESTIONNAIRE_DOWNLOAD_RESUME = "downloadResume";
    const ACTION_QUESTIONNAIRE_EDIT_QUESTION= "editQuestion";
    const ACTION_QUESTIONNAIRE_GET_CLIENT_LIST_WITH_ACCESS_TO_CANDIDATE_MODULE = "getClientListWithAccessToCandidateModule";
    const ACTION_QUESTIONNAIRE_GET_CANDIDATE_LIST = "getCandidateList"
    const ACTION_QUESTIONNAIRE_GET_CANDIDATE_QUESTIONNAIRE_SUBMISSION_DATA = "getCandidateAndQuestionnaireSubmissionData"
    const ACTION_QUESTIONNAIRE_GET_DATA_FOR_LANDING_AREA = "getDataForQuestionnaireLandingArea";
    const ACTION_QUESTIONNAIRE_GET_DETAIL_EDIT_MODE = "getQuestionnaireDetailEditMode";
    const ACTION_QUESTIONNAIRE_GET_DETAIL_VIEW_MODE = "getQuestionnaireDetailViewMode";
    const ACTION_QUESTIONNAIRE_GET_PUBLICATION_LIST = "getPublicationList"
    const ACTION_QUESTIONNAIRE_GET_QUESTION_BANK_LIST = "getQuestionBankList"
    const ACTION_QUESTIONNAIRE_GET_PUBLICATION_DATA_FOR_LANDING_AREA = "getDataForPublicationLandingArea";
    const ACTION_QUESTIONNAIRE_GET_PUBLICATION_STYLING_DATA_BY_PUBLICATION_ID = "getPublicationStylingDataByPublicationId";
    const ACTION_QUESTIONNAIRE_REPORT_DOWNLOAD_CANDIDATE_DATA = "downloadCandidateData"
    const ACTION_QUESTIONNAIRE_SAVE_CANDIDATE_DATA_EXPORT_SETTINGS = "saveCandidateExportSettings";
    const ACTION_QUESTIONNAIRE_SAVE_QUESTIONNAIRE = "saveQuestionnaire";
    const ACTION_QUESTIONNAIRE_SAVE_QUESTIONNAIRE_STEP_SORT = "saveQuestionnaireStepSortingChanges";
    const ACTION_QUESTIONNAIRE_SAVE_QUESTIONNAIRE_STEP = "saveQuestionnaireStep";
    const ACTION_QUESTIONNAIRE_SAVE_QUESTIONNAIRE_NAME = "saveQuestionnaireName";
    const ACTION_QUESTIONNAIRE_SAVE_PUBLICATION_SETTING = "saveQuestionnairePublicationMapperSetting";
    const ACTION_QUESTIONNAIRE_SAVE_PUBLICATION_STYLE_SETTINGS = "savePublicationStyleSettings"
    
    const FIELD_TYPE_TEXT = "text"
    const FIELD_TYPE_TEXT_OTHER_FIELD = "text-other-field"
    const FIELD_TYPE_RADIO = "radio"
    const FIELD_TYPE_CHECKBOX = "checkbox"
    const FIELD_TYPE_SELECT = "select"


    const FIRST_COLUMN = 0
    const SECOND_COLUMN = 1

    const MOVE_LEFT = "left"
    const MOVE_RIGHT = "right"
    const MOVE_UP = "up"
    const MOVE_DOWN = "down"

    const VIEW_STYLE_SETTINGS =  "View Style Settings"
    const VIEW_PUBLISH_SETTINGS = "View Publish Settings"


    const getQuestionFieldType = ()=>{
        return [
            {value:"text",label:"Text"},
            {value:"radio",label:"Radio"},
            {value:"checkbox",label:"Checkbox"},
            {value:"select",label:"Drop-down"},
        ]
    }

    const getQuestionnaireFieldTypeListHavingValueList = ()=>{
        return [
            FIELD_TYPE_RADIO,
            FIELD_TYPE_CHECKBOX,
            FIELD_TYPE_SELECT,
        ]
    }
    
    const getQuestionnaireFieldTypeHavingOtherField = ()=>{
        return [
           
            FIELD_TYPE_SELECT,
        ]
    }

/* ------------- END OF CANDIDATE QUESTIONNAIRE RELATED CONSTANTS -------------------------------*/


const applicationConstants = {
    ACTION_QUESTIONNAIRE_ACTIVATE_QUESTION,
    ACTION_QUESTIONNAIRE_CREATE_NEW_QUESTION,
    ACTION_QUESTIONNAIRE_CREATE_NEW_QUESTIONNAIRE,
    ACTION_QUESTIONNAIRE_DELETE_STEP,
    ACTION_QUESTIONNAIRE_DELETE_QUESTIONNAIRE,
    ACTION_QUESTIONNAIRE_DELETE_QUESTION,
    ACTION_QUESTIONNAIRE_DOWNLOAD_RESUME,
    ACTION_QUESTIONNAIRE_EDIT_QUESTION,
    ACTION_QUESTIONNAIRE_GET_CLIENT_LIST_WITH_ACCESS_TO_CANDIDATE_MODULE,
    ACTION_QUESTIONNAIRE_GET_CANDIDATE_LIST,
    ACTION_QUESTIONNAIRE_GET_CANDIDATE_QUESTIONNAIRE_SUBMISSION_DATA,
    ACTION_QUESTIONNAIRE_GET_DATA_FOR_LANDING_AREA,
    ACTION_QUESTIONNAIRE_GET_DETAIL_EDIT_MODE,
    ACTION_QUESTIONNAIRE_GET_DETAIL_VIEW_MODE,
    ACTION_QUESTIONNAIRE_GET_PUBLICATION_DATA_FOR_LANDING_AREA,
    ACTION_QUESTIONNAIRE_GET_PUBLICATION_LIST,
    ACTION_QUESTIONNAIRE_GET_PUBLICATION_STYLING_DATA_BY_PUBLICATION_ID,
    ACTION_QUESTIONNAIRE_GET_QUESTION_BANK_LIST,
    ACTION_QUESTIONNAIRE_REPORT_DOWNLOAD_CANDIDATE_DATA,
    ACTION_QUESTIONNAIRE_SAVE_CANDIDATE_DATA_EXPORT_SETTINGS,
    ACTION_QUESTIONNAIRE_SAVE_PUBLICATION_SETTING,
    ACTION_QUESTIONNAIRE_SAVE_PUBLICATION_STYLE_SETTINGS,
    ACTION_QUESTIONNAIRE_SAVE_QUESTIONNAIRE,
    ACTION_QUESTIONNAIRE_SAVE_QUESTIONNAIRE_STEP_SORT,
    ACTION_QUESTIONNAIRE_SAVE_QUESTIONNAIRE_STEP,
    ACTION_QUESTIONNAIRE_SAVE_QUESTIONNAIRE_NAME,

    AD_STATUS_DB_AWAITING_APPROVAL,
    AD_STATUS_DB_PROCESSING,
    AD_STATUS_DB_PUBLISHED,
    AD_STATUS_DB_UPDATE_IN_PROGRESS,
    AD_STATUS_DB_UPDATED,
    AD_STATUS_DB_REMOVAL_IN_PROGRESS,
    AD_STATUS_DB_STOPPED,
    AD_STATUS_DB_CANCELED,
    AD_STATUS_DB_ERROR,

    CAMPAIGN_ITEM_STATUS_DB_REVIEW_IN_PROGRESS,
    CAMPAIGN_ITEM_STATUS_DB_REVIEW_COMPLETE,
    CAMPAIGN_ITEM_STATUS_DISPLAY_DO_NOT_PUBLISH_AD,
    CAMPAIGN_ITEM_STATUS_DB_PUBLISH_AD,
    CAMPAIGN_ITEM_STATUS_DB_PUBLISHED,
    CAMPAIGN_ITEM_STATUS_DB_MODIFIED_BY_CLIENT,

    CAMPAIGN_GET_FOR_ALL_VIEWS,
    CAMPAIGN_ALL,
    CAMPAIGN_STATUS_ACTIVE,
    CAMPAIGN_STATUS_ARCHIVED,
    CAMPAIGN_STATUS_DRAFT,

    CAMPAIGN_TAB_ACTIVE,
    CAMPAIGN_TAB_ALL,
    CAMPAIGN_TAB_ARCHIVED,
    CAMPAIGN_TAB_DRAFT,

    CAMPAIGN_DETAILS_TAB_READY_FOR_APPROVAL,
    CAMPAIGN_DETAILS_TAB_REVIEW_BY_JE,
    CAMPAIGN_DETAILS_TAB_PROCESSING,
    CAMPAIGN_DETAILS_TAB_PUBLISHED,
    CAMPAIGN_DETAILS_TAB_CANCELED,
    CAMPAIGN_DETAILS_TAB_EXPIRED,
    CAMPAIGN_DETAILS_TAB_ALL,

    CAMPAIGN_DETAILS_URL_READY_FOR_APPROVAL,
    CAMPAIGN_DETAILS_URL_REVIEW_BY_JE,
    CAMPAIGN_DETAILS_URL_PROCESSING,
    CAMPAIGN_DETAILS_URL_PUBLISHED,
    CAMPAIGN_DETAILS_URL_CANCELED,
    CAMPAIGN_DETAILS_URL_EXPIRED,
    CAMPAIGN_DETAILS_URL_ALL,

    CANDIDATE_TAB_PUBLICATIONS,
    CANDIDATE_TAB_QUESTIONNAIRE_LIST,
    CANDIDATE_TAB_QUESTION_BANK,
    CANDIDATE_TAB_REPORTS,
    CANDIDATE_TAB_EXPORTS,

    CANDIDATE_TAB_ROUTE_PUBLICATIONS,
    CANDIDATE_TAB_ROUTE_QUESTIONNAIRE_LIST,
    CANDIDATE_TAB_ROUTE_QUESTION_BANK,
    CANDIDATE_TAB_ROUTE_REPORTS,
    CANDIDATE_TAB_ROUTE_EXPORTS,

    CONTRACT_TAB_OPEN,
    CONTRACT_TAB_CLOSED,

    ENDPOINT_FOR_AUTH_SLICE,
    ENDPOINT_FOR_CAMPAIGN_SLICE,
    ENDPOINT_FOR_CANDIDATE_SERVICES,
    ENDPOINT_FOR_CLIENT_SLICE,
    ENDPOINT_FOR_INVOICE_SLICE,
    ENDPOINT_FOR_PENDING_QUOTE_SLICE,
    ENDPOINT_FOR_USER_SLICE,

    FIELD_TYPE_CHECKBOX,
    FIELD_TYPE_RADIO,
    FIELD_TYPE_SELECT,
    FIELD_TYPE_TEXT,
    FIELD_TYPE_TEXT_OTHER_FIELD,

    FIRST_COLUMN,
    
    INVOICE_CREDIT_CARD_SURCHARGE,
    
    INVOICE_TAB_OPEN,
    INVOICE_TAB_CLOSED,
    INVOICE_ALL,

    INVOICE_STATUS_OPEN,
    INVOICE_STATUS_CLOSED,

    MANAGE_ACCOUNT_TAB_PROFILE,
    MANAGE_ACCOUNT_TAB_COMPANY,
    MANAGE_ACCOUNT_TAB_USERS,
    MANAGE_ACCOUNT_TAB_NOTIFICATIONS,

    MANAGE_ACCOUNT_ROUTE_TAB_PROFILE,
    MANAGE_ACCOUNT_ROUTE_TAB_COMPANY,
    MANAGE_ACCOUNT_ROUTE_TAB_USERS,
    MANAGE_ACCOUNT_ROUTE_TAB_NOTIFICATIONS,

    MODIFY_AD_SINGLE_AD,
    MODIFY_AD_WHOLE_CAMPAIGN,

    MOVE_LEFT,
    MOVE_RIGHT,
    MOVE_UP,
    MOVE_DOWN,

    NOTIFICATION_READY_FOR_APPROVAL,
    NOTIFICATION_AD_LIVE,
    NOTIFICATION_AD_UPDATED,
    NOTIFICATION_AD_EXPIRATION,

    NOTIFICATION_CORRESPONDENCE_TYPE_TEXT,
    NOTIFICATION_CORRESPONDENCE_TYPE_EMAIL,
    
    NOTIFICATION_FREQUENCY_HOURLY,
    NOTIFICATION_FREQUENCY_WEEKLY,
    NOTIFICATION_FREQUENCY_DAILY,
    
    
    PAGINATION_LIMIT,
    
    PUBLICATION_ADDED_TYPE_MANUAL,
    PUBLICATION_ADDED_TYPE_CONTRACT,
    PUBLICATION_ADDED_TYPE_RECOMMENDATION,
    
    PUBLICATION_TYPE_ONLINE,
    PUBLICATION_TYPE_PRINT,
    PUBLICATION_TYPE_BOTH,
    
    PUBLICATION_PRINT_TYPE_LINE_AD,
    PUBLICATION_PRINT_TYPE_DISPLAY,

    QUOTE_ENTRY_FORM_FRIENDLY,
    QUOTE_ENTRY_FORM_FRIENDLY_TEXT,
    QUOTE_ENTRY_FORM_FRIENDLY_URL_STEP,
    QUOTE_ENTRY_FORM_REGULAR,
    QUOTE_ENTRY_FORM_REGULAR_TEXT,
    QUOTE_ENTRY_FORM_REGULAR_URL_STEP,
    QUOTE_FRIENDLY_STEP_1,
    QUOTE_FRIENDLY_STEP_2,
    QUOTE_FRIENDLY_STEP_3,
    QUOTE_FRIENDLY_STEP_4,
    QUOTE_FRIENDLY_STEP_5,
    QUOTE_FRIENDLY_STEP_6,
    QUOTE_FRIENDLY_STEP_7,
    QUOTE_FRIENDLY_STEP_8,
    QUOTE_FRIENDLY_STEP_9,
    QUOTE_FRIENDLY_STEP_10,
    QUOTE_FRIENDLY_STEP_11,

    REFETCH_THRESHOLD_IN_MINUTES,

    ROLE_DB_CLIENT_ADMINISTRATOR,
    ROLE_DB_USER,
    ROLE_DB_BILLING,
    ROLE_DB_CUSTOM,

    ROLE_CLIENT_ADMINISTRATOR,
    ROLE_USER,
    ROLE_BILLING,
    ROLE_CUSTOM,

    SECOND_COLUMN,

    SORT_ORDER_ASCEND,
    SORT_ORDER_DESCEND,

    USER_ACTIVE,
    USER_INACTIVE,

    USER_ALERT_INVOICE_OVERDUE,
    USER_ALERT_AD_REVIEW,
    USER_ALERT_QUOTE_SUBMITTED,
    USER_ALERT_TEARSHEET_FOR_AD,
    USER_ALERT_CAMPAIGN_EXPIRING,

    VIEW_PUBLISH_SETTINGS,
    VIEW_STYLE_SETTINGS,

    getAdStatusForDisplay,
    getApplicationDeadlineTypeList,
    getCampaignDetailUrlByStatus,
    getCampaignItemBadgeForDisplay,
    getCampaignStatusForDisplay,
    getEndDateTypeList,
    getEndDateTypeDbList,
    getInvoiceTabByStatus,
    getInvoiceStatusForDisplay,
    getInvoiceStatusByTab,
    getJobTypeLabel,
    getJobTypeList,
    getMobileCarrierSettings,
    getNotificationFrequencyList,
    getQuestionFieldType,
    getQuestionnaireFieldTypeListHavingValueList,
    getQuestionnaireFieldTypeHavingOtherField,
    getRolesForDisplay,
    getRolesMapping,
    getRolesMappingReverse,
    getSalaryLabel,
    getSalaryTypeList,
    getStartDateTypeList,
    getStartDateTypeDbList,
    getUserAlertType,
    getUserNotificationType,

    userNotificationMappingToView,
    
    
}

export default applicationConstants;
