import React,{Component} from "react";
import { connect } from 'react-redux';

import {
    Row,
    Col,
    Table,
    CardBody,
    Card,
    Badge,
    Label,
    UncontrolledTooltip,
    Button,
    Progress
  } from 'reactstrap';
  

//Load UI action creators and selectors
import { 
    getActiveView,
} from "../../store/auth";

import {
    getJobelephantClientRecord,
} from "../../store/globalProperties"
  
// End of loading action creators and selectors

/*
    Props:
    - customExecution: These are custom methods that are used to allow component display
*/
class AccessControl extends Component{

    accessToQuestionnaireSetup(){
        let {activeView, jobelephantClientRecord} = this.props;
        if(activeView && jobelephantClientRecord){
            const {client} = activeView
            const {id:clientId} = jobelephantClientRecord
            return clientId == client.id
        }
        else 
            return false
    }

    render(){
        const {activeView,allow,children,customExecution} = this.props
        
        let proceed = false;
        if(allow.length && activeView && activeView.permissionList){
            allow.forEach(permission=>{
                proceed = activeView.permissionList[permission]
            })
        }

        /*
        If we need custom execution for the component to display then we create a custom function 
        - Pass the function name in the as a property in the AccessControl component
        */
        if(customExecution){
            proceed = this[customExecution]()
        }

        return(
            <>
                {   proceed === true &&
                    children
                }
                {   proceed === false &&
                    <Row className="mx-2 mt-4">
                        <Col xs="12">
                            <div className="alert alert-danger text-center" role="alert">
                                <p className="pt-2">You do not have permission to view this protected area</p>
                            </div>
                        </Col>
                    </Row>
                }
            </>
        ) 

    }
}

const mapStateToProps = state=>({
    activeView:getActiveView(state),
    jobelephantClientRecord:getJobelephantClientRecord(state),
});
  

export default connect(mapStateToProps)(AccessControl);
