import { createSlice } from "@reduxjs/toolkit";
import {createSelector} from "reselect";
import moment from "moment";

import applicationContants from "./applicationConstants";

const {
    CAMPAIGN_TAB_ACTIVE,
    CAMPAIGN_TAB_ALL,
    CAMPAIGN_TAB_ARCHIVED,
    CAMPAIGN_TAB_DRAFT,
    
    PAGINATION_LIMIT,
    
    SORT_ORDER_ASCEND,
    SORT_ORDER_DESCEND,
} = applicationContants;

const DEFAULT_SORT_FIELD_1 = "created";
const DEFAULT_SORT_FIELD_2 = "start_date";

const listOfStatusToProcess = [
    CAMPAIGN_TAB_ALL,
    CAMPAIGN_TAB_ACTIVE,
    CAMPAIGN_TAB_ARCHIVED,
    CAMPAIGN_TAB_DRAFT,
]



const initialState = {
    query:{
        keyword:"",
        clientId:"",
        clientAccountId:"",
        filteredView:CAMPAIGN_TAB_ALL,
        sortForFilter:"",   
        paginationLimit:PAGINATION_LIMIT,
        pageNumber:1,
        defaultSort:[
            {
                sortBy:DEFAULT_SORT_FIELD_1,
                sortOrder:SORT_ORDER_DESCEND,
            },
            {
                sortBy:DEFAULT_SORT_FIELD_2,
                sortOrder:SORT_ORDER_DESCEND,
            },
        ],
    },

    defaultSortIndicator:{},

    activeTabView:CAMPAIGN_TAB_ALL,

    filterProperties:{
        [CAMPAIGN_TAB_ALL]:{
            displayLabel:CAMPAIGN_TAB_ALL,
            pageNumber:1,
            sort:{
                sortBy:"", 
                sortOrder:"",
            },
        },
        [CAMPAIGN_TAB_ACTIVE]:{
            displayLabel:CAMPAIGN_TAB_ACTIVE,
            pageNumber:1,
            sort:{
                sortBy:"", 
                sortOrder:"",
            },
        },
        [CAMPAIGN_TAB_ARCHIVED]:{
            displayLabel:CAMPAIGN_TAB_ARCHIVED,
            pageNumber:1,
            sort:{
                sortBy:"", 
                sortOrder:"",
            },
        },
        [CAMPAIGN_TAB_DRAFT]:{
            displayLabel:CAMPAIGN_TAB_DRAFT,
            pageNumber:1,
            sort:{
                sortBy:"", 
                sortOrder:"",
            },
        },
    },

}

//-------Slice: Create reducers and actions--------
const slice  = createSlice({
    name:"myCampaignView",
    initialState,
    reducers:{
        currentPageNumberSelected:(myCampaignView,action)=>{
            const {activeTabView} = myCampaignView;
            const {pageNumber} = action.payload;
            myCampaignView.filterProperties[activeTabView].pageNumber = pageNumber;
            myCampaignView.query.pageNumber = pageNumber;
        },
        keywordFieldUpdated:(myCampaignView,action)=>{
            const {activeTabView} = myCampaignView;
            const {keyword} = action.payload;
            myCampaignView.query.keyword = keyword;
            myCampaignView.query.sortForFilter="";
            myCampaignView.query.pageNumber = 1;
        },
        selectedActiveTabView:(myCampaignView,action)=>{
            const {tabView:activeTabView}  = action.payload;
            myCampaignView.activeTabView = activeTabView;
            myCampaignView.query.filteredView = activeTabView;
        },
        
    },
})


//--------Export reducers and actions-----------------
export const {
    currentPageNumberSelected,
    keywordFieldUpdated,
    selectedActiveTabView,
} = slice.actions;

export default slice.reducer;


//---------Export action creators-------------------------
export const setActiveTabView = tabView=>dispatch=>{
    dispatch(selectedActiveTabView({tabView}))
}

export const setCurrentPageNumber = pageNumber=>dispatch=>{
    dispatch(currentPageNumberSelected({pageNumber}))
}

export const setKeywordSearchField = (keyword)=>dispatch=>{
    dispatch(keywordFieldUpdated({keyword}))
}

//----------Get selectors------------------------------
export const getActiveTabView = createSelector(
    state=>state.ui.myCampaignView,
    myCampaignView=>myCampaignView.activeTabView
)
