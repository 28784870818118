import { createSlice } from "@reduxjs/toolkit";
import {createSelector} from "reselect";
import moment from "moment";

import applicationContants from "./applicationConstants";

const {
    CAMPAIGN_TAB_ACTIVE,
    CAMPAIGN_TAB_ALL,
    CAMPAIGN_TAB_ARCHIVED,
    CAMPAIGN_TAB_DRAFT,
    
    PAGINATION_LIMIT,
    
    SORT_ORDER_ASCEND,
    SORT_ORDER_DESCEND,
} = applicationContants;

const DEFAULT_SORT_FIELD_1 = "created";
const DEFAULT_SORT_FIELD_2 = "start_date";

const listOfStatusToProcess = [
    CAMPAIGN_TAB_DRAFT,
]

const initialState = {
    query:{
        keyword:"",
        clientId:"",
        clientAccountId:"",
        filteredView:CAMPAIGN_TAB_DRAFT,
        sortForFilter:"",   
        paginationLimit:PAGINATION_LIMIT,
        pageNumber:1,
        defaultSort:[
            {
                sortBy:DEFAULT_SORT_FIELD_1,
                sortOrder:SORT_ORDER_DESCEND,
            },
            {
                sortBy:DEFAULT_SORT_FIELD_2,
                sortOrder:SORT_ORDER_DESCEND,
            },
        ],
    },

    defaultSortIndicator:{},

    activeTabView:CAMPAIGN_TAB_DRAFT,
    displayLabel:CAMPAIGN_TAB_DRAFT,
    pageNumber:1,
    sort:{
        sortBy:"", 
        sortOrder:"",
    },

    
}

//-------Slice: Create reducers and actions--------
const slice  = createSlice({
    name:"pendingQuoteTabView",
    initialState,
    reducers:{
        currentPageNumberSelected:(pendingQuoteTabView,action)=>{
            const {pageNumber} = action.payload;
            pendingQuoteTabView.pageNumber = pageNumber;
            pendingQuoteTabView.query.pageNumber = pageNumber;
        },
        
    },
})


//--------Export reducers and actions-----------------
export const {
    currentPageNumberSelected,
} = slice.actions;

export default slice.reducer;


//---------Export action creators-------------------------
export const setCurrentPageNumber = pageNumber=>dispatch=>{
    dispatch(currentPageNumberSelected({pageNumber}))
}

//----------Get selectors------------------------------

export const getCurrentPageNumber = createSelector(
    state=>state.ui.myCampaignPendingQuoteView,
    myCampaignPendingQuoteView=>myCampaignPendingQuoteView.pageNumber
)