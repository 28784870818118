import React, { Fragment, useState } from 'react';

import PropTypes from 'prop-types';

const MinimalLayout = props => {
  const { children } = props;

  const footerShadow = useState();
  const footerTransparentBg = useState();

  return <Fragment>{children}</Fragment>;
};

MinimalLayout.propTypes = {
  children: PropTypes.node
};

export default MinimalLayout;
