import { createSlice } from "@reduxjs/toolkit";
import {createSelector} from "reselect";
import _ from "lodash";

import { apiCallBegan } from "./api";

import applicationConstants from "./applicationConstants";

const {
    ENDPOINT_FOR_USER_SLICE,
} = applicationConstants


//-------Slice: Create reducers and actions--------
const slice  = createSlice({
    name:"jeAdminSelectUserView",
    initialState:{
        data:{
            userList:[]   //[{label:"John Doe",value:{userId:12334, name:"John Doe"}}]
        },
        errors:[],
        loading:false,
    },
    reducers:{
        errorUICleared:(jeAdminSelectUserView,action)=>{
            jeAdminSelectUserView.errors = [];
        },
        errorUIDisplayed:(jeAdminSelectUserView,action)=>{
            const {error} = action.payload;
            jeAdminSelectUserView.errors = error.list;
        },
        userListCleared:(jeAdminSelectUserView,action)=>{
            jeAdminSelectUserView.data.userList = [];
            jeAdminSelectUserView.loading = false;
        },
        userListReceived:(jeAdminSelectUserView,action)=>{
            jeAdminSelectUserView.data.userList = action.payload;
            jeAdminSelectUserView.loading = false;
        },
        userListRequested:(jeAdminSelectUserView,action)=>{
            jeAdminSelectUserView.loading = true;
        },
        userListRequestFailed:(jeAdminSelectUserView,action)=>{
            jeAdminSelectUserView.loading = false;
        },

    }

});

//--------Export reducers and actions-----------------
export const {
    errorUICleared,
    errorUIDisplayed,
    userListCleared,
    userListReceived,
    userListRequested,
    userListRequestFailed,
} = slice.actions;

export default slice.reducer;

//---------Action creators-------------------------------
const baseUserUrl = ENDPOINT_FOR_USER_SLICE;

export const clearErrors = ()=>dispatch=>{
    dispatch(errorUICleared())
}

export const clearUserList = ()=>dispatch=>{
    dispatch(userListCleared())
}

export const searchUserList = (formData)=>dispatch=>{
    dispatch(apiCallBegan({
        url:`${baseUserUrl}/getListOfUsers`,
        method:'post',
        data:formData,
        onStart:[userListRequested.type],
        onSuccess:[userListReceived.type],
        onError:[userListRequestFailed.type,errorUIDisplayed.type],
    }))
}


//----------Get selectors------------------------------

export const getListOfUsers = createSelector(
    state=>state.ui.jeAdminSelectUserView,
    jeAdminSelectUserView=>jeAdminSelectUserView.data.userList
)

export const getServerErrors = createSelector(
    state=>state.ui.jeAdminSelectUserView,
    jeAdminSelectUserView=> jeAdminSelectUserView.errors
)

