import React, {Fragment, useState, Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Row, Table, Col, CardBody, Card, CardTitle, CardHeader, Collapse, UncontrolledPopover, Container, InputGroup, InputGroupAddon, InputGroupText, FormText, Form, CustomInput, Label, FormGroup, Input, Badge, ListGroup, ListGroupItem, PopoverHeader, PopoverBody, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, Progress, DropdownToggle, DropdownMenu, Alert, DropdownItem } from 'reactstrap'

import {connect} from 'react-redux';

import {
    setSidebarToggleMobile
} from '../../reducers/ThemeOptions';


import HeaderDots from "../../layout-components/HeaderDots";
import HeaderDrawer from "../../layout-components/HeaderDrawer";
import HeaderUserbox from "../../layout-components/HeaderUserbox";
import HeaderSearch from "../../layout-components/HeaderSearch";
import HeaderMenu from "../../layout-components/HeaderMenu";

class HeaderAddUser extends Component {

    toggleSidebarMobile = () => {
        let {sidebarToggleMobile, setSidebarToggleMobile} = this.props;
        setSidebarToggleMobile(!sidebarToggleMobile);
    }

    render() {
        let {
            headerShadow,
            headerBgTransparent,
            sidebarToggleMobile
        } = this.props;

        return (
            <Fragment>
                <div className={cx("app-header","default-background-color","zindex-10", {'app-header--shadow': false, 'app-header--opacity-bg': false})}>
                <div className="app-header--pane">
                    <button className={cx("navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn", {'is-active': sidebarToggleMobile})} onClick={this.toggleSidebarMobile}>
                        <span className="hamburger-box">
                            <span className="hamburger-inner"/>
                        </span>
                    </button>
                    <div className="je-header-page-title mb-3">
                        <div className=" text-left">
                            <div className="float-left mt-1 ">
                                <Button color="link" className="pl-0 ml-0 btn-link-dark" onClick={e=>{
                                        e.preventDefault();
                                        this.props.history.goBack();
                                    }}
                                >
                                    <span className="btn-wrapper--icon">
                                        <FontAwesomeIcon icon={['fas', 'angle-left']} />
                                    </span>
                                    <span className="btn-wrapper--label">Go Back</span>
                                </Button>
                            </div> 
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
            </Fragment>
    );
    }
}

const mapStateToProps = state => ({
    headerShadow: state.ThemeOptions.headerShadow,
    headerBgTransparent: state.ThemeOptions.headerBgTransparent,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({

    setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),

});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderAddUser);
