import React, {Fragment, useState, Component} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import cx from 'classnames';
import {Link} from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Row, Table, Col, CardBody, Card, CardTitle, CardHeader, Collapse, UncontrolledPopover, Container, InputGroup, InputGroupAddon, InputGroupText, FormText, Form, CustomInput, Label, FormGroup, Input, Badge, ListGroup, ListGroupItem, PopoverHeader, PopoverBody, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, Progress, DropdownToggle, DropdownMenu, Alert, DropdownItem } from 'reactstrap'

import {connect} from 'react-redux';

import {
    getActiveClientAccount,
} from '../../store/auth'

import { 
    getCampaign,
    getCampaignDetails,
    stopAdForCampaignItemsList,
} from "../../store/campaigns"

import {
    getSelectedCampaignItemsPerView,
} from "../../store/ui-campaign-details";


import {
    setSidebarToggleMobile
} from '../../reducers/ThemeOptions';

import applicationConstants from "../../store/applicationConstants"

const {
    CAMPAIGN_DETAILS_TAB_PUBLISHED,  
    CAMPAIGN_DETAILS_TAB_PROCESSING,
} = applicationConstants;


class HeaderCampaignDetailItem extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          
        };
    }

    toggleSidebarMobile = () => {
        let {sidebarToggleMobile, setSidebarToggleMobile} = this.props;
        setSidebarToggleMobile(!sidebarToggleMobile);
    }

    render() {
        let {
            headerShadow,
            headerBgTransparent,
            sidebarToggleMobile,
            campaign,  
            campaignDetails,  
            clientAccountName,
        } = this.props;


        

        const campaignLoaded = (campaign) ? true:false;
        let [campaignTitle,jobNumber,itemCounts] = ["","",0];
        if(campaignLoaded){
            ({name:campaignTitle,jobNumber} = campaign);
        } 

        if(campaignDetails){
            ({itemCounts} = campaignDetails);
        }

        return (
            <Fragment>
                <div className={cx("app-header", {'app-header--shadow': headerShadow, 'app-header--opacity-bg': headerBgTransparent})}>
                <div className="app-header--pane">
                    
                    <button className={cx("navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn", {'is-active': sidebarToggleMobile})} onClick={this.toggleSidebarMobile}>
                        <span className="hamburger-box">
                            <span className="hamburger-inner"/>
                        </span>
                    </button>
                    <div className="je-header-page-title mb-3">
                        <div className=" text-center">
                            { !campaignLoaded && <div className="width-50px"><Skeleton count={1} /></div>}
                            {
                                campaignLoaded &&
                                <>
                                    <div className="float-left mt-1 je-header-campaign-detail-page-title">
                                        {campaignTitle}
                                    </div>
                                    <div className="float-left  ml-2 text-black-50 d-none d-xl-block" style={{marginTop:"0.4rem"}}>
                                        | 
                                    </div>
                                    <div  className="float-left ml-2 d-none d-xl-block" style={{marginTop:"0.5rem"}}>
                                        <span className="text-black-50">{ jobNumber !== "" && `#${jobNumber}`}</span>
                                    </div>
                                </>
                            }
                            
                            <div className="clearfix"></div>
                        </div>
                        <div className="je-header-campaign-detail-page-description">
                            { !campaignLoaded && <div className="width-50px"><Skeleton count={1} /></div>}
                            { 
                              campaignLoaded && <strong>{clientAccountName}</strong>
                            }
                        </div>
                        
                    </div>
                    
                </div>
                <div className="app-header--pane">
                    
                    

                                       
                </div>
            </div>
            </Fragment>
    );
    }
}

const mapStateToProps = (state,ownProps) => ({
    campaign:getCampaign(ownProps.urlParameters.campaignId)(state),
    campaignDetails:getCampaignDetails(ownProps.urlParameters.campaignId)(state),
    clientAccountName:getActiveClientAccount(state).name || "",
    headerShadow: state.ThemeOptions.headerShadow,
    headerBgTransparent: state.ThemeOptions.headerBgTransparent,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
    
    setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
    stopAdForCampaignItemList:(campaignItemObject)=>dispatch(stopAdForCampaignItemsList(campaignItemObject)),


});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCampaignDetailItem);
