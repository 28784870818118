import { createSlice } from "@reduxjs/toolkit";

import {createSelector} from "reselect";
import _ from "lodash";
import moment from "moment";

import { apiCallBegan } from "./api"

import {
    forgotPasswordUIRestPasswordSaved,
    errorUIDisplayed as forgotPasswordUIOnError,
    loadingIndicatorUIStart as forgotPasswordUILoadingIndicatorStart,
    loadingIndicatorUIEnd   as forgotPasswordUILoadingIndicatorEnd,
} 
from "./ui-forgot-password"
import { errorUIDisplayed as resetPasswordUIOnError,
    loadingIndicatorUIStart,
    loadingIndicatorUIEnd,
} from "./ui-reset-password"

import {
    notificationSettingUIOnError,
    notificationSettingUISaveInProgress,
    notificationSettingUISaved,

    personalProfileUIOnError,
    personalProfileUISaveInProgress,
    personalProfileUISaved,   

    personalProfileUIResetPasswordInProgress,
    personalProfileUIResetPasswordSaved, 
} from "./ui-manage-account"

import { errorUIDisplayed } from "./ui-login-screen";

import authService from "../services/authService";

import applicationConstants from "./applicationConstants";

const {
    ENDPOINT_FOR_AUTH_SLICE,
    ENDPOINT_FOR_USER_SLICE,
} = applicationConstants

/*
initializationAuthObject = {
    loggedInUser:{
        userId:"",
        userUuid:"",
        username:"",
        clientId:"",
        salutation:"",
        firstName:"",
        lastName:"",
        isJEAdministrator:"",
        resetPasswordFlag:"",  
    },
    activeUserId:"" //This will be the driver for the activeView && accessControlList
    activeView:{
       userId: "",   //If not je administrator then its the the logged in user id
       user:"",
       roleId:"",
       role: "", 
       client:{
          id:"",
          name:"",
       },
       clientAccount:{
          id:"",
          name:"",
       }
    },
    accessControlList:{
       userId: "",   //If not je administrator then its the the logged in user id
       user:"",
       acl:[ 
            //This is the user permission records
            {
                id:"",
                idUuid:"",
                userId:"",
                userUuid:"",
                clientId:"",
                clientAccountId"",
                groupId:"",
                active:"",
                client:{
                    id:"",
                    name:"",
                },
                clientAccount:{
                    id:"",
                    name:"",
                },
            } 
        ],
        clientById:{
            [clientId]:{
                id:
                name:
            }
        },
        clientAccountById:{
            [clientAccountId]:{
                clientId:
                id:
                name:
            }
        }
        permissions:[
            {
                role:
                roleId:  
                clientId:
                clientAccountId:
                clientName:
                clientAccountName:
            }
        ]
        
    }
}
*/

//Slice: Create reducers and actions
const slice = createSlice({
    name:"auth",
    initialState:{
        loggedInUser:"",
        activeUserId:"",
        activeView:"",
        accessControlList:"",
        loading:false,
    },
    reducers:{
        activeUserCleared:(auth,action)=>{
            authService.setActiveUserId("");
            auth.activeUserId = "";
        },
        activeUserIdReceived:(auth,action)=>{
            const {activeUserId} = action.payload;
            authService.setActiveUserId(activeUserId);
            auth.activeUserId = activeUserId;

        },
        activeViewReceived:(auth,action)=>{
            const {activeView} = action.payload;
            authService.setActiveView(JSON.stringify(activeView));
            auth.activeView = activeView;
        },
        loggedInUserReceived:(auth,action)=>{
            const {loggedInUser} = action.payload;
            auth.loggedInUser = loggedInUser;
        },
        userAccessControlListReceived:(auth,action)=>{
            auth.accessControlList = action.payload;
            auth.loading = false;
        },
        //This happens only when the user logs in for the first time from the login screen 
        userAuthenticated:(auth,action)=>{
            //console.log(action);
            const {token,activeView,accessControlList} = action.payload;
            
            authService.setJwt(token);
            
            const loggedInUser = authService.getCurrentUser();
            const {isJEAdministrator,userId} = loggedInUser;
            
            auth.loggedInUser = loggedInUser;
            if(!isJEAdministrator){
                auth.activeUserId = userId;
                authService.setActiveUserId(userId);
            }

            if(activeView !== ""){
                authService.setActiveView(JSON.stringify(activeView));
                auth.activeView = activeView;
            }
            //auth.accessControlList = accessControlList; //This will initiall be empty for je admin
                
            
            //auth.loading = false;
        },
        userAuthenticationRequested:(auth,action)=>{
            auth.loading = true;
        },
        userAuthenticationRequestFailed:(auth,action)=>{
            auth.loading = false;
        },
        userLoggedOut:(auth,action)=>{
            
        },

        userNotificationSettingSaved:(auth,action)=>{
            //console.log(action.payload);
            const {
                status,
                user,
                notificationSettings,
            } = action.payload;
            
            auth.activeView.user = user;
            auth.accessControlList.user = user;
            auth.accessControlList.notificationSettings = notificationSettings;
            authService.setActiveView(JSON.stringify(auth.activeView));
            
        },

        userPasswordResetComplete:(auth,action)=>{
            const {
                status,
                token,
                activeView,
                user
            } = action.payload;

            authService.setJwt(token);
            
            const loggedInUser = authService.getCurrentUser();
            const {isJEAdministrator,userId} = loggedInUser;
            
            auth.loggedInUser = loggedInUser;
            if(!isJEAdministrator){
                auth.activeUserId = userId;
                authService.setActiveUserId(userId);
            }

            if(activeView !== ""){
                authService.setActiveView(JSON.stringify(activeView));
                auth.activeView = activeView;
            }
            //auth.accessControlList = accessControlList; //This will initiall be empty for je admin
                
            window.location = "/dashboard"    
        
        },

        userPersonalProfileSaved:(auth,action)=>{
            /* set the successIndicator to true 
            
                Need to set the following:
                JWT token needs to get updated
                Redux:
                auth.loggedInUser
                    .activeView.user
                    .accessControlList.user
            
            */
            //console.log(action.payload);
            const {
                status,
                hasPrimaryAttributesChanged,
                token,
                user
            } = action.payload;
            
            auth.activeView.user = user;
            auth.accessControlList.user = user;
            authService.setActiveView(JSON.stringify(auth.activeView));
            
            if(hasPrimaryAttributesChanged){
                //JWT token needs to get updated
                authService.setJwt(token);
                const loggedInUser = authService.getCurrentUser();
                auth.loggedInUser = loggedInUser;
                window.location = "/manage-account/?success"    
            }

            
        },

        


        
    }

});

//---------------Actions & Reducer export setup----------------------------
export const {
    activeUserIdReceived,
    activeViewReceived,
    activeUserCleared,
    loggedInUserReceived,
    userAccessControlListReceived,
    userAuthenticated,
    userAuthenticationRequested,
    userAuthenticationRequestFailed,
    userLoggedOut,
    userNotificationSettingSaved,
    userPasswordResetComplete,
    userPersonalProfileSaved,
}  = slice.actions;

//Export reducer
export default slice.reducer;
//---------------End of Actions & Reducer export setup----------------------------

//-------------Export Action Creators-----------------------
const url = ENDPOINT_FOR_AUTH_SLICE;
const baseUserUrl = ENDPOINT_FOR_USER_SLICE;

export const clearActiveUser = (authenticationObject)=>(dispatch,getState)=>{
    dispatch(activeUserCleared())
}

export const login = (authenticationObject)=>(dispatch,getState)=>{
    
    dispatch(apiCallBegan({
        url,
        method:'post',
        data:authenticationObject,
        onStart:[userAuthenticationRequested.type],
        onSuccess:[userAuthenticated.type],
        onError:[userAuthenticationRequestFailed.type,errorUIDisplayed.type],
    }))
}

export const resetPassword = (formData)=>(dispatch,getState)=>{
    const {userId:activeUserId}  = getState().auth.loggedInUser;
    
    const data = {
        userId:activeUserId,
        ...formData,
    }

    dispatch(apiCallBegan({
        url:`${baseUserUrl}/resetPassword`,
        method:'post',
        data,
        onStart:[loadingIndicatorUIStart.type],
        onSuccess:[userPasswordResetComplete.type],
        onError:[resetPasswordUIOnError.type],
    }))
}

export const requestToResetPasswordFromForgotPasswordView = (formData)=>(dispatch,getState)=>{
        //console.log(formData);
        
        dispatch(apiCallBegan({
            url:`${baseUserUrl}/requestToResetPasswordForNonLoggedInUser`,
            method:'post',
            data:formData,
            onStart:[forgotPasswordUILoadingIndicatorStart.type],
            onSuccess:[forgotPasswordUIRestPasswordSaved.type],
            onError:[forgotPasswordUIOnError.type,forgotPasswordUILoadingIndicatorEnd.type],
        }))
    
}

/*
    We send the request:
     - reset password
     - send email
     - display notification on the screen
*/
export const requestToResetPasswordFromPersonalProfileView = ()=>(dispatch,getState)=>{
    const {userId:activeUserId,activeView}  = getState().auth.activeView;
    if(activeUserId){
        //console.log(formData);
        const data = {
            userId:activeUserId,
            activeView,
        }

        dispatch(apiCallBegan({
            url:`${baseUserUrl}/requestToResetPasswordLoggedInUser`,
            method:'post',
            data,
            onStart:[personalProfileUIResetPasswordInProgress.type],
            onSuccess:[personalProfileUIResetPasswordSaved.type],
            onError:[personalProfileUIOnError.type],
        }))
    }
}

export const savePersonalProfile = (formData)=>(dispatch,getState)=>{
    const {activeUserId,activeView}  = getState().auth;
    if(activeUserId){
        //console.log(formData);
        const message = "Your personal profile has been updated";
        const data = {
            ...formData,
            activeView,
            userId:activeUserId,
            successNotification:{message},
        
        }

        dispatch(apiCallBegan({
            url:`${baseUserUrl}/savePersonalProfile`,
            method:'post',
            data,
            onStart:[personalProfileUISaveInProgress.type],
            onSuccess:[userPersonalProfileSaved.type,personalProfileUISaved.type],
            onError:[personalProfileUIOnError.type],
        }))
    }
}

export const saveUserNotificationSettings = ({formData,originalData})=>(dispatch,getState)=>{
    const {activeUserId,activeView}  = getState().auth;
    if(activeUserId){
        //console.log(formData);
        const message = "Your notification setting has been updated";
        const data = {
            ...formData,
            originalData,
            activeView,
            userId:activeUserId,
            successNotification:{message},
        
        }

        dispatch(apiCallBegan({
            url:`${baseUserUrl}/saveUserNotificationSettings`,
            method:'post',
            data,
            onStart:[notificationSettingUISaveInProgress.type],
            onSuccess:[userNotificationSettingSaved.type,notificationSettingUISaved.type],
            onError:[notificationSettingUIOnError.type],
        }))
    }
}


/*Fetch from database list of user permission records */
export const setAccessControlListForUser = ()=>(dispatch,getState)=>{
    const {activeUserId,activeView}  = getState().auth;

    if(activeUserId){
        const data = {
            userId:activeUserId,
            activeView,
        }

        dispatch(apiCallBegan({
            url:`${baseUserUrl}/getAccessControlListForUser`,
            method:'post',
            data,
            onStart:[userAuthenticationRequested.type],
            onSuccess:[userAccessControlListReceived.type],
            onError:[userAuthenticationRequestFailed.type,errorUIDisplayed.type],
        }))
    }
}


export const setActiveView = (formData)=>(dispatch,getState)=>{
    const {activeUserId,accessControlList} = getState().auth;
    const {clientAccountId,clientAccountName,clientId,clientName,role,roleId,
        ...rest
    } = formData;

    const data = {
       userId: activeUserId, 
       user:accessControlList.user,
       roleId:roleId,
       role:role, 
       permissionList:{...rest},
       client:{
          id:clientId,
          name:clientName,
       },
       clientAccount:{
          id:clientAccountId,
          name:clientAccountName,
       }
    }

    dispatch(activeViewReceived({
        activeView:data
    })) 
}

/*This gets called from JE select user screen */
export const setActiveUserId = (formData)=>(dispatch,getState)=>{
    const {userId} = formData;
    //console.log(formData);
    dispatch(activeUserIdReceived({
        activeUserId:userId
    })) 
}


export const setLoggedInUserPopulatedFromLocalStorage = ()=>(dispatch,getState)=>{
    const loggedInUser = authService.getCurrentUser();
    if(loggedInUser !== null){
        dispatch(loggedInUserReceived({
            loggedInUser
        }))
        
        const activeUserId = authService.getActiveUserId();
        if(activeUserId !== null){
            dispatch(activeUserIdReceived({
                activeUserId
            })) 
        }

        const activeView = authService.getActiveView();
        if(activeView !== null){
            dispatch(activeViewReceived({
                activeView
            })) 
        }
    }
}




//------------------Selectors-----------------------

export const getAccessControlList = createSelector(
    state=>state.auth,
    auth=>{
        return auth.accessControlList;
    }
);

export const getActiveClient = createSelector(
    state=>state.auth,
    auth=>{
        return (auth.activeView !== "") ? auth.activeView.client:"";
    }
);

export const getActiveClientAccount = createSelector(
    state=>state.auth,
    auth=>{
        return (auth.activeView !== "") ? auth.activeView.clientAccount:"";
    }
);

export const getActiveClientDetails = createSelector(
    state=>state.auth,
    auth=>{
        if(auth.activeView !== ""){
            const clientId = auth.activeView.client.id
            return (
                clientId && 
                auth.accessControlList && 
                auth.accessControlList.clientById && 
                auth.accessControlList.clientById[clientId]) ? auth.accessControlList.clientById[clientId] : "";
        }
        else 
         return "";
    }
);

export const getActiveClientAccountDetails = createSelector(
    state=>state.auth,
    auth=>{
        if(auth.activeView !== ""){
            const clientAccountId = auth.activeView.clientAccount.id
            return (
                clientAccountId && 
                auth.accessControlList && 
                auth.accessControlList.clientAccountById && 
                auth.accessControlList.clientAccountById[clientAccountId]) ? auth.accessControlList.clientAccountById[clientAccountId] : "";
        }
        else 
         return "";
    }
);


export const getActiveView= createSelector(
    state=>state.auth,
    auth=>auth.activeView
);

//Get list of active client accounts based on the active user id to display in the select-account screen
export const getClientAccounts = createSelector(
    state=>state.auth,
    auth=>{
        if(auth.accessControlList !== "" && auth.accessControlList.permissions){
            return _.filter(auth.accessControlList.permissions,p=>p.active)
        }
        else 
           return [];
        
        //return (auth.accessControlList !== "") ? auth.accessControlList.permissions:[];
    }
);


export const getLoggedInUser= createSelector(
    state=>state.auth,
    auth=>auth.loggedInUser
);

export const getUserNotificationSettings = createSelector(
    state=>state.auth,
    auth=>{
        return (auth.accessControlList !== "") ? auth.accessControlList.notificationSettings:[];
    }
);


export const isAccessControlListPopulated = createSelector(
    state=>state.auth,
    auth=>auth.accessControlList !== "" ? true:false
);


export const isActiveClientAccountSelected = createSelector(
    state=>state.auth,
    auth=>auth.activeView !== "" ? true:false
);

export const isActiveUserIdSelected = createSelector(
    state=>state.auth,
    auth=>auth.activeUserId !== "" ? true:false
);

export const isUserLoggedIn = createSelector(
    state=>state.auth,
    auth=>auth.loggedInUser !== "" ? true:false
);

export const isUserJeAdministrator = createSelector(
    state=>state.auth,
    auth=>auth.loggedInUser.isJEAdministrator
);

export const getAccessToCandidatesModule = createSelector(
    state=>state.auth,
    auth=>{
        /*
        //Commented this as it would show the link to for all client account selected by an JE admin
        // For JE staff to see all publication data they need to go to Jobelephant account
        if(auth.loggedInUser.isJEAdministrator)
           return true
        */

        if(auth.activeView && auth.activeView.permissionList){
            return auth.activeView.permissionList.manageCandidates
        }

        return false
    }
);




//----------------End of Selectors-----------------------


