import { createSlice } from "@reduxjs/toolkit";
import {createSelector} from "reselect";
import _ from "lodash";
import moment from "moment";
import { toast, Flip } from 'react-toastify';
import {saveAs} from "file-saver"
import JSZip from "jszip";
import axios from "axios";

import applicationContants from "./applicationConstants";

import { apiCallBegan } from "./api"
import {getActiveView} from "./auth";

import {
    notificationsByClientAndClientAccountFetchTimestampCleared
} from "./clients"

import {
    campaignItemErrorUIDisplayedPerView,
    campaignItemErrorUIDisplayedFromModifyAdScreen,
    
    campaignItemErrorUIDisplayedFromReviewArtworkScreen,
    campaignItemErrorUIDisplayedFromSelectPublicationScreen,
    campaignItemSuccessUIDisplayedPerView,
    campaignItemSuccessUIDisplayedFromModifyAdScreen,
    campaignItemSuccessUIDisplayedFromSelectPublicationScreen,
} from "./ui-campaign-details";

import {
    campaignsForDashboardUIViewReceived,
} from "./ui-dashboard"


const {
    AD_STATUS_DB_PROCESSING,
    AD_STATUS_DB_REMOVAL_IN_PROGRESS,

    CAMPAIGN_ALL,
    CAMPAIGN_GET_FOR_ALL_VIEWS,
    CAMPAIGN_STATUS_ACTIVE,
    CAMPAIGN_STATUS_ARCHIVED,
    CAMPAIGN_STATUS_DRAFT,

    CAMPAIGN_DETAILS_TAB_READY_FOR_APPROVAL,
    CAMPAIGN_DETAILS_TAB_REVIEW_BY_JE,
    CAMPAIGN_DETAILS_TAB_PROCESSING,
    CAMPAIGN_DETAILS_TAB_PUBLISHED,
    CAMPAIGN_DETAILS_TAB_CANCELED,
    CAMPAIGN_DETAILS_TAB_EXPIRED,
    CAMPAIGN_DETAILS_TAB_ALL,

    ENDPOINT_FOR_CAMPAIGN_SLICE,
    ENDPOINT_FOR_PENDING_QUOTE_SLICE,

    REFETCH_THRESHOLD_IN_MINUTES,
} = applicationContants

const listOfStatusToProcess = [
    CAMPAIGN_ALL,
    CAMPAIGN_STATUS_ACTIVE,
    CAMPAIGN_STATUS_ARCHIVED,
    //CAMPAIGN_STATUS_DRAFT,
]

const listOfCampaignItemStatusToProcess = [
    CAMPAIGN_DETAILS_TAB_READY_FOR_APPROVAL,
    CAMPAIGN_DETAILS_TAB_REVIEW_BY_JE,
    CAMPAIGN_DETAILS_TAB_PROCESSING,
    CAMPAIGN_DETAILS_TAB_PUBLISHED,
    CAMPAIGN_DETAILS_TAB_CANCELED,
    CAMPAIGN_DETAILS_TAB_EXPIRED,
]

const emptyCampaignObject = {
    byIds:{},
    allIds:[],
    campaignCounts:{
        [CAMPAIGN_ALL]:0,
        [CAMPAIGN_STATUS_ACTIVE]:0,
        [CAMPAIGN_STATUS_ARCHIVED]:0,
        [CAMPAIGN_STATUS_DRAFT]:0,
    },
    idListByStatus:{
        [CAMPAIGN_ALL]:[],
        [CAMPAIGN_STATUS_ACTIVE]:[],
        [CAMPAIGN_STATUS_ARCHIVED]:[],
        [CAMPAIGN_STATUS_DRAFT]:[],
    },
    //campaignDetailsByIds:{},
    recommendations:[],
    loading:false,
    lastFetch:null,
    campaignItemTaskProcessingFlag:false, //This is to load a processing indicator and we are not keeping at individual campaignItem level

}

const toastNotificationMessages = {
    tearsheetDownloadedSuccess:{
        message:"Tearsheet has been downloaded",
        position:{containerId: 'B', transition: Flip},
    },
    tearsheetDownloadedInProcess:{
        message:"Tearsheet package is being generated",
        position:{containerId: 'B', transition: Flip},
    },
    campaignSummaryDownloadSuccess:{
        message:"Campaign summary has been downloaded",
        position:{containerId: 'B', transition: Flip},
    },
    campaignSummaryDownloadInProcess:{
        message:"Campaign summary is being generated",
        position:{containerId: 'B', transition: Flip},
    },
    campaignItemsStoppedSuccess:{
        position:{containerId: 'B', transition: Flip},
    },
}

//Slice: Create reducers and actions
const slice = createSlice({
    name:"campaigns",
    initialState:{},
    reducers:{
        campaignsReceivedForAllStatus:(campaigns,action)=>{
           //This will have a data-structure for each status in the payload for 1 page
           const {clientId,clientAccountId} = action.payload; 
            
           listOfStatusToProcess.forEach(status=>{
                const {data,totalCount} = action.payload[status];
                campaigns[clientId][clientAccountId].campaignCounts[status] = totalCount;
                if(!_.isEmpty(data)){
                    const idList = _.map(data,'id');
                    campaigns[clientId][clientAccountId].idListByStatus[status] = idList;
                    
                    if(!campaigns[clientId][clientAccountId].campaignDetailsByIds)
                        campaigns[clientId][clientAccountId].campaignDetailsByIds = {}

                    const campaignDetailsByIds = campaigns[clientId][clientAccountId].campaignDetailsByIds;
                            
                    data.forEach(campaignRecord=>{
                        const {id} = campaignRecord;
                        if(!campaigns[clientId][clientAccountId].allIds.includes(id)) 
                          campaigns[clientId][clientAccountId].allIds.push(id);
                        
                        campaigns[clientId][clientAccountId].byIds[id] = _.omit(campaignRecord, ['campaignItems']);
                        
                        //Initialize and populate the campaign details
                        if(!campaignDetailsByIds[id])
                            campaignDetailsByIds[id] = campaignRecord.campaignItems;
                        //End of Initialize and populate the campaign details
                    })
                }
                else{
                    campaigns[clientId][clientAccountId].idListByStatus[status] = [];
                }
            });

            campaigns[clientId][clientAccountId].loading=false;
            campaigns[clientId][clientAccountId].lastFetch = Date.now();
        },

        campaignsReceivedForDashboard:(campaigns,action)=>{
            //This will be for individual status for 1 page
            const {clientId,clientAccountId} = action.payload; 
            const {view:status} = action.payload;
            const {data,totalCount} = action.payload[status];
            campaigns[clientId][clientAccountId].campaignCounts[status] = totalCount;
            if(!_.isEmpty(data)){
                if(!campaigns[clientId][clientAccountId].campaignDetailsByIds)
                        campaigns[clientId][clientAccountId].campaignDetailsByIds = {}

                const campaignDetailsByIds = campaigns[clientId][clientAccountId].campaignDetailsByIds;
                
                data.forEach(campaignRecord=>{
                    const {id} = campaignRecord;
                    if(!campaigns[clientId][clientAccountId].allIds.includes(id)) 
                       campaigns[clientId][clientAccountId].allIds.push(id);
                    
                    campaigns[clientId][clientAccountId].byIds[id] = _.omit(campaignRecord, ['campaignItems']);
                    
                    //Initialize and populate the campaign details
                    if(!campaignDetailsByIds[id])
                        campaignDetailsByIds[id] = campaignRecord.campaignItems;
                    //End of Initialize and populate the campaign details
                })
            }
            campaigns[clientId][clientAccountId].loading=false;
        },
        
        campaignsReceivedForIndividualStatus:(campaigns,action)=>{
            //This will be for individual status for 1 page
            const {clientId,clientAccountId} = action.payload; 
            const {view:status} = action.payload;
            const {data,totalCount} = action.payload[status];
            campaigns[clientId][clientAccountId].campaignCounts[status] = totalCount;
            if(!_.isEmpty(data)){
                const idList = _.map(data,'id');
                campaigns[clientId][clientAccountId].idListByStatus[status] = idList;
                
                if(!campaigns[clientId][clientAccountId].campaignDetailsByIds)
                        campaigns[clientId][clientAccountId].campaignDetailsByIds = {}

                const campaignDetailsByIds = campaigns[clientId][clientAccountId].campaignDetailsByIds;
                
                data.forEach(campaignRecord=>{
                    const {id} = campaignRecord;
                    if(!campaigns[clientId][clientAccountId].allIds.includes(id)) 
                       campaigns[clientId][clientAccountId].allIds.push(id);
                    
                    campaigns[clientId][clientAccountId].byIds[id] = _.omit(campaignRecord, ['campaignItems']);
                    
                    //Initialize and populate the campaign details
                    if(!campaignDetailsByIds[id])
                        campaignDetailsByIds[id] = campaignRecord.campaignItems;
                    //End of Initialize and populate the campaign details
                })
            }
            campaigns[clientId][clientAccountId].loading=false;
            
        },
        
        campaignAdded:(campaigns,action)=>{
            const {clientId,clientAccountId} = action.payload; 
            campaigns[clientId][clientAccountId].list.push(action.payload)
        },

        campaignsRequested:(campaigns,action)=>{
            const {clientId,clientAccountId} = action.payload.data; 
            campaigns[clientId][clientAccountId].loading=true;
        },
        
        campaignsRequestedFailed:(campaigns,action)=>{
            const {clientId,clientAccountId} = action.payload.data; 
            campaigns[clientId][clientAccountId].loading = false;
        },

        campaignDetailsRecommendationsReceived:(campaigns,action)=>{
            const {clientId,clientAccountId,data} = action.payload; 
            if(!_.isEmpty(data) && campaigns[clientId][clientAccountId].recommendations.length === 0){
                campaigns[clientId][clientAccountId].recommendations = data;
            }
        },

        campaignDetailsRecommendationsRequested:(campaigns,action)=>{
            //campaigns.loading=true;
            //For now I am not using this as the skeleton indicator itself does the work.
            
        },
        
        campaignDetailsRecommendationsFailed:(campaigns,action)=>{
            //campaigns.loading=false;   
        },

        campaignDetailsReceived:(campaigns,action)=>{
            //console.log(action)
            const {clientId,clientAccountId} = action.payload; 
            const {campaign,campaignItems} = action.payload; 
            const {id} = campaign;
            
            //Add to campaigns data structure
            campaigns[clientId][clientAccountId].byIds[id] = campaign;
            if(!campaigns[clientId][clientAccountId].allIds.includes(id)) 
              campaigns[clientId][clientAccountId].allIds.push(id);
            
            //Add/Update to campaigns data structure
            if(!campaigns[clientId][clientAccountId].campaignDetailsByIds)
               campaigns[clientId][clientAccountId].campaignDetailsByIds = {};
            
            //Load data
            campaigns[clientId][clientAccountId].campaignDetailsByIds[id] = campaignItems;
            campaigns[clientId][clientAccountId].campaignDetailsByIds[id].loading = false;
            campaigns[clientId][clientAccountId].campaignDetailsByIds[id].lastFetch = Date.now();
            
            campaigns[clientId][clientAccountId].loading=false;  
        },

                
        campaignDetailsRequested:(campaigns,action)=>{
            //campaigns.loading=true;
            //For now I am not using this as the skeleton indicator itself does the work.
            
        },
        
        campaignDetailsRequestedFailed:(campaigns,action)=>{
            //campaigns.loading=false;   
        },

        campaignItemPerformTaskRequested:(campaigns,action)=>{
            const {data} = action.payload;
            const {clientId,clientAccountId} = data;
            campaigns[clientId][clientAccountId].campaignItemTaskProcessingFlag = true;
        },

        campaignItemPerformTaskRequestedFailed:(campaigns,action)=>{
            //console.log("Dispatched: campaignItemPerformTaskRequestedFailed")
            //console.log(action.payload)
            
            const {data} = action.payload;
            const {clientId,clientAccountId} = data;
            campaigns[clientId][clientAccountId].campaignItemTaskProcessingFlag = false;
        },

        campaignItemAdModified: (campaigns,action)=>{
            const {clientId,clientAccountId,} = action.payload;
            campaigns[clientId][clientAccountId].campaignItemTaskProcessingFlag = false;
        },

        campaignItemAdModifiedRequested: (campaigns,action)=>{
            const {data} = action.payload;
            const {clientId,clientAccountId,} = data;
            campaigns[clientId][clientAccountId].campaignItemTaskProcessingFlag = true;
        },

        campaignItemAdModifiedFailed: (campaigns,action)=>{
            const {data} = action.payload;
            const {clientId,clientAccountId,} = data;
            campaigns[clientId][clientAccountId].campaignItemTaskProcessingFlag = false;
        },

        campaignItemArtworkAcknowledgedByUser: (campaigns,action)=>{
            const {clientId,clientAccountId,campaignId,campaignItemId,acknowledgeStatus} = action.payload;
            let campaignDetailObjectByCampaign = campaigns[clientId][clientAccountId].campaignDetailsByIds[campaignId];
            campaignDetailObjectByCampaign.byIds[campaignItemId].isArtworkAcknowledgedByClient = acknowledgeStatus;
            campaigns[clientId][clientAccountId].campaignItemTaskProcessingFlag = false;
        },

        campaignItemArtworkOpenedByUser: (campaigns,action)=>{
            const {clientId,clientAccountId,campaignId,campaignItemId} = action.payload;
            let campaignDetailObjectByCampaign = campaigns[clientId][clientAccountId].campaignDetailsByIds[campaignId];
            campaignDetailObjectByCampaign.byIds[campaignItemId].isArtworkDocumentOpenedByClient = true;
            campaigns[clientId][clientAccountId].campaignItemTaskProcessingFlag = false;
        },

        
        campaignItemListForPublishingApproved:(campaigns,action)=>{
            const {clientId,clientAccountId,campaignId,campaignItems} = action.payload;
            campaigns[clientId][clientAccountId].campaignItemTaskProcessingFlag = false;

            /*Get the list of campaign items  */
            let campaignItemPublishedIdList = _.map(campaignItems, 'id');
            const itemsPublishedCount = campaignItemPublishedIdList.length;
            
            let campaignDetailObjectByCampaign = campaigns[clientId][clientAccountId].campaignDetailsByIds[campaignId];
            
            campaignDetailObjectByCampaign.itemCounts[CAMPAIGN_DETAILS_TAB_PROCESSING] += itemsPublishedCount;
            campaignDetailObjectByCampaign.itemCounts[CAMPAIGN_DETAILS_TAB_READY_FOR_APPROVAL] -= itemsPublishedCount;
            
            campaignItemPublishedIdList.forEach(id=>{
                campaignDetailObjectByCampaign.byIds[id].adStatus = AD_STATUS_DB_PROCESSING;
                campaignDetailObjectByCampaign.idListByStatus[CAMPAIGN_DETAILS_TAB_PROCESSING].push(id);

                const index = campaignDetailObjectByCampaign.idListByStatus[CAMPAIGN_DETAILS_TAB_READY_FOR_APPROVAL].indexOf(id);
                campaignDetailObjectByCampaign.idListByStatus[CAMPAIGN_DETAILS_TAB_READY_FOR_APPROVAL].splice(index,1) 
            })

            
        },

        campaignItemListForPublishingDisapproved:(campaigns,action)=>{
            const {clientId,clientAccountId,campaignId,campaignItems} = action.payload;
            campaigns[clientId][clientAccountId].campaignItemTaskProcessingFlag = false;

            /*Get the list of campaign items  */
            let campaignItemIdList = _.map(campaignItems, 'id');
            const itemsCanceledCount = campaignItemIdList.length;
            
            let campaignDetailObjectByCampaign = campaigns[clientId][clientAccountId].campaignDetailsByIds[campaignId];
            
            /*
                The item could be in either the processing queue or the ready for approval 
                so find the status and decrement that one 
            */
            for(var item of campaignItems){
                const {id:campaignItemId} = item;
                listOfCampaignItemStatusToProcess.forEach(status=>{
                   if(campaignDetailObjectByCampaign.idListByStatus[status].includes(campaignItemId)){
                        //Update the counts    
                        campaignDetailObjectByCampaign.itemCounts[CAMPAIGN_DETAILS_TAB_CANCELED] += 1;
                        campaignDetailObjectByCampaign.itemCounts[status] -= 1;

                        //Add it to canceled queue
                        campaignDetailObjectByCampaign.idListByStatus[CAMPAIGN_DETAILS_TAB_CANCELED].push(campaignItemId);
                    
                        //Remove it from the original status queue
                        const index = campaignDetailObjectByCampaign.idListByStatus[status].indexOf(campaignItemId);
                        campaignDetailObjectByCampaign.idListByStatus[status].splice(index,1)
                        
                   }
                })
                
            }

            
        },

        campaignItemListStopAds:(campaigns,action)=>{
            const {clientId,clientAccountId,campaignId,campaignItems,stopWholeCampaignFlag,successNotification} = action.payload;
            campaigns[clientId][clientAccountId].campaignItemTaskProcessingFlag = false;

            //console.log(action.payload);
            
            let campaignItemPublishedIdList = _.map(campaignItems, 'id');
            const itemsPublishedCount = campaignItemPublishedIdList.length;
            
            let campaignDetailObjectByCampaign = campaigns[clientId][clientAccountId].campaignDetailsByIds[campaignId];
            
            campaignDetailObjectByCampaign.itemCounts[CAMPAIGN_DETAILS_TAB_PROCESSING] += itemsPublishedCount;
            campaignDetailObjectByCampaign.itemCounts[CAMPAIGN_DETAILS_TAB_PUBLISHED] -= itemsPublishedCount;
            
            campaignItemPublishedIdList.forEach(id=>{
                campaignDetailObjectByCampaign.byIds[id].adStatus = AD_STATUS_DB_REMOVAL_IN_PROGRESS;
                
                campaignDetailObjectByCampaign.idListByStatus[CAMPAIGN_DETAILS_TAB_PROCESSING].push(id);

                const index = campaignDetailObjectByCampaign.idListByStatus[CAMPAIGN_DETAILS_TAB_PUBLISHED].indexOf(id);
                campaignDetailObjectByCampaign.idListByStatus[CAMPAIGN_DETAILS_TAB_PUBLISHED].splice(index,1) 
            })

            //We display as toast if we stop the whole campaign as we are not bound to a specific view
            if(stopWholeCampaignFlag === true){
                const {position} = toastNotificationMessages.campaignItemsStoppedSuccess
                toast.success(successNotification.message,position);
            }
        },

        campaignItemListForTearsheetDownload:(campaigns,action)=>{
            //console.log(action);
            const zipBlob = new Blob([action.payload], {type : 'application/zio'})
            saveAs(zipBlob,"Tearsheets.zip"); 
            /*
            const {clientId,clientAccountId} = action.payload;
            campaigns[clientId][clientAccountId].campaignItemTaskProcessingFlag = false;
            const {message,position} = toastNotificationMessages.tearsheetDownloadedSuccess
            toast.success(message,position);
            */
        },

        clientAndClientAccountAdded:(campaigns,action)=>{
            const {clientId,clientAccountId} = action.payload;
            if(!_.has(campaigns, `${clientId}.${clientAccountId}`)){
                campaigns[clientId] = {};
                campaigns[clientId][clientAccountId] = {...emptyCampaignObject}
            }
        },

        
        publicationAddedToExistingCampaign:(campaigns,action)=>{
            const {clientId,clientAccountId} = action.payload;
            campaigns[clientId][clientAccountId].campaignItemTaskProcessingFlag = false;
        },

        
        
    }
})

//---------------Actions & Reducer export setup----------------------------
export const {
    campaignAdded,
    campaignsReceivedForAllStatus,
    campaignsReceivedForDashboard,
    campaignsReceivedForIndividualStatus,
    campaignsRequested,
    campaignsRequestedFailed,
    
    campaignDetailsRecommendationsReceived,
    campaignDetailsRecommendationsRequested,
    campaignDetailsRecommendationsFailed,

    campaignDetailsReceived,
    campaignDetailsRequested,
    campaignDetailsRequestedFailed,

    campaignItemPerformTaskRequested,
    campaignItemPerformTaskRequestedFailed,

    campaignItemAdModified,
    campaignItemAdModifiedRequested,
    campaignItemAdModifiedFailed,

    campaignItemArtworkAcknowledgedByUser,
    campaignItemArtworkOpenedByUser,

    campaignItemListForPublishingApproved,
    campaignItemListForPublishingDisapproved,

    campaignItemListForTearsheetDownload,

    campaignItemListStopAds,
    
    clientAndClientAccountAdded,

    publicationAddedToExistingCampaign,


}  = slice.actions;

//Export reducer
export default slice.reducer;
//---------------End of Actions & Reducer export setup----------------------------


//-------------Export Action Creators-----------------------
const url = ENDPOINT_FOR_CAMPAIGN_SLICE;
const urlForPendingQuotes = ENDPOINT_FOR_PENDING_QUOTE_SLICE;

const ROUTE_GET_CAMPAIGNS_BY_FILTER = "getCampaignsByFilter";
const ROUTE_GET_CAMPAIGN_DETAILS = "getCampaignDetails";

const ROUTE_ADD_PUBLICATIONS_TO_EXISTING_CAMPAIGN = "addPublicationsToExistingCampaign";

const ROUTE_CAMPAIGN_DETAIL_SUMMARY_DOWNLOAD = "campaignDetailSummaryDownload";


const ROUTE_CAMPAIGN_ITEM_LIST_FOR_PUBLISHING_APPROVE = "campaignItemListForPublishingApproved";
const ROUTE_CAMPAIGN_ITEM_LIST_FOR_PUBLISHING_DISAPPROVE = "campaignItemListForPublishingDisapproved";

const ROUTE_CAMPAIGN_ITEM_LIST_FOR_TEARSHEET_DOWNLOAD = "campaignItemListForTearsheetDownload";

const ROUTE_CAMPAIGN_ITEM_LIST_FOR_STOP_AD = "campaignItemListForStopAd";

const ROUTE_CAMPAIGN_ITEM_LOG_ARTWORK_OPENED_BY_USER = "campaignItemLogArtworkHasBeenOpenedByUserAction";
const ROUTE_CAMPAIGN_ITEM_LOG_ARTWORK_ACKNOWLEDGED_BY_USER = "campaignItemLogArtworkHasBeenAcknowledgedByUserAction";

const ROUTE_CAMPAIGN_ITEM_MODIFY_AD = "campaignItemModifyAd";

const ROUTE_GET_RECOMMENDATIONS = "getRecommendations";


//Add a campaign - This will be triggered by request a quote
export const addCampaign = campaign=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    dispatch(apiCallBegan({
        url,
        method:"post",
        data:campaign,
        onSuccess:[campaignAdded.type],
    }));
}

/*
  formdata:{
      campaign,
      listOfPublications,
  }
*/
export const addPublicationsToExistingCampaign = (formData)=>(dispatch,getState)=>{
    const {activeView,accessControlList}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    const {clientAccountById} = accessControlList;
    const {onBehalfOfClient} = clientAccountById[clientAccountId];


    //If contracts were selected then bind the contract constructs in the payload
    let listOfSelectedContracts = [];
    if(formData.listOfPublications.contracts.length){
        const {contracts} = onBehalfOfClient;
        listOfSelectedContracts = formData.listOfPublications.contracts.map(c=>{
            return _.find(contracts,{'id':c})
        })
    }
    
    const {campaign,campaignDetails,listOfPublications} = formData;

    const data = {
        activeView,
        clientId,
        clientAccountId,
        campaign,
        campaignDetails,
        listOfPublications:{
            ...listOfPublications,
            listOfSelectedContracts,
        },
        successNotification:{message:`Your publications have been added to the campaign and will be reviewed by us shortly.`}
    }

    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_ADD_PUBLICATIONS_TO_EXISTING_CAMPAIGN}`,
        method:'post',
        data,
        onStart:[campaignItemPerformTaskRequested.type],
        onSuccess:[campaignDetailsReceived.type,publicationAddedToExistingCampaign.type,campaignItemSuccessUIDisplayedFromSelectPublicationScreen.type],
        onError:[campaignItemPerformTaskRequestedFailed.type,campaignItemErrorUIDisplayedFromSelectPublicationScreen.type]
    }))
    
}

export const approveCampaignItemFromReviewArtwork = campaignItemsObject=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const {campaignId,campaignItems} = campaignItemsObject;
    const data = {
        activeView,
        clientId,
        clientAccountId,
        campaignId,
        campaignItems,
        errorNotification:{message:`We encounted an error while processing your request. Please contact Jobelephant or try again.`}
    }

    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_CAMPAIGN_ITEM_LIST_FOR_PUBLISHING_APPROVE}`,
        method:'post',
        data,
        onStart:[campaignItemPerformTaskRequested.type],
        onSuccess:[campaignItemListForPublishingApproved.type],
        onError:[campaignItemPerformTaskRequestedFailed.type,campaignItemErrorUIDisplayedFromReviewArtworkScreen.type]
    }))
}

/* Handles both single item and a set of campaign items */
export const approveCampaignItemListForPublishing = campaignItemsObject=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const {campaignId,campaignItems,tabView} = campaignItemsObject;
    const data = {
        activeView,
        clientId,
        clientAccountId,
        campaignId,
        campaignItems,
        tabView,
        successNotification:{message:`Your ad will be set for publishing shortly and has been set to "Processing"`}
    }

    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_CAMPAIGN_ITEM_LIST_FOR_PUBLISHING_APPROVE}`,
        method:'post',
        data,
        onStart:[campaignItemPerformTaskRequested.type],
        onSuccess:[
            campaignItemListForPublishingApproved.type,
            campaignItemSuccessUIDisplayedPerView.type,
            notificationsByClientAndClientAccountFetchTimestampCleared.type
        ],
        onError:[campaignItemPerformTaskRequestedFailed.type,campaignItemErrorUIDisplayedPerView.type]
    }))
}

/* Handles both single item and a set of campaign items */
export const disapproveCampaignItemListForPublishing = campaignItemsObject=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const {campaignId,campaignItems,tabView} = campaignItemsObject;
    const data = {
        activeView,
        clientId,
        clientAccountId,
        campaignId,
        campaignItems,
        tabView,
        successNotification:{message:`Your request has been processed and the quote item will not be published.`}
    }

    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_CAMPAIGN_ITEM_LIST_FOR_PUBLISHING_DISAPPROVE}`,
        method:'post',
        data,
        onStart:[campaignItemPerformTaskRequested.type],
        onSuccess:[
            campaignItemListForPublishingDisapproved.type,
            campaignItemSuccessUIDisplayedPerView.type,
            notificationsByClientAndClientAccountFetchTimestampCleared.type,
        ],
        onError:[campaignItemPerformTaskRequestedFailed.type,campaignItemErrorUIDisplayedPerView.type],
    }))
}

export const downloadCampaignSummary =  campaignObject=>async (dispatch,getState)=>{
    const {activeView,accessControlList}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const {clientAccountById} = accessControlList;
    const {onBehalfOfClient} = clientAccountById[clientAccountId];

    const {campaignId} = campaignObject;
    const data = {
        activeView,
        clientId,
        clientAccountId,
        campaignId,
        onBehalfOfClient,
    }

   let message,position;
   ({message,position} = toastNotificationMessages.campaignSummaryDownloadInProcess);
   toast.info(message,position);
   const {data:content} = await axios.post(`${url}/${ROUTE_CAMPAIGN_DETAIL_SUMMARY_DOWNLOAD}`,data,{responseType:'blob'});
   const pdfBlob = new Blob([content], {type : 'application/pdf'})
   saveAs(pdfBlob,"CampaignSummary.pdf");  
   
   ({message,position} = toastNotificationMessages.campaignSummaryDownloadSuccess);
   toast.success(message,position);
   
}

export const downloadTearsheetCampaignItemList =  campaignItemsObject=>async (dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const {campaignId,campaignItems,tabView} = campaignItemsObject;
    const data = {
        activeView,
        clientId,
        clientAccountId,
        campaignId,
        campaignItems,
        tabView,
    }
    
    
   let message,position;
   ({message,position} = toastNotificationMessages.tearsheetDownloadedInProcess);
   toast.info(message,position);
   const {data:content} = await axios.post(`${url}/${ROUTE_CAMPAIGN_ITEM_LIST_FOR_TEARSHEET_DOWNLOAD}`,data,{responseType:'blob'});
   const zipBlob = new Blob([content], {type : 'application/zip'})
   saveAs(zipBlob,"Tearsheets.zip");  
   
   ({message,position} = toastNotificationMessages.tearsheetDownloadedSuccess);
   toast.success(message,position);
   
      
    /*
    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_CAMPAIGN_ITEM_LIST_FOR_TEARSHEET_DOWNLOAD}`,
        method:'post',
        data,
        //onStart:[campaignItemPerformTaskRequested.type],
        onSuccess:[campaignItemListForTearsheetDownload.type],
        onError:[campaignItemPerformTaskRequestedFailed.type,campaignItemErrorUIDisplayedPerView.type],
        responseType:'blob'
    }))
    */
}


//This is to make a call to the database for the initial load to get data for all the views
export const loadCampaigns = ()=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const  {campaigns} = getState().entities;

    if(_.has(campaigns,`${clientId}.${clientAccountId}`)){
        const {lastFetch} = campaigns[clientId][clientAccountId];
        const diffInMinutes = moment().diff(moment(lastFetch),'minutes');
        if(diffInMinutes < REFETCH_THRESHOLD_IN_MINUTES) return;
    }
    
    const query = {...getState().ui.myCampaignView.query};
    query.activeView = activeView;
    query.clientId = clientId;
    query.clientAccountId = clientAccountId;
    
    //This happens only for initial load: any switch in views updates the filtered view property in the query object
    query.filteredView = CAMPAIGN_GET_FOR_ALL_VIEWS;

    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_GET_CAMPAIGNS_BY_FILTER}`,
        method:'post',
        data:query,
        onStart:[campaignsRequested.type],
        onSuccess:[campaignsReceivedForAllStatus.type],
        onError:[campaignsRequestedFailed.type],
    }))
}

//Load active campaigns for dashboard
export const loadCampaignsForDashboard = ()=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const dashboardView = getState().ui.dashboard;

    const query = {...dashboardView.query};
    query.activeView = activeView;
    query.clientId = clientId;
    query.clientAccountId = clientAccountId;
    
    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_GET_CAMPAIGNS_BY_FILTER}`,
        method:'post',
        data:query,
        onStart:[campaignsRequested.type],
        onSuccess:[campaignsReceivedForDashboard.type,campaignsForDashboardUIViewReceived.type],
        onError:[campaignsRequestedFailed.type],
    }))
}

//Load campaigns per page for a specific filter
export const loadCampaignsPerFilter = ()=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const campaignView = getState().ui.myCampaignView;

    const query = {...campaignView.query};
    query.activeView = activeView;
    query.clientId = clientId;
    query.clientAccountId = clientAccountId;
    
    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_GET_CAMPAIGNS_BY_FILTER}`,
        method:'post',
        data:query,
        onStart:[campaignsRequested.type],
        onSuccess:[campaignsReceivedForIndividualStatus.type],
        onError:[campaignsRequestedFailed.type],
    }))
    
}

//Load campaigns by keyword and it updates the timestamp so it does not have to refetch if component unloads
export const loadCampaignsByKeyword = ()=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const campaignView = getState().ui.myCampaignView;
    
    const query = {...campaignView.query};
    query.activeView = activeView;
    query.clientId = clientId;
    query.clientAccountId = clientAccountId;
    
    //This happens only for initial load: any switch in views updates the filtered view property in the query object
    query.filteredView = CAMPAIGN_GET_FOR_ALL_VIEWS;

    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_GET_CAMPAIGNS_BY_FILTER}`,
        method:'post',
        data:query,
        onStart:[campaignsRequested.type],
        onSuccess:[campaignsReceivedForAllStatus.type],
        onError:[campaignsRequestedFailed.type],
    }))
}

export const loadCampaignsByKeywordForDashboard = ()=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const {dashboard:dashboardView}  = getState().ui;

    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const query = {...dashboardView.query};
    query.activeView = activeView;
    query.clientId = clientId;
    query.clientAccountId = clientAccountId;
    
    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_GET_CAMPAIGNS_BY_FILTER}`,
        method:'post',
        data:query,
        onStart:[campaignsRequested.type],
        onSuccess:[campaignsReceivedForDashboard.type,campaignsForDashboardUIViewReceived.type],
        onError:[campaignsRequestedFailed.type],
    }))
}



export const loadCampaignDetails = campaignId=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    //Need to get this from the auth slice
    const {campaigns} = getState().entities;
    if(_.has(campaigns,`${clientId}.${clientAccountId}.campaignDetailsByIds`)){
     const {campaignDetailsByIds} = getState().entities.campaigns[clientId][clientAccountId];
     //If campaignDetails is there then we don't need to make the api call
     if(campaignDetailsByIds.hasOwnProperty(campaignId)) return; 
    }

    const data = {
        activeView,
        clientId ,
        clientAccountId ,
        campaignId,
    }

    //console.log('loadCampaignDetails:' + campaignId);
    //console.log(campaignId);

    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_GET_CAMPAIGN_DETAILS}`,
        method:'post',
        data,
        onStart:[campaignDetailsRequested.type],
        onSuccess:[campaignDetailsReceived.type],
        onError:[campaignDetailsRequestedFailed.type],
    }))


}

export const loadRecommendations= (formData)=>(dispatch,getState)=>{
    const {activeView,accessControlList}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const  {campaigns} = getState().entities;

    if(_.has(campaigns,`${clientId}.${clientAccountId}`)){
        const {recommendations} = campaigns[clientId][clientAccountId];

        if(recommendations.length) return;
    }

    const quoteId = null;
    
    const {clientAccountById} = accessControlList;
    if(clientAccountById){
        const {onBehalfOfClientId} = clientAccountById[clientAccountId];
        const {userId} = activeView;
        
        const {
            campaignTitle,
            jobDescription,
        } = formData;
        
        const query = {
            activeView,
            clientId ,
            clientAccountId,
            onBehalfOfClientId,
            userId,
            activeQuoteId:quoteId,
            campaignTitle,
            jobDescription,

        };
        dispatch(apiCallBegan({
            url:`${urlForPendingQuotes}/${ROUTE_GET_RECOMMENDATIONS}`,
            method:'post',
            data:query,
            onStart:[campaignDetailsRecommendationsRequested.type],
            onSuccess:[campaignDetailsRecommendationsReceived.type],
            onError:[campaignDetailsRecommendationsFailed.type],
        }))
    }
    
    
}

export const logArtworkHasBeenAcknowledgedByUserAction = (campaignItemObject)=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const {acknowledgeStatus,adNumber,campaignId,campaignItemId} = campaignItemObject;
    const data = {
        activeView,
        adNumber,
        clientId,
        clientAccountId,
        campaignId,
        campaignItemId,
        acknowledgeStatus,
    }

    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_CAMPAIGN_ITEM_LOG_ARTWORK_ACKNOWLEDGED_BY_USER}`,
        method:'post',
        data,
        onStart:[campaignItemPerformTaskRequested.type],
        onSuccess:[campaignItemArtworkAcknowledgedByUser.type,],
        onError:[campaignItemPerformTaskRequestedFailed.type],
    }))
}

export const logArtworkHasBeenOpenedByUserAction = (campaignItemObject)=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const {adNumber,campaignId,campaignItemId} = campaignItemObject;
    const data = {
        activeView,
        adNumber,
        clientId,
        clientAccountId,
        campaignId,
        campaignItemId,
    }

    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_CAMPAIGN_ITEM_LOG_ARTWORK_OPENED_BY_USER}`,
        method:'post',
        data,
        onStart:[campaignItemPerformTaskRequested.type],
        onSuccess:[campaignItemArtworkOpenedByUser.type,],
        onError:[campaignItemPerformTaskRequestedFailed.type],
    }))
}

/*
This modifies the single ad or the whole campaign
const formData = {
            action:"update-whole-campaign" or "update-single-ad",
            campaign,
            campaignDetails:{byIds,idList,idListByStatus},
            campaignItemId,
            formData:{
                campaignTitle:"",
                jobUrl:"",
                jobNumber:"",
                jobLocation:"",
                isJobRemote:false,
                salaryType:salaryStatusTab.SALARY,
                salary:"",
                jobType:"",
                jobDescription:"",                
                deadlineType:"",
                deadlineDate:"", 
            },
        }
*/
export const modifyAd = (formObject)=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const {formData,...rest} = formObject
    const formDataForSubmission = {...formData};
    const {deadlineDate} = formDataForSubmission;
    formDataForSubmission.deadlineDate = (deadlineDate !== null) ? new Date(deadlineDate).toJSON():""

    const data = {
        activeView,
        clientId,
        clientAccountId,
        formData:formDataForSubmission,
        ...rest,
        successNotification:{message:`Your request for modifying the ad has been processed successfully.`}
    }
    console.log(data);
    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_CAMPAIGN_ITEM_MODIFY_AD}`,
        method:'post',
        data,
        onStart:[campaignItemAdModifiedRequested.type],
        onSuccess:[campaignItemAdModified.type,campaignDetailsReceived.type,campaignItemSuccessUIDisplayedFromModifyAdScreen.type],
        onError:[campaignItemAdModifiedFailed.type,campaignItemErrorUIDisplayedFromModifyAdScreen.type]
    }))
}


export const setClientAndClientAccount = ()=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    dispatch(clientAndClientAccountAdded({
        clientId,
        clientAccountId,
    }))

}

/*
  This will work to serve stopping an individual ad or a whole campaign
    - we supply all the items for stopping in the payload
*/
export const stopAdForCampaignItemsList = campaignItemsObject=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const {campaignId,campaignItems,stopWholeCampaignFlag,tabView} = campaignItemsObject;
    
    let message = `Your ad will be stopped shortly and will be processed in the next 24 hours. You ad has been moved to the "Processing" tab`;
    if (stopWholeCampaignFlag === true)
        message = `All ads published for the campaign will be stopped and processed in the next 24 hours. You ad has been moved to the "Processing" tab`;

    const data = {
        activeView,
        clientId,
        clientAccountId,
        campaignId,
        campaignItems,
        tabView,
        successNotification:{message},
        stopWholeCampaignFlag,
    }

    const onSuccess = [campaignItemListStopAds.type];
    const onError = [campaignItemPerformTaskRequestedFailed.type];
    
    if(!stopWholeCampaignFlag){
        onSuccess.push(campaignItemSuccessUIDisplayedPerView.type);
        onError.push(campaignItemErrorUIDisplayedPerView.type);
    }
    
    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_CAMPAIGN_ITEM_LIST_FOR_STOP_AD}`,
        method:'post',
        data,
        onStart:[campaignItemPerformTaskRequested.type],
        onSuccess,
        onError,
    }))
}

//-------------End of Export Action Creators-----------------------

//------------------Selectors-----------------------

export const getListOfCampaignsByStatus = (status="")=>createSelector(
    state=>state.entities.campaigns,
    campaigns=>campaigns.list
);

export const getCampaignCountsByStatus = status=>createSelector(
    state=>state.entities.campaigns,
    state=>state.auth,
    (campaigns,auth)=>{
        const {activeView}  = auth;
        if(activeView === "") return 0;

        const clientId = activeView.client.id;
        const clientAccountId = activeView.clientAccount.id;
    
        return (_.has(campaigns,`${clientId}.${clientAccountId}`))? campaigns[clientId][clientAccountId].campaignCounts[status]:null;
    }
)

export const getCampaign = (campaignId)=>createSelector(
  state=>state.entities.campaigns,
  state=>state.auth,
  (campaigns,auth)=>{
        const {activeView}  = auth;
        if(activeView === "") return null;

        const clientId = activeView.client.id;
        const clientAccountId = activeView.clientAccount.id;
    
        return (_.has(campaigns,`${clientId}.${clientAccountId}`))? campaigns[clientId][clientAccountId].byIds[campaignId]:null;
  }
);

export const getCampaignDetails = (campaignId)=>createSelector(
    state=>state.entities.campaigns,
    state=>state.auth,
    (campaigns,auth)=>{
        const {activeView}  = auth;
        if(activeView.client && activeView.clientAccount){
            const clientId = activeView.client.id;
            const clientAccountId = activeView.clientAccount.id;  
            return (_.has(campaigns,`${clientId}.${clientAccountId}.campaignDetailsByIds`))? 
                campaigns[clientId][clientAccountId].campaignDetailsByIds[campaignId]:null;
        }
        else
          return null;
      
    }
);

export const getCampaignDetailsRecommendations = createSelector(
    state=>state.entities.campaigns,
    state=>state.auth,
    (campaigns,auth)=>{
        const {activeView}  = auth;
        const clientId = activeView.client.id;
        const clientAccountId = activeView.clientAccount.id;  
        return (_.has(campaigns,`${clientId}.${clientAccountId}`))? campaigns[clientId][clientAccountId].recommendations:[]
    }
)

export const getCampaignItemTaskProcessingFlag = (campaignId)=>createSelector(
    state=>state.entities.campaigns,
    state=>state.auth,
    (campaigns,auth)=>{
        const {activeView}  = auth;
        const clientId = activeView.client.id;
        const clientAccountId = activeView.clientAccount.id;  
      return (_.has(campaigns,`${clientId}.${clientAccountId}`))?
        campaigns[clientId][clientAccountId].campaignItemTaskProcessingFlag:false;
    }
);

//This is to get campaigns based on the currenct select client/client account
export const getCampaignList = createSelector(
    state=>state.entities.campaigns,
    state=>state.auth,
    (campaigns,auth)=>{
        const {activeView}  = auth;
        const clientId = activeView.client.id;
        const clientAccountId = activeView.clientAccount.id;  
        return (_.has(campaigns,`${clientId}.${clientAccountId}`))? campaigns[clientId][clientAccountId]:{...emptyCampaignObject}
    }
)

//----------------End of Selectors-----------------------
