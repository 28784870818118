import React, { Fragment, Component } from 'react';
import moment from "moment";
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';

import { Button, Nav, NavItem, NavLink } from 'reactstrap';

import { connect } from 'react-redux';

class FooterRequestQuote extends Component {
  render() {
    let { footerShadow, footerFixed, footerBgTransparent,handleBackToDashboard } = this.props;

    return (
      <Fragment>
        <div
          className={cx('d-flex justify-content-between text-black-50', {
            'app-footer--shadow': footerShadow,
            'app-footer--opacity-bg': footerBgTransparent
          })}>
          <div className=" px-2 d-none d-sm-block">
            <Button color="link" className="m-2 pl-3  btn-link-dark" onClick={handleBackToDashboard}>
                <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fas', 'angle-left']} />
                </span>
                <span className="btn-wrapper--label">Back to Dashboard</span>
            </Button>
          </div>
          <div className=" px-2 d-block d-sm-none">
            <Button color="primary btn-block" className="m-2 pl-3  " onClick={handleBackToDashboard}>
                <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fas', 'angle-left']} />
                </span>
                <span className="btn-wrapper--label">Back to Dashboard</span>
            </Button>
          </div>
          <div className=" px-2 m-2 d-none">
            <Button 
                color="primary btn-block"
                className="" 
                onClick={()=>this.props.history.push(`/request-new-quote/step-1`) }
            >
                <span className="btn-wrapper--label">Next</span>
                <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fas', 'angle-right']} />
                </span>
            </Button>
          </div>
          
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  footerFixed: state.ThemeOptions.footerFixed,
  footerShadow: state.ThemeOptions.footerShadow,
  footerBgTransparent: state.ThemeOptions.footerBgTransparent
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FooterRequestQuote);
