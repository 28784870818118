import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

//Root reducer
import reducer from "./reducer";

//Middleware
import logger from "./middleware/logger";
import toast from "./middleware/toast";
import api from "./middleware/api";

export default function configureAppStore(){
   
    //returns the store object
    return configureStore({
         reducer,
         middleware:[
             ...getDefaultMiddleware(),
             logger('console'),
             toast,
             api,
         ]
    })
 }
 
