import { createSlice } from "@reduxjs/toolkit";
import {createSelector} from "reselect";
import _ from "lodash";


//-------Slice: Create reducers and actions--------
const slice  = createSlice({
    name:"loginScreenView",
    initialState:{
        data:{},
        errors:[],
    },
    reducers:{
        errorUICleared:(loginScreenView,action)=>{
            loginScreenView.errors = [];
        },
        errorUIDisplayed:(loginScreenView,action)=>{
            //console.log(action.payload);
            const {error} = action.payload;
            loginScreenView.errors = error.list;
        },
    }

});

//--------Export reducers and actions-----------------
export const {
    errorUICleared,
    errorUIDisplayed,
} = slice.actions;

export default slice.reducer;

//---------Action creators-------------------------------

export const clearErrors = ()=>dispatch=>{
    dispatch(errorUICleared())
}

//----------Get selectors------------------------------
export const getLoginErrors = createSelector(
    state=>state.ui.loginScreenView,
    loginScreenView=> loginScreenView.errors
)

