import React, { Fragment, Component } from 'react';
import {connect} from "react-redux"

import cx from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  Row,
  Col,
  Label,
  Badge,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Button,
  Modal,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';

import { Bell, Settings, Grid, Briefcase, LifeBuoy } from 'react-feather';


//Load campaign action creators
import { 
  getCampaignList,
  loadCampaigns, 
  setClientAndClientAccount,
} from "../../store/campaigns"

import avatar1 from '../../assets/images/avatars/avatar1.jpg';
import avatar2 from '../../assets/images/avatars/avatar2.jpg';
import avatar5 from '../../assets/images/avatars/avatar5.jpg';
import avatar6 from '../../assets/images/avatars/avatar6.jpg';
import avatar7 from '../../assets/images/avatars/avatar7.jpg';

import people3 from '../../assets/images/stock-photos/people-3.jpg';

import applicationConstants from "../../store/applicationConstants";


const {
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_TAB_ACTIVE,
} = applicationConstants;


class DashboardPageTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }

  incrementCounter = (e)=>{
    e.preventDefault();
    const {handleUpdateCounter} = this.props;
    handleUpdateCounter(
      {
        dashboardObject:{counter:3},
        campaignObject:{counter:2},
      }
    );
    return;
  }

  render() {
    let {
      pageTitleStyle,
      pageTitleBackground,
      pageTitleShadow,
      pageTitleIconBox,
      campaigns,
    } = this.props;

    const {
        byIds,
        campaignCounts,
        idListByStatus,
    } = campaigns;


    return (
      <Fragment>
        <Row>
          <Col sm="12" lg="4">
              <div
                className={cx('pb-0','app-page-title', pageTitleStyle, pageTitleBackground, {
                  'app-page-title--shadow': pageTitleShadow
                })}>
                <div>
                  <div className="app-page-title--first mb-0">
                    {pageTitleIconBox && (
                      <div className="app-page-title--iconbox d-70">
                        <div className="d-70 d-flex gradient-icon align-items-center justify-content-center">
                          <i className="pe-7s-umbrella display-3 gradient-icon bg-ripe-malin"></i>
                        </div>
                      </div>
                    )}
                    <div className="app-page-title--heading ">
                      {
                        this.props.titleHeading &&
                        <h1>{this.props.titleHeading}</h1>
                      }
                      {(
                        <div className="app-page-title--description">
                          <strong>{campaignCounts[CAMPAIGN_STATUS_ACTIVE]}</strong> Active Campaigns 
                          
                        </div>
                      )}
                      
                      

                    </div>
                  </div>
                </div>
              </div>
          </Col>
          <Col sm="12" lg="8" className="d-none">
            <div className="d-flex align-items-center justify-content-center justify-content-lg-end my-2">
              <Card className="card-box d-flex flex-row flex-wrap ml-1">
                <div className="py-2 px-2 d-flex align-items-center">
                    <Label className="mt-2 dashboard-status-display-pending-approval"></Label> <div className="mt-0 pl-2">Pending Approval</div>
                </div>
              </Card>
              <Card className="card-box d-flex flex-row flex-wrap ml-1">
                <div className="py-2 px-2 d-flex align-items-center">
                    <Label className="mt-2 dashboard-status-display-review-by-je"></Label> <div className="mt-0 pl-2">Review by JE</div>
                </div>
              </Card>
              <Card className="card-box d-flex flex-row flex-wrap ml-1">
                <div className="py-2 px-2 d-flex align-items-center">
                    <Label className="mt-2 dashboard-status-display-published"></Label> <div className="mt-0 pl-2">Live/ Published</div>
                </div>
              </Card>
              <Card className="card-box d-flex flex-row flex-wrap ml-1">
                <div className="py-2 px-2 d-flex align-items-center">
                    <Label className="mt-2 dashboard-status-display-expired"></Label> <div className="mt-0 pl-2">Expired/ Canceled</div>
                </div>
              </Card>
              <Card className="card-box d-flex flex-row flex-wrap ml-1">
                <div className="py-2 px-2 d-flex align-items-center">
                    <Label className="mt-2 dashboard-status-display-processing"></Label> <div className="mt-0 pl-2">Processing</div>
                </div>
              </Card>                  
            </div>
          </Col>
        </Row>
        
        

      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleBreadcrumb: state.ThemeOptions.pageTitleBreadcrumb,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription,
  campaigns:getCampaignList(state),

});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPageTitle);
