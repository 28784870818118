import React, {Fragment, useState, Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Row, Table, Col, CardBody, Card, CardTitle, CardHeader, Collapse, UncontrolledPopover, Container, InputGroup, InputGroupAddon, InputGroupText, FormText, Form, CustomInput, Label, FormGroup, Input, Badge, ListGroup, ListGroupItem, PopoverHeader, PopoverBody, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, Progress, DropdownToggle, DropdownMenu, Alert, DropdownItem } from 'reactstrap'

import {connect} from 'react-redux';

import {
    setSidebarToggleMobile
} from '../../reducers/ThemeOptions';

import {
    getActiveClientAccount,
} from '../../store/auth'

import { 
    setKeywordSearchField,
} from "../../store/ui-dashboard";

import {
    loadCampaignsByKeywordForDashboard,
} from "../../store/campaigns"

import BaseForm from "../../components/BaseClass/Form"


import HeaderDots from "../../layout-components/HeaderDots";
import HeaderDrawer from "../../layout-components/HeaderDrawer";
import HeaderUserbox from "../../layout-components/HeaderUserbox";
import HeaderSearch from "../../layout-components/HeaderSearch";
import HeaderMenu from "../../layout-components/HeaderMenu";

import avatar1 from '../../assets/images/avatars/avatar1.jpg';
import avatar2 from '../../assets/images/avatars/avatar2.jpg';
import avatar6 from '../../assets/images/avatars/avatar6.jpg';
import avatar7 from '../../assets/images/avatars/avatar7.jpg';
import people1 from '../../assets/images/stock-photos/people-1.jpg';
import people3 from '../../assets/images/stock-photos/people-3.jpg';

import { Grid, Users, Bell } from 'react-feather';
import Chart from 'react-apexcharts';


class HeaderDashboard extends BaseForm {
    divAreaToScrollForError = "regularFormArea"

    validationSchema = ""
    
    
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
    
        this.state = {
            activeTab: '1',
            data:{
                searchCampaign:"",
            },
            errors:{} 
        };
    }

    componentDidMount(){
        const { keyword:searchCampaign,} = this.props;
        this.setState({
            data:{
                ...this.state.data,
                searchCampaign,
            }
        })

    }
    
    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    toggleSidebarMobile = () => {
        let {sidebarToggleMobile, setSidebarToggleMobile} = this.props;
        setSidebarToggleMobile(!sidebarToggleMobile);
    }

    submitLocal=()=>{
        const { keyword,searchCampaignsByKeyword, setKeywordInputField} = this.props;

        const {data} = this.state;
        const {searchCampaign} = data;
        
        if(keyword !== searchCampaign){
            setKeywordInputField(searchCampaign);
            searchCampaignsByKeyword();
        }
    }

    validateLocal = ()=>{

    }

    render() {
        let {
            clientAccountName,
            headerShadow,
            headerBgTransparent,
            sidebarToggleMobile,
            useHistory:history,
        } = this.props;

        const {data,errors} = this.state;
        const {searchCampaign} = data;
        

        return (
            <Fragment>
                <div className={cx("app-header", {'app-header--shadow': headerShadow, 'app-header--opacity-bg': headerBgTransparent})}>
                <div className="app-header--pane">
                    
                    <button className={cx("navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn", {'is-active': sidebarToggleMobile})} onClick={this.toggleSidebarMobile}>
                        <span className="hamburger-box">
                            <span className="hamburger-inner"/>
                        </span>
                    </button>
                    <div className="je-header-page-title">
                        <div className="je-header-dashboard-page-description">
                        {clientAccountName}
                        </div>
                        <h2 className="je-header-dashboard-page-title">Dashboard</h2>
                    </div>
                    
                </div>
                <div className="app-header--pane">
                    {/* Search field */}
                    <div className="d-none d-md-block">
                        <Form
                            onSubmit={this.submitForm}
                        >
                            <InputGroup >
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={['fas', 'search']} />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input 
                                    type="text"
                                    name="searchCampaign"
                                    id="searchCampaign"
                                    placeholder="Campaign Title or Job #..."
                                    value={searchCampaign}
                                    onChange={this.handleChange}
                                    onBlur={this.submitForm}
                                />
                            </InputGroup>
                        </Form>
                    </div>
                    {/*End of Search field */}

                    {/*Request New */}
                    <div className="d-none d-lg-block">
                        <UncontrolledDropdown className="mx-3">
                            <DropdownToggle color="primary" caret>
                                <span className="btn-wrapper--label pr-2">Request</span>
                            </DropdownToggle>
                            <DropdownMenu right className="dropdown-menu-xl p-0">
                                <Nav className=" flex-column py-2">
                                    <NavItem>
                                        <NavLink className="text-black-50" href="#" onClick={e =>{ 
                                            e.preventDefault();
                                            history.push("/request-new-quote");
                                        }}>
                                        <div className="nav-link-icon">
                                            <i className="sidebar-icon pe-7s-safe"></i>
                                        </div>
                                        <span>New Job Campaign Quote</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="d-none">
                                        <NavLink
                                        className="text-black-50"
                                        href="#"
                                        onClick={e => e.preventDefault()}
                                        >
                                        <div className="nav-link-icon">
                                            <FontAwesomeIcon
                                            icon={['far', 'question-circle']}
                                            />
                                        </div>
                                        <span>Search Tearsheets</span>
                                        </NavLink>
                                    </NavItem>
                                
                                </Nav>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    
                    {/* End of Request New */}


                                       
                </div>
            </div>
            </Fragment>
    );
    }
}

const mapStateToProps = state => ({
    keyword:state.ui.dashboard.query.keyword,
    headerShadow: state.ThemeOptions.headerShadow,
    headerBgTransparent: state.ThemeOptions.headerBgTransparent,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
    clientAccountName:getActiveClientAccount(state).name || "",
});

const mapDispatchToProps = dispatch => ({
    searchCampaignsByKeyword:()=>dispatch(loadCampaignsByKeywordForDashboard()),
    setKeywordInputField:(keyword)=>dispatch(setKeywordSearchField(keyword)),
    setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),

});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDashboard);
