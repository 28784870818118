import {combineReducers} from "redux";
import entitiesReducer from "./entities";
import uiReducer from "./ui";
import authReducer from "./auth";
import globalPropertiesReducer from "./globalProperties";

import themeOptionsReducer from "../reducers";

export default combineReducers({
    auth:authReducer,
    entities:entitiesReducer,
    globalProperties:globalPropertiesReducer,
    ui:uiReducer,
    ...themeOptionsReducer,
})