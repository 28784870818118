import { createSlice } from "@reduxjs/toolkit";
import {createSelector} from "reselect";
import _ from "lodash";
import { toast, Flip } from 'react-toastify';

import {scrollTop} from '../utils/helperUtils';


const toastNotificationMessages = {
    newQuoteCreated:{
        message:"Quote has been saved to draft and can be accessed under My Campaigns.",
        position:{containerId: 'B', transition: Flip},
    },
}

const divErrorArea = "errorArea";

//-------Slice: Create reducers and actions--------
const slice  = createSlice({
    name:"requestQuoteView",
    initialState:{
        requestQuoteType:"",
        notifyUserOfSavedToDraft:false, //This will be used for the first step modal display
        proceedToNextStep:false,
        processingIndicator:false,
        invalidQuote:false,             //If database retrieves and incorrect quote
        data:{},
        errors:[],  //server errors
    },
    reducers:{
        /*set the request quote type */
        requestQuoteTypeSaved:(requestQuoteView,action)=>{
            const {viewType} = action.payload
            requestQuoteView.requestQuoteType = viewType
        },
        uiNewQuoteCreatedInProgress:(requestQuoteView,action)=>{
            requestQuoteView.notifyUserOfSavedToDraft = false
            requestQuoteView.processingIndicator = true
        },
        uiQuoteCreatedToCleared:(requestQuoteView,action)=>{
            requestQuoteView.notifyUserOfSavedToDraft = false
            requestQuoteView.processingIndicator = false
        },
        uiNewQuoteCreatedComplete:(requestQuoteView,action)=>{
            //requestQuoteView.notifyUserOfSavedToDraft = true
            const {message,position} = toastNotificationMessages.newQuoteCreated
            toast.success(message,position);
            requestQuoteView.proceedToNextStep = true;
            requestQuoteView.processingIndicator = false
        },
        uiNewQuoteCreatedtOnError:(requestQuoteView,action)=>{
            requestQuoteView.processingIndicator = false
        },
        uiInvalidQuoteReceived:(requestQuoteView,action)=>{
            requestQuoteView.invalidQuote = true
        },
        uiServerErrorReceived:(requestQuoteView,action)=>{
            requestQuoteView.processingIndicator = false
            const {error} = action.payload;
            requestQuoteView.errors = error.list;
            requestQuoteView.processingIndicator = false;
            scrollTop(divErrorArea);
        },
        processingIndicatorFlagShow:(requestQuoteView,action)=>{
            requestQuoteView.processingIndicator = true
        },
        processingIndicatorFlagHide:(requestQuoteView,action)=>{
            requestQuoteView.processingIndicator = false
        },
        proceedToNextStep:(requestQuoteView,action)=>{
            requestQuoteView.processingIndicator = false;
            requestQuoteView.proceedToNextStep = true
        },
        proceedToNextStepReset:(requestQuoteView,action)=>{
            requestQuoteView.proceedToNextStep = false
        },
        errorUICleared:(requestQuoteView,action)=>{
            requestQuoteView.errors = [];
            requestQuoteView.invalidQuote = false
        },
        errorUIDisplayed:(requestQuoteView,action)=>{
            const {error} = action.payload;
            requestQuoteView.errors = error.list;
        },
    }

});

//--------Export reducers and actions-----------------
export const {
    requestQuoteTypeSaved,
    uiQuoteCreatedToCleared,
    uiNewQuoteCreatedComplete,
    uiNewQuoteCreatedtOnError,
    uiNewQuoteCreatedInProgress,
    uiInvalidQuoteReceived,
    uiServerErrorReceived,
    processingIndicatorFlagShow,
    processingIndicatorFlagHide,
    proceedToNextStep,
    proceedToNextStepReset,
    errorUICleared,
    errorUIDisplayed,
} = slice.actions;

export default slice.reducer;

//---------Action creators-------------------------------
export const setProcessingIndicator = (flag)=>dispatch=>{
    if(flag === true)
        dispatch(processingIndicatorFlagShow())
    else
        dispatch(processingIndicatorFlagHide())
        
}

export const setRequestTypeView = (viewType)=>dispatch=>{
    dispatch(requestQuoteTypeSaved({viewType}))
}

export const clearErrors = ()=>dispatch=>{
    dispatch(errorUICleared())
}

export const clearProceedToNextStep = ()=>dispatch=>{
    dispatch(proceedToNextStepReset())
}

export const clearQuoteCreatedNotificationFlags = ()=>dispatch=>{
    dispatch(uiQuoteCreatedToCleared())
}

//----------Get selectors------------------------------
export const displayProcessingIndicator = createSelector(
    state=>state.ui.requestQuoteView,
    requestQuoteView=> requestQuoteView.processingIndicator
)

export const displayQuoteCreated = createSelector(
    state=>state.ui.requestQuoteView,
    requestQuoteView=> requestQuoteView.notifyUserOfSavedToDraft
)

export const getRequestQuoteType = createSelector(
    state=>state.ui.requestQuoteView,
    requestQuoteView=> requestQuoteView.requestQuoteType
)

export const getServerErrors = createSelector(
    state=>state.ui.requestQuoteView,
    requestQuoteView=> requestQuoteView.errors
)

export const isQuoteInvalid = createSelector(
    state=>state.ui.requestQuoteView,
    requestQuoteView=> requestQuoteView.invalidQuote
) 

export const proceedToNextStepOnFormSubmission = createSelector(
    state=>state.ui.requestQuoteView,
    requestQuoteView=> requestQuoteView.proceedToNextStep
)

