import { createSlice } from "@reduxjs/toolkit";
import {createSelector} from "reselect";
import _ from "lodash";
import moment from "moment";
import { toast, Flip } from 'react-toastify';


import { apiCallBegan } from "./api"

import applicationContants from "./applicationConstants";

import {
    userPersonalProfileSaved
} from "./auth";

import {
    addUserUIOnError,
    addUserUISaveInProgress,
    addUserUISaved,
    editUserUIOnError,
    editUserUISaveInProgress,
    editUserUISaved,
    loadingIndicatorUIStart,
    loadingIndicatorUIEnd,
    userListUILoadingInProgress,
    userListUIReceived,
    userListUIOnError,
} from "./ui-manage-account"

const {
    ENDPOINT_FOR_CLIENT_SLICE,
    ENDPOINT_FOR_USER_SLICE,
    USER_ACTIVE,
    USER_INACTIVE,

    REFETCH_THRESHOLD_IN_MINUTES,
} = applicationContants

const toastNotificationMessages = {
    userAddedSuccess:{
        message:"An email has been sent to the user about their access information.",
        position:{containerId: 'B', transition: Flip},
    },
    userEditedSuccess:{
        message:"User information has been saved successfully.",
        position:{containerId: 'B', transition: Flip},
    },
    userActivatedSuccess:{
        message:"The user has been activated and can access the website",
        position:{containerId: 'B', transition: Flip},
    },
    userDeactivatedSuccess:{
        message:"The user has been deactivated and cannot access the website",
        position:{containerId: 'B', transition: Flip},
    },
}
/* 
   This slice will have all the necessary data related to the client. 
   Currently to begin we start saving list of users related to client for manage users 

   {
     <<ClientID>>:{
         client:"",
         clientAccounts:[],
         userById:{},
         userIdList:[],
         notificationsByClientAccount:{<<CLIENT_ACCOUNT_ID>>:[],...,},
         notificationLastFetch:
    }   

   }
*/

const initializationObject = {
    client:"",
    clientAccounts:[],
    userById:{},
    userIdList:[],
    notificationsByClientAccount:{},
}

//Slice: Create reducers and actions
const slice = createSlice({
    name:"clients",
    initialState:{},
    reducers:{
        clientDetailsReceived:(clients,action)=>{
            //console.log(action.payload);
            const {clientId,client} = action.payload;
            
            const clientDetail = _.omit(client, ['clientAccounts']);

            //Initialize if client id does not exist
            if(clients.hasOwnProperty(clientId) === false){
               clients[clientId] = {...initializationObject};
            }
            clients[clientId].client = clientDetail;
        },
        clientAccountsPerClientReceived:(clients,action)=>{
            //console.log(action.payload);
            const {clientId,client} = action.payload;
            const {
                clientAccounts
            } = client;

            const clientDetail = _.omit(client, ['clientAccounts']);

            //Initialize if client id does not exist
            if(clients.hasOwnProperty(clientId) === false){
               clients[clientId] = {...initializationObject};
            }
            clients[clientId].client = clientDetail;
            clients[clientId].clientAccounts = clientAccounts
        },
        
        notificationsByClientAndClientAccountFetchTimestampCleared:(clients,action)=>{
            const {clientId,clientAccountId} = action.payload;
            
            if(clients.hasOwnProperty(clientId) === false){
                return
            }

            if(clients[clientId] && clients[clientId].notificationsByClientAccount && clients[clientId].notificationsByClientAccount[clientAccountId])
                clients[clientId].notificationsByClientAccount[clientAccountId].lastFetch = null;
        },

        notificationsByClientAndClientAccountRemoved:(clients,action)=>{
            const {clientId,clientAccountId,notificationId} = action.payload;
            //console.log(action.payload);
            
            const notificationList = clients[clientId].notificationsByClientAccount[clientAccountId].notificationList
            const index = notificationList.findIndex(n=>n.id === notificationId)
            notificationList.splice(index,1);
        },

        notificationsByClientAndClientAccountReceived:(clients,action)=>{
            const {clientId,clientAccountId,notificationList} = action.payload;
            //console.log(action.payload);
            
            if(clients.hasOwnProperty(clientId) === false){
                const initObject = {...initializationObject}
                initObject.notificationsByClientAccount = {
                    [clientAccountId]:{
                        lastFetch:Date.now(),
                        notificationList,
                    }
                }
                clients[clientId] = initObject
            }
            else{
                clients[clientId].notificationsByClientAccount[clientAccountId] = {
                    lastFetch:Date.now(),
                    notificationList,
                }
            }
            /*
            if(clientId && clientAccountId && clients[clientId].notificationsByClientAccount){
                
                try{
                    clients[clientId].notificationsByClientAccount[clientAccountId] = {
                        lastFetch:Date.now(),
                        notificationList,
                    }
                }
                catch(ex){
                    console.log(ex);
                    console.log("notificationsByClientAndClientAccountReceived error");
                    console.log(clients[clientId]);
                
                }
                
                
            }
            */
            
             
        },
        userListPerClientReceived:(clients,action)=>{
            //console.log(action.payload);
            const {clientId,users} = action.payload;

            //Initialize if client id does not exist
            if(clients.hasOwnProperty(clientId) === false){
               clients[clientId] = {...initializationObject}
            }
            users.forEach(u=>{
                const {id} = u;
                clients[clientId].userById[id] = u;
                
                const index = clients[clientId].userIdList.findIndex(e=>e === id)
                if(index === -1)  clients[clientId].userIdList.push(id)
            })
        },
        userActivated:(clients,action)=>{
            //This is called from manage my account screen user list
            const {clientId,userId} = action.payload;
            clients[clientId].userById[userId].confirmed = USER_ACTIVE;
            const {message,position} = toastNotificationMessages.userActivatedSuccess;
            toast.success(message,position);
        },
        

        userAdded:(clients,action)=>{
            //This is called from manage my account screen user edit list
            const {clientId,userForClientViewUpdate:user} = action.payload;
            const {id:userId} = user;
            clients[clientId].userById[userId] = user;
            const {message,position} = toastNotificationMessages.userAddedSuccess;
            toast.success(message,position);
        },

        userDeactivated:(clients,action)=>{
            //This is called from manage my account screen user list
            const {clientId,userId} = action.payload;
            clients[clientId].userById[userId].confirmed = USER_INACTIVE;
            const {message,position} = toastNotificationMessages.userDeactivatedSuccess;
            toast.success(message,position);
        },

        userEdited:(clients,action)=>{
            //This is called from manage my account screen user edit list
            const {clientId,userForClientViewUpdate:user} = action.payload;
            const {id:userId} = user;
            clients[clientId].userById[userId] = user;
            const {message,position} = toastNotificationMessages.userEditedSuccess;
            toast.success(message,position);
        },
        



    }
})

//---------------Actions & Reducer export setup----------------------------
export const {
    clientDetailsReceived,
    clientAccountsPerClientReceived,
    notificationsByClientAndClientAccountFetchTimestampCleared,
    notificationsByClientAndClientAccountRemoved,
    notificationsByClientAndClientAccountReceived,
    userActivated,
    userAdded,
    userDeactivated,
    userEdited,
    userListPerClientReceived,
}  = slice.actions;

//Export reducer
export default slice.reducer;
//---------------End of Actions & Reducer export setup----------------------------

//-------------Export Action Creators-----------------------
const url = ENDPOINT_FOR_USER_SLICE;
const urlClient = ENDPOINT_FOR_CLIENT_SLICE;
const ROUTE_GET_LIST_OF_USERS_BY_CLIENT = "getUserListForClient";
const ROUTE_GET_CLIENT_BY_ID = "getClientById";
const ROUTE_GET_NOTIFICATIONS_BY_CLIENT_AND_CLIENTACCOUNT = "getNotificationsByClientAndClientAccount";

const ROUTE_SAVE_EXISTING_USER = "saveExistingUserInformation";
const ROUTE_SAVE_NEW_USER = "saveNewUserInformation";
const ROUTE_ACTIVATE_USER = "activateUser";
const ROUTE_DEACTIVATE_USER = "deactivateUser";

const ROUTE_DELETE_NOTIFICATION = "removeNotification";


export const activateUser = (userId)=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    
    const formData = {
        activeView,
        userId,
        clientId,
        clientAccountId:null,
    }

    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_ACTIVATE_USER}`,
        method:'post',
        data:formData,
        onStart:[loadingIndicatorUIStart.type],
        onSuccess:[userActivated.type,loadingIndicatorUIEnd.type],
        onError:[userListUIOnError.type],
    }))
    
}

export const clearNotificationsFetchTimestamp = ()=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clients  = getState().entities.clients;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
   
    //If notifications already exists then no need to query
    if(clients[clientId] && 
        clients[clientId].notificationsByClientAccount && 
        clients[clientId].notificationsByClientAccount[clientAccountId] &&
        typeof clients[clientId].notificationsByClientAccount[clientAccountId] === "object"   
    ){
        dispatch(notificationsByClientAndClientAccountFetchTimestampCleared({clientId,clientAccountId}))              
    }
}

export const deleteNotificationItem = (notificationId)=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clients  = getState().entities.clients;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
   

    const formData = {
        activeView,
        clientId,
        clientAccountId,
        notificationId
    }    

    dispatch(apiCallBegan({
        url:`${urlClient}/${ROUTE_DELETE_NOTIFICATION}`,
        method:'delete',
        data:formData,
        onStart:[],
        onSuccess:[notificationsByClientAndClientAccountRemoved.type],
        onError:[],
    }))

    
}

export const deactivateUser = (userId)=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    
    const formData = {
        activeView,
        userId,
        clientId,
        clientAccountId:null,
    }    

    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_DEACTIVATE_USER}`,
        method:'post',
        data:formData,
        onStart:[loadingIndicatorUIStart.type],
        onSuccess:[userDeactivated.type,loadingIndicatorUIEnd.type],
        onError:[userListUIOnError.type],
    }))
    
}

export const loadClientDetails = ()=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clients  = getState().entities.clients;
    const clientId = activeView.client.id;

    //If user already exists then no need to query
    if(clients[clientId] && clients[clientId].client && clients[clientId].client !== "") return;

    const query = {
        clientId,
        activeView,
        clientAccountId:null,
    };
    
    
    //Basically getting the client information and it's primary association client account
    dispatch(apiCallBegan({
        url:`${urlClient}/${ROUTE_GET_CLIENT_BY_ID}`,
        method:'post',
        data:query,
        onStart:[],
        onSuccess:[clientDetailsReceived.type],
        onError:[],
    }))
}

export const loadClientDetailsByClientId = (clientId)=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clients  = getState().entities.clients;
    const clientId = activeView.client.id;

    const query = {
        clientId,
        activeView,
        clientAccountId:null,
    };
    
    
    //Basically getting the client information and it's primary association client account
    dispatch(apiCallBegan({
        url:`${urlClient}/${ROUTE_GET_CLIENT_BY_ID}`,
        method:'post',
        data:query,
        onStart:[],
        onSuccess:[clientDetailsReceived.type],
        onError:[],
    }))
}

export const loadUserById = (id)=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clients  = getState().entities.clients;
    const clientId = activeView.client.id;
    
    //If user already exists then no need to query
    if(clients[clientId] && clients[clientId].userById && clients[clientId].userById[id]) return;

    const query = {id:parseInt(id)};
    query.activeView = activeView;
    query.clientId = clientId;
    query.clientAccountId= null;
    
    dispatch(apiCallBegan({
        url:`${url}/`,
        method:'post',
        data:query,
        onStart:[loadingIndicatorUIStart.type],
        onSuccess:[userListPerClientReceived.type,loadingIndicatorUIEnd.type],
        onError:[userListUIOnError.type],
    }))
}

export const loadUserListForClient = ()=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    
    const manageAccountView = getState().ui.manageAccountView;
    const query = {...manageAccountView.users.query};
    query.activeView = activeView;
    query.clientId = clientId;
    query.clientAccountId= null;
    
    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_GET_LIST_OF_USERS_BY_CLIENT}`,
        method:'post',
        data:query,
        onStart:[userListUILoadingInProgress.type],
        onSuccess:[userListPerClientReceived.type,userListUIReceived.type],
        onError:[userListUIOnError.type],
    }))
}

export const loadClientAccountsForClient = ()=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clients  = getState().entities.clients;
    const clientId = activeView.client.id;

    //If user already exists then no need to query
    if(clients[clientId] && clients[clientId].clientAccounts && clients[clientId].clientAccounts.length) return;

    const query = {
        clientId,
        activeView,
        clientAccountId:null,
    };
    
    
    //Basically getting the client information and it's primary association client account
    dispatch(apiCallBegan({
        url:`${urlClient}/${ROUTE_GET_CLIENT_BY_ID}`,
        method:'post',
        data:query,
        onStart:[loadingIndicatorUIStart.type],
        onSuccess:[clientAccountsPerClientReceived.type,loadingIndicatorUIEnd.type],
        onError:[loadingIndicatorUIEnd.type],
    }))
}

//Query the database for that specific client and client account only once
export const loadNotificationsByClientAndClientAccount = ()=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clients  = getState().entities.clients;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
   
    //If notifications already exists then no need to query
    if(clients[clientId] && 
        clients[clientId].notificationsByClientAccount && 
        clients[clientId].notificationsByClientAccount[clientAccountId] &&
        typeof clients[clientId].notificationsByClientAccount[clientAccountId] === "object"   
    ){
        const {lastFetch} = clients[clientId].notificationsByClientAccount[clientAccountId]
        if(lastFetch !== null){
            const diffInMinutes = moment().diff(moment(lastFetch),'minutes');
            if(diffInMinutes < REFETCH_THRESHOLD_IN_MINUTES) return;
        }        
    }
    

    const query = {
        clientId,
        activeView,
        clientAccountId,
    };
    
    
    //Basically getting the client information and it's primary association client account
    dispatch(apiCallBegan({
        url:`${urlClient}/${ROUTE_GET_NOTIFICATIONS_BY_CLIENT_AND_CLIENTACCOUNT}`,
        method:'post',
        data:query,
        onStart:[],
        onSuccess:[notificationsByClientAndClientAccountReceived.type],
        onError:[],
    }))
}

export const saveExistingUserInformation = (formData)=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clients  = getState().entities.clients;
    const clientId = activeView.client.id;

    const message = "Your users information has been updated";
    const data = {
        activeView,
        ...formData,
        clientId,
        clientAccountId:null,
        successNotification:{message},
    };

    /*
    auth.userPersonalProfileSaved action gets executed when logged in user is updating from list view 
    as we need to refresh the screen to regenerate a new token
    */
    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_SAVE_EXISTING_USER}`,
        method:'post',
        data,
        onStart:[editUserUISaveInProgress.type],
        onSuccess:[userEdited.type,editUserUISaved.type,userPersonalProfileSaved.type],
        onError:[editUserUIOnError.type],
    }))
    
}

export const saveNewUserInformation = (formData)=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clients  = getState().entities.clients;
    const clientId = activeView.client.id;

    const message = "Your users information has been updated";
    const data = {
        activeView,
        ...formData,
        clientId,
        clientAccountId:null,
        successNotification:{message},
    };

    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_SAVE_NEW_USER}`,
        method:'post',
        data,
        onStart:[addUserUISaveInProgress.type],
        onSuccess:[userAdded.type,addUserUISaved.type],
        onError:[addUserUIOnError.type],
    }))
    
}

//-------------End of Export Action Creators-----------------------

//------------------Selectors-----------------------
export const getActiveClientDetail = createSelector(
    state=>state.entities.clients,
    state=>state.auth,
    (clients,auth)=>{
        const {activeView}  = auth;
        try{
            const clientId = activeView.client.id;
            
            if(clientId && clients[clientId] && clients[clientId].client !== "")
            return clients[clientId].client
            else
            return null;
            }
        catch(ex){
            return null;
        }   
       
    }
)

export const getClientAccountListForActiveClient = createSelector(
    state=>state.entities.clients,
    state=>state.auth,
    (clients,auth)=>{
        const {activeView}  = auth;
        const clientId = activeView.client.id;
        
        if(clientId && clients[clientId])
           return clients[clientId].clientAccounts
        else
           return []
       
    }
)

export const getNotificationsByClientAndClientAccount = createSelector(
    state=>state.entities.clients,
    state=>state.auth,
    (clients,auth)=>{
        try{
            const {activeView}  = auth;
            const clientId = activeView.client.id;
            const clientAccountId = activeView.clientAccount.id;
            
            if(clientId && clientAccountId && clients[clientId] && 
                clients[clientId].notificationsByClientAccount && 
                clients[clientId].notificationsByClientAccount[clientAccountId]
            ){
                const {lastFetch,notificationList} = clients[clientId].notificationsByClientAccount[clientAccountId]
                return notificationList
            }else
            return []
        }
        catch(ex){
            return []
        }
       
    }
)


export const getUserDetailById = (id)=>createSelector(
    state=>state.entities.clients,
    state=>state.auth,
    (clients,auth)=>{
        //console.log(id);
        const {activeView}  = auth;
        const clientId = activeView.client.id;
        
        if(clientId && clients[clientId]){
            return clients[clientId].userById[id]
        }else
           return null
       
    }
)


export const getUserListForActiveClient = createSelector(
    state=>state.entities.clients,
    state=>state.auth,
    (clients,auth)=>{
        const {activeView}  = auth;
        const clientId = activeView.client.id;
        
        if(clientId && clients[clientId])
           return clients[clientId].userById
        else
           return []
       
    }
)




//------------------End of Selectors-----------------------
