import { createSlice } from "@reduxjs/toolkit";
import {createSelector} from "reselect";
import _ from "lodash";
import moment from "moment";
import { toast, Flip } from 'react-toastify';
import axios from "axios"
import {saveAs} from "file-saver"



import applicationContants from "./applicationConstants";

import { apiCallBegan } from "./api"

import {
    paymentProcessedErrorUICleared,
    paymentProcessedOnError,
    paymentProcessedOnSuccess,
    setPaymentModalDisplay,
} from "./ui-invoices";

import {
    notificationsByClientAndClientAccountFetchTimestampCleared
} from "./clients"


const {
    INVOICE_STATUS_OPEN,
    INVOICE_STATUS_CLOSED,
    
    INVOICE_TAB_OPEN,
    INVOICE_TAB_CLOSED,
    INVOICE_ALL,

    ENDPOINT_FOR_INVOICE_SLICE,
    REFETCH_THRESHOLD_IN_MINUTES,

    getInvoiceStatusByTab,
} = applicationContants

const listOfTabsToProcess = [
    INVOICE_TAB_OPEN,
    INVOICE_TAB_CLOSED,
]

const listOfStatusToProcess = [
    INVOICE_STATUS_OPEN,
    INVOICE_STATUS_CLOSED,
]

const toastNotificationMessages = {
    invoiceDownloadedError:{
        message:"We encountered an error downloading your invoice",
        position:{containerId: 'B', transition: Flip},
    },
    
}

/*
We are using the tabs as the organization key compared to status where it's numeric in the database
*/
const emptyInvoiceObject = {
    byIds:{},
    allIds:[],
    invoiceBalance:0,
    openInvoiceCount:0,
    invoiceCounts:{
        [INVOICE_TAB_OPEN]:0,
        [INVOICE_TAB_CLOSED]:0,
        
    },
    idListByStatus:{
        [INVOICE_TAB_OPEN]:[],
        [INVOICE_TAB_CLOSED]:[],
        
    },
    loading:false,
    lastFetch:null,
    invoiceItemTaskProcessingFlag:false, //This is to load a processing indicator
    paymentTaskProcessingFlag:false, //This is to load a processing indicator
    
    //invoiceDetailsByIds:{},
    paymentProcessing:{
        invoiceIdList:[]
    }
};


const slice = createSlice({
    name:"invoices",
    initialState:{},
    reducers:{
        clientAndClientAccountAdded:(invoices,action)=>{
            const {clientId,clientAccountId} = action.payload;
            if(!_.has(invoices, `${clientId}.${clientAccountId}`)){
                invoices[clientId] = {};
                invoices[clientId][clientAccountId] = {...emptyInvoiceObject}
            }
        },

        invoicesForPaymentCleared:(invoices,action)=>{
            const {clientId,clientAccountId} = action.payload;
            invoices[clientId][clientAccountId].paymentProcessing.invoiceIdList = [];
        },

        invoicesForPaymentSelected:(invoices,action)=>{
            const {clientId,clientAccountId,invoicesIdListForPayment} = action.payload;
            invoices[clientId][clientAccountId].paymentProcessing.invoiceIdList = invoicesIdListForPayment;
        },

        invoicesPaid:(invoices,action)=>{
            //console.log(action);
            const {clientId,clientAccountId,invoices:selectedInvoices,invoiceBalance,openInvoiceCount} = action.payload; 
            const invoiceForClient = invoices[clientId][clientAccountId];
            invoiceForClient.paymentTaskProcessingFlag=true;
            
            selectedInvoices.forEach(selectedInvoice=>{
                const {id:invoiceId,balance,paid} = selectedInvoice ;
                const invoiceRecord =  invoiceForClient.byIds[invoiceId];
                invoiceRecord.paid = balance + paid;
                invoiceRecord.balance = 0;
                invoiceRecord.status = INVOICE_STATUS_CLOSED;

                const index = invoiceForClient.idListByStatus[INVOICE_TAB_OPEN].indexOf(invoiceId);
                invoiceForClient.idListByStatus[INVOICE_TAB_OPEN].splice(index,1);

                invoiceForClient.idListByStatus[INVOICE_TAB_CLOSED].push(invoiceId);
            })
            
            const totalInvoiceProcessed = selectedInvoices.length;
            const totalOpenCount = invoiceForClient.invoiceCounts[INVOICE_TAB_OPEN];
            const totalCloseCount = invoiceForClient.invoiceCounts[INVOICE_TAB_CLOSED]
            invoiceForClient.invoiceCounts[INVOICE_TAB_OPEN] = totalOpenCount - totalInvoiceProcessed;
            invoiceForClient.invoiceCounts[INVOICE_TAB_CLOSED] = totalCloseCount + totalInvoiceProcessed;
            
            invoiceForClient.invoiceBalance = invoiceBalance;
            invoiceForClient.openInvoiceCount = openInvoiceCount;
            
            invoiceForClient.paymentTaskProcessingFlag = false;
        },

        invoicePdfGenerated:(invoices,action)=>{
            //console.log(action);
            //const {clientId,clientAccountId} = action.payload; 
            //invoices[clientId][clientAccountId].loading = false;

            const blobData = action.payload;
            const pdfBlob = new Blob([blobData], {type : 'application/pdf'})
            saveAs(pdfBlob,"Invoice.pdf"); 

        },

        invoicesReceivedForAllStatus:(invoices,action)=>{
            //This will have a data-structure for each status in the payload for 1 page
            const {clientId,clientAccountId} = action.payload; 
            const invoicesObject = invoices[clientId][clientAccountId];
             
            listOfTabsToProcess.forEach(tabView=>{
                 const {data,totalCount,status} = action.payload[tabView];
                 invoicesObject.invoiceCounts[tabView] = totalCount;
                 if(!_.isEmpty(data)){
                     const idList = _.map(data,'id');
                     invoicesObject.idListByStatus[tabView] = idList;
                     
                     data.forEach(invoiceRecord=>{
                        const {id} = invoiceRecord;
                        if(!invoicesObject.allIds.includes(id)) 
                            invoicesObject.allIds.push(id);
                         
                        invoicesObject.byIds[id] = invoiceRecord;
                     })
                 }
                 else{
                    invoicesObject.idListByStatus[tabView] = [];
                 }
             });
 
             invoicesObject.loading=false;
             invoicesObject.lastFetch = Date.now();
        },

        invoicesReceivedForIndividualStatus:(invoices,action)=>{
            //This will be for individual status for 1 page
            const {clientId,clientAccountId} = action.payload; 
            const {view:tabView} = action.payload;
            const {data,totalCount} = action.payload[tabView];
            invoices[clientId][clientAccountId].invoiceCounts[tabView] = totalCount;
            if(!_.isEmpty(data)){
                const idList = _.map(data,'id');
                invoices[clientId][clientAccountId].idListByStatus[tabView] = idList;
                
                data.forEach(invoiceRecord=>{
                    const {id} = invoiceRecord;
                    if(!invoices[clientId][clientAccountId].allIds.includes(id)) 
                      invoices[clientId][clientAccountId].allIds.push(id);
                    
                    invoices[clientId][clientAccountId].byIds[id] = invoiceRecord;
                })
            }
            invoices[clientId][clientAccountId].loading=false;
            
        },

        invoicesRequested:(invoices,action)=>{
            const {clientId,clientAccountId} = action.payload.data; 
            invoices[clientId][clientAccountId].loading=true;
        },
        
        invoicesRequestedFailed:(invoices,action)=>{
            const {clientId,clientAccountId} = action.payload.data; 
            invoices[clientId][clientAccountId].loading = false;
        },

        invoiceDetailsReceived:(invoices,action)=>{
            const {clientId,clientAccountId} = action.payload; 
            const {invoice,invoiceItems} = action.payload; 
            const {id} = invoice;

            const invoicesObject = invoices[clientId][clientAccountId];
            
            //Add to invoices data structure
            invoicesObject.byIds[id] = invoice;
            if(!invoicesObject.allIds.includes(id)) 
                invoicesObject.allIds.push(id);
            
            
            //Add/Update to campaigns data structure
            if(!invoicesObject.invoiceDetailsByIds)
               invoicesObject.invoiceDetailsByIds = {};
            
            invoicesObject.invoiceDetailsByIds[id] = {
                invoiceItems,
                loading:false,
                lastFetch:Date.now(),
            };
            invoicesObject.invoiceItemTaskProcessingFlag = false;
        },

        invoiceBalanceRequested:(invoices,action)=>{
            const {clientId,clientAccountId} = action.payload.data; 
            invoices[clientId][clientAccountId].loading=true;
        },
        
        invoiceBalanceFailed:(invoices,action)=>{
            const {clientId,clientAccountId} = action.payload.data; 
            invoices[clientId][clientAccountId].loading = false;
        },

        invoiceBalanceReceived:(invoices,action)=>{
            const {clientId,clientAccountId,invoiceBalance,openInvoiceCount} = action.payload; 
            const invoicesObject = invoices[clientId][clientAccountId];
            invoicesObject.invoiceBalance = invoiceBalance;
            invoicesObject.openInvoiceCount = openInvoiceCount;
            invoicesObject.loading = false;
        },

        invoiceDetailsRequested:(invoices,action)=>{
            const {clientId,clientAccountId} = action.payload.data; 
            const invoicesObject = invoices[clientId][clientAccountId];
            invoicesObject.invoiceItemTaskProcessingFlag = true;
        },
        
        invoiceDetailsRequestedFailed:(invoices,action)=>{
            const {clientId,clientAccountId} = action.payload.data; 
            const invoicesObject = invoices[clientId][clientAccountId];
            invoicesObject.invoiceItemTaskProcessingFlag = false;
        },

        invoicesPaymentProcessingRequested:(invoices,action)=>{
            const {clientId,clientAccountId} = action.payload.data; 
            invoices[clientId][clientAccountId].paymentTaskProcessingFlag=true;
        },
        
        invoicesPaymentProcessingRequestedFailed:(invoices,action)=>{
            const {clientId,clientAccountId} = action.payload.data; 
            invoices[clientId][clientAccountId].paymentTaskProcessingFlag = false;
        },

        invoicePdfGeneratedReceived:(invoices,action)=>{
            const {clientId,clientAccountId} = action.payload; 
            const invoicesObject = invoices[clientId][clientAccountId];
            invoicesObject.invoiceItemTaskProcessingFlag = false;
        },

        invoicePdfGeneratedRequested:(invoices,action)=>{
            const {clientId,clientAccountId} = action.payload; 
            const invoicesObject = invoices[clientId][clientAccountId];
            invoicesObject.invoiceItemTaskProcessingFlag = true;
        },

        invoicePdfGeneratedFailed:(invoices,action)=>{
            const {clientId,clientAccountId} = action.payload; 
            const invoicesObject = invoices[clientId][clientAccountId];
            invoicesObject.invoiceItemTaskProcessingFlag = false;
        },

        
    }
});

//---------------Actions & Reducer export setup----------------------------
const {
    clientAndClientAccountAdded,
    invoicesForPaymentCleared,
    invoicesForPaymentSelected,
    invoicesPaid,
    invoicePdfGenerated,
    invoicesReceivedForAllStatus,
    invoicesReceivedForIndividualStatus,
    invoicesRequested,
    invoicesRequestedFailed,
    invoiceBalanceRequested,
    invoiceBalanceReceived,
    invoiceBalanceFailed,
    invoiceDetailsReceived,
    invoiceDetailsRequested,
    invoiceDetailsRequestedFailed,
    invoicesPaymentProcessingRequested,
    invoicesPaymentProcessingRequestedFailed,
    invoicePdfGeneratedReceived,
    invoicePdfGeneratedRequested,
    invoicePdfGeneratedFailed,
    
} = slice.actions

//export reducer
export default slice.reducer;

//---------------End of Actions & Reducer export setup----------------------------


//-------------ROUTE URL-----------------------
const url = ENDPOINT_FOR_INVOICE_SLICE;

const ROUTE_GET_INVOICES_BY_FILTER = "getInvoicesByFilter";
const ROUTE_GET_INVOICE_DETAILS = "getInvoiceDetails";
const ROUTE_GET_INVOICE_BALANCE = "getInvoiceBalance";

const ROUTE_GENERATE_INVOICE_PDF = "generateInvoicePdf";
const ROUTE_GENERATE_INVOICE_PDF_AS_ZIP = "generateInvoicePdfAsZip";
const ROUTE_PAY_INVOICES = "payInvoices";


//-------------END ROUTE URL-----------------------


//-------------Export Action Creators-----------------------
/*
  Load the invoice balance and number of open invoices
  returns back
  {
     invoiceBalance:0,
     openInvoiceCount:0,
  }
*/
export const loadInvoiceBalance = ()=>async (dispatch,getState)=>{
    const {activeView,accessControlList}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const data = {
        activeView,
        clientId,
        clientAccountId,
    };

    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_GET_INVOICE_BALANCE}`,
        method:'post',
        data,
        onStart:[invoiceBalanceRequested.type],
        onSuccess:[invoiceBalanceReceived.type],
        onError:[invoiceBalanceFailed.type],
    }))
}


/*
   Send the payload
   in the reducer I will trigger the download but not update the state
*/
export const generateInvoicePdf = (invoiceItemIdList)=>async (dispatch,getState)=>{
    const {activeView,accessControlList}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const {clientAccountById} = accessControlList;
    const {onBehalfOfClient} = clientAccountById[clientAccountId];
        
    const data = {
        activeView,
        clientId,
        clientAccountId,
        invoiceItemIdList,
        onBehalfOfClient,
    };

    /*
    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_GENERATE_INVOICE_PDF}`,
        method:'post',
        data,
        onStart:[invoicesRequested.type],
        onSuccess:[invoicePdfGenerated.type],
        onError:[invoicesRequestedFailed.type],
    }))
    */

    try{
        dispatch(invoicePdfGeneratedRequested({...data}));
        const {data:content} = await axios.post(`${url}/${ROUTE_GENERATE_INVOICE_PDF}`,data,{responseType:'blob'});
        const invoiceBlob = new Blob([content], {type : 'application/pdf'})
        saveAs(invoiceBlob,"Invoice.pdf");  
        dispatch(invoicePdfGeneratedReceived({...data}))
    }catch(ex){
        dispatch(invoicePdfGeneratedFailed({...data}));
        const {message,position} = toastNotificationMessages.invoiceDownloadedError;
        toast.error(message,position);
    }
}

export const generateInvoicePdfAsZip = (invoiceItemIdList)=>async (dispatch,getState)=>{
    const {activeView,accessControlList}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const {clientAccountById} = accessControlList;
    const {onBehalfOfClient} = clientAccountById[clientAccountId];
        
    const data = {
        activeView,
        clientId,
        clientAccountId,
        invoiceItemIdList,
        onBehalfOfClient,
    };

    try{
        dispatch(invoicePdfGeneratedRequested({...data}));
        const {data:content} = await axios.post(`${url}/${ROUTE_GENERATE_INVOICE_PDF_AS_ZIP}`,data,{responseType:'blob'});
        const invoiceBlob = new Blob([content], {type : 'application/zip'})
        saveAs(invoiceBlob,`JE_Invoice_Bundle_${moment().format('YYYY-MM-DD_HHMMSS')}.zip`);  
        dispatch(invoicePdfGeneratedReceived({...data}))
    }catch(ex){
        dispatch(invoicePdfGeneratedFailed({...data}));
        const {message,position} = toastNotificationMessages.invoiceDownloadedError;
        toast.error(message,position);
    }
}

/* 
   Feed the list to the payment processing list in the invoice slice (This is used by the modal window)
   Set the payment modal display flag in the ui-invoice slice
*/
export const handlePaymentModal = (displayFlag,invoicesIdListForPayment)=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    if(displayFlag === true){
        dispatch(invoicesForPaymentSelected({
            clientId,
            clientAccountId, 
            invoicesIdListForPayment,
        }))
        
        setPaymentModalDisplay(displayFlag)(dispatch);
    }
    else{
        dispatch(invoicesForPaymentCleared({
            clientId,
            clientAccountId, 
        }))
        setPaymentModalDisplay(displayFlag)(dispatch)
    }
}


export const loadInvoiceDetails = (invoiceId)=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    //Need to get this from the auth slice
    const {invoices} = getState().entities;
    if(_.has(invoices,`${clientId}.${clientAccountId}.invoiceDetailsByIds`)){
     const {invoiceDetailsByIds} = getState().entities.invoices[clientId][clientAccountId];
     //If invoiceDetails is there then we don't need to make the api call
     if(invoiceDetailsByIds.hasOwnProperty(invoiceId)) return; 
    }

    const data = {
        activeView,
        clientId ,
        clientAccountId ,
        invoiceId,
        activeView,
    }

    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_GET_INVOICE_DETAILS}`,
        method:'post',
        data,
        onStart:[invoiceDetailsRequested.type],
        onSuccess:[invoiceDetailsReceived.type],
        onError:[invoiceDetailsRequestedFailed.type],
    }))
}


//This is to make a call to the database for the initial load to get data for all the views
export const loadInvoices = ()=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    if(activeView && activeView.client && activeView.client.id && activeView.clientAccount.id){
        const clientId = activeView.client.id;
        const clientAccountId = activeView.clientAccount.id;
        
        const  {invoices} = getState().entities;

        if(_.has(invoices,`${clientId}.${clientAccountId}`)){
            const {lastFetch} = invoices[clientId][clientAccountId];
            const diffInMinutes = moment().diff(moment(lastFetch),'minutes');
            if(diffInMinutes < REFETCH_THRESHOLD_IN_MINUTES) return;
        }
        
        
        const query = {...getState().ui.invoiceView.query};
        query.clientId = clientId;
        query.clientAccountId = clientAccountId;
        query.activeView = activeView
        
        //This happens only for initial load: any switch in views updates the filtered view property in the query object
        query.filteredView = INVOICE_ALL;

        dispatch(apiCallBegan({
            url:`${url}/${ROUTE_GET_INVOICES_BY_FILTER}`,
            method:'post',
            data:query,
            onStart:[invoicesRequested.type],
            onSuccess:[invoicesReceivedForAllStatus.type],
            onError:[invoicesRequestedFailed.type],
        }))
    }
}

//Load invoices by keyword
export const loadInvoicesByKeyword = ()=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const invoiceView = getState().ui.invoiceView;
    
    const query = {...invoiceView.query};
    query.clientId = clientId;
    query.clientAccountId = clientAccountId;
    query.activeView = activeView
    
    //This happens only for initial load: any switch in views updates the filtered view property in the query object
    query.filteredView = INVOICE_ALL;

    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_GET_INVOICES_BY_FILTER}`,
        method:'post',
        data:query,
        onStart:[invoicesRequested.type],
        onSuccess:[invoicesReceivedForAllStatus.type],
        onError:[invoicesRequested.type],
    }))
}

//Load invoices per page for a specific filter
export const loadInvoicesPerFilter = ()=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    
    const invoiceView = getState().ui.invoiceView;

    const query = {...invoiceView.query};
    query.clientId = clientId;
    query.clientAccountId = clientAccountId;
    //query.activeView = getActiveView(getState());
    query.activeView = activeView;
    
    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_GET_INVOICES_BY_FILTER}`,
        method:'post',
        data:query,
        onStart:[invoicesRequested.type],
        onSuccess:[invoicesReceivedForIndividualStatus.type],
        onError:[invoicesRequested.type],
    }))
    
}

/*
    Need to provided the credit card surcharge in the payload
*/
export const payInvoices = (formData)=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    const clientId = activeView.client.id;
    const clientAccountId = activeView.clientAccount.id;
    const data = {
        activeView,
        clientId,
        clientAccountId,
        ...formData
    }
    //console.log(query);

    dispatch(apiCallBegan({
        url:`${url}/${ROUTE_PAY_INVOICES}`,
        method:'post',
        data,
        onStart:[invoicesPaymentProcessingRequested.type,paymentProcessedErrorUICleared.type],
        onSuccess:[
            invoicesPaid.type,
            paymentProcessedOnSuccess.type,
            notificationsByClientAndClientAccountFetchTimestampCleared.type
        ],
        onError:[invoicesPaymentProcessingRequestedFailed.type,paymentProcessedOnError.type],
    }))
}


export const setClientAndClientAccount = ()=>(dispatch,getState)=>{
    const {activeView}  = getState().auth;
    if(activeView && activeView.client && activeView.client.id && activeView.clientAccount.id){
        const clientId = activeView.client.id;
        const clientAccountId = activeView.clientAccount.id;
        
        dispatch(clientAndClientAccountAdded({
            clientId,
            clientAccountId,
        }))
    }
}

//-------------End of Export Action Creators-----------------------


//------------------Selectors-----------------------

export const getCurrencyAttributesForCampaigns = (client,campaign)=>{
    let currencySymbol = "$";
    let currenyExchangeRate = 1;
    let currencyBuffer = 0;
    let needToUseForeignExchangeConversion  = false;
    let currencyCodeToUse = "USD";

    if(client){
        const {currencyCodeQuote,CurrencyQuote} = client;
        if(currencyCodeQuote && currencyCodeQuote !== "USD"){
            const {exchange_rate:exchangeRate,quote_buffer:quoteBuffer,symbol} = CurrencyQuote;
            currencySymbol = symbol;
            currenyExchangeRate = exchangeRate;
            currencyBuffer = quoteBuffer;
            needToUseForeignExchangeConversion = true;
            currencyCodeToUse = currencyCodeQuote;
        }
    }

    if(campaign){
        const {currencyCode,Currency} = campaign;
        if(currencyCode){
            const {exchange_rate:exchangeRate,symbol} = Currency;
            currencySymbol = symbol;
            currenyExchangeRate = exchangeRate;
            needToUseForeignExchangeConversion = true;
            currencyCodeToUse = currencyCode;
        }
    }

    return {
        currencySymbol,
        currenyExchangeRate:currenyExchangeRate || 1,
        currencyBuffer,
        currencyCode:currencyCodeToUse,
        needToUseForeignExchangeConversion
    }
}

export const getCurrencyAttributesForInvoices = (client,invoice)=>{
    let currencySymbol = "$";
    let currencyExchangeRate = 1;
    let currencyBuffer = 0;
    let needToUseForeignExchangeConversion  = false;
    let currencyCodeToUse = "USD";

    if(client){
        const {currencyCodeInvoice,CurrencyInvoice} = client;
        if(currencyCodeInvoice && currencyCodeInvoice !== "USD"){
            const {exchange_rate:exchangeRate,invoice_buffer:invoiceBuffer,symbol} = CurrencyInvoice;
            currencySymbol = symbol;
            currencyExchangeRate = exchangeRate;
            currencyBuffer = invoiceBuffer;
            needToUseForeignExchangeConversion = true;
            currencyCodeToUse = currencyCodeInvoice;
        }

    }

    if(invoice){
        const {currencyCode,currencyBuffer:invoiceBuffer,currencyExchangeRate:invoiceExchangeRate,Currency,status} = invoice;
        if(currencyCode){
            const {exchange_rate:exchangeRate,symbol} = Currency;
            currencySymbol = symbol;
            currencyExchangeRate = exchangeRate;
            needToUseForeignExchangeConversion = true;
            currencyCodeToUse = currencyCode;

            if(status === INVOICE_STATUS_CLOSED){
                currencyExchangeRate = invoiceExchangeRate;
                currencyBuffer = invoiceBuffer;
            }
        }
    }

    return {
        currencySymbol,
        currencyExchangeRate:currencyExchangeRate,
        currencyBuffer,
        currencyCode:currencyCodeToUse,
        needToUseForeignExchangeConversion
    }
}

export const getInvoice = (invoiceId)=>createSelector(
    state=>state.entities.invoices,
    state=>state.auth,
    (invoices,auth)=>{
          const {activeView}  = auth;
          if(activeView === "") return null;
  
          const clientId = activeView.client.id;
          const clientAccountId = activeView.clientAccount.id;
      
          return (_.has(invoices,`${clientId}.${clientAccountId}`))? invoices[clientId][clientAccountId].byIds[invoiceId]:null;
    }
);

export const getInvoiceDetails = (invoiceId)=>createSelector(
    state=>state.entities.invoices,
    state=>state.auth,
    (invoices,auth)=>{
        const {activeView}  = auth;
        const clientId = activeView.client.id;
        const clientAccountId = activeView.clientAccount.id;  
        return (_.has(invoices,`${clientId}.${clientAccountId}.invoiceDetailsByIds.${invoiceId}`))? 
            invoices[clientId][clientAccountId].invoiceDetailsByIds[invoiceId].invoiceItems:[];
      
    }
);

//This is to get campaigns based on the currenct select client/client account
export const getInvoiceList = createSelector(
    state=>state.entities.invoices,
    state=>state.auth,
    (invoices,auth)=>{
        const {activeView}  = auth;
        if(activeView === "") return null;

        const clientId = activeView.client.id;
        const clientAccountId = activeView.clientAccount.id;
    
        return (_.has(invoices,`${clientId}.${clientAccountId}`))? invoices[clientId][clientAccountId]:{...emptyInvoiceObject}
    }
)


export const getInvoiceItemTaskProcessingFlag = ()=>createSelector(
    state=>state.entities.invoices,
    state=>state.auth,
    (invoices,auth)=>{
        const {activeView}  = auth;
        if(!activeView) 
         return false;
        
        const clientId = activeView.client.id;
        const clientAccountId = activeView.clientAccount.id;  
        return (_.has(invoices,`${clientId}.${clientAccountId}`))?
         invoices[clientId][clientAccountId].invoiceItemTaskProcessingFlag:false;
    }
);

//Payment processing indicator
export const getPaymentTaskProcessingFlag= createSelector(
    state=>state.entities.invoices,
    state=>state.auth,
    (invoices,auth)=>{
        const {activeView}  = auth;
        if(activeView === "") return false;

        const clientId = activeView.client.id;
        const clientAccountId = activeView.clientAccount.id;
    
        return (_.has(invoices,`${clientId}.${clientAccountId}`))? 
            invoices[clientId][clientAccountId].paymentTaskProcessingFlag:false;
    }
)

export const getTotalUnpaidBalance = ()=>createSelector(
    state=>state.entities.invoices,
    state=>state.auth,
    (invoices,auth)=>{
        const {activeView}  = auth;
        if(!activeView) 
         return 0
        
        const clientId = activeView.client.id;
        const clientAccountId = activeView.clientAccount.id;  

        /*
        if(_.has(invoices,`${clientId}.${clientAccountId}.idListByStatus.${INVOICE_TAB_OPEN}`)){
            const invoiceList = invoices[clientId][clientAccountId].byIds;
            const openInvoicesIdList = invoices[clientId][clientAccountId]['idListByStatus'][INVOICE_TAB_OPEN];
            
            if(openInvoicesIdList &&  openInvoicesIdList.length){
                const balance =  openInvoicesIdList.reduce((accumulator, id) => {
                    //console.log(invoiceList[id].balance)
                    return accumulator + invoiceList[id].balance
                },0) 
                return balance;
            }else 
                return 0;

            
        }
        else 
         return 0;

        */ 

       if(_.has(invoices,`${clientId}.${clientAccountId}.invoiceBalance`)){
        return invoices[clientId][clientAccountId].invoiceBalance;
       }
       else 
         return 0;
    }

        
    
)


export const getTotalCountUnpaidInvoices = ()=>createSelector(
    state=>state.entities.invoices,
    state=>state.auth,
    (invoices,auth)=>{
        const {activeView}  = auth;
        if(!activeView) 
         return 0
        
        const clientId = activeView.client.id;
        const clientAccountId = activeView.clientAccount.id;  

        if(_.has(invoices,`${clientId}.${clientAccountId}.openInvoiceCount`)){
            return invoices[clientId][clientAccountId].openInvoiceCount;
        }
        else 
         return 0;

        
    }
)

//--------------End of Selectors-----------------------

