import http from "./httpService";
import jwtDecode from "jwt-decode";

const jsonWebToken = 'token';

const activeViewDataKey = 'activeView';

const activeUserIdDataKey = 'activeUserId';



//This is called on refresh of the whole page (Remove bidirectional dependencies)
http.setJwt(getJwt());

/*
Check is token has expired
*/
function getCurrentUser(){
    try{
      const jwt = localStorage.getItem(jsonWebToken);
      const token = jwtDecode(jwt);
      //console.log(user);
      const {exp:expirationTime} = token;
      const now = Math.floor(Date.now() / 1000);
      if(now < expirationTime)
        return token.data;
      else{
        logout();
        return null;
      }
    }
    catch(ex){
      return null;
    }
}

function getActiveUserId(){
  try{
    const activeView = localStorage.getItem(activeUserIdDataKey);
    return activeView;
  }
  catch(ex){
    return null;
  }
}

function getActiveView(){
    try{
      const activeView = JSON.parse(localStorage.getItem(activeViewDataKey));
      return activeView;
    }
    catch(ex){
      return null;
    }
}


function getJwt(){
    return localStorage.getItem(jsonWebToken);
}


function logout(){
    localStorage.removeItem(jsonWebToken);
    localStorage.removeItem(activeViewDataKey);
    localStorage.removeItem(activeUserIdDataKey);
    window.location = "/login";

}

function setActiveUserId(data){
  return localStorage.setItem(activeUserIdDataKey,data);
}

function setActiveView(data){
    return localStorage.setItem(activeViewDataKey,data);
}

function setJwt(jwt){
    return localStorage.setItem(jsonWebToken,jwt);
}


export default {
    getActiveUserId,
    getActiveView,
    getCurrentUser,
    getJwt,
    logout,
    setActiveUserId,
    setActiveView,
    setJwt,
}
