import { combineReducers } from "redux";
import campaignsReducer from "./campaigns";
import invoicesReducer from "./invoices";
import pendingQuotesReducer from "./pendingQuotes";
import clientsReducer from "./clients";



export default combineReducers({
    campaigns:campaignsReducer,
    clients:clientsReducer,
    invoices:invoicesReducer,
    pendingQuotes:pendingQuotesReducer,
})