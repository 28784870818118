import React, { Fragment, Component } from 'react';
import accounting from "accounting";

import PerfectScrollbar from 'react-perfect-scrollbar';

import {Link} from "react-router-dom"
import { connect } from 'react-redux';
import RouterLink from '../ReactMetismenuRouterLink';

import MetisMenu from 'react-metismenu';
import {Badge,Button} from 'reactstrap';

import { SidebarUserbox } from '../../layout-components';

//Load campaign action creators
import { 
  getTotalCountUnpaidInvoices,
  getTotalUnpaidBalance,
  loadInvoices,
  loadInvoiceBalance, 
  setClientAndClientAccount,
} from "../../store/invoices"

import {
  getActiveView,
  getAccessToCandidatesModule,
  isActiveClientAccountSelected,
  isUserJeAdministrator,
} from "../../store/auth"

import { 
  getNotificationsByClientAndClientAccount,
  getActiveClientDetail,
  loadClientDetails,
} from "../../store/clients"

import {
  getJobelephantClientRecord,
} from "../../store/globalProperties"



const sidebarMenuContent = [
  {
    label: 'Dashboard',
    icon: 'pe-7s-safe',
    to: '/Dashboard',
    allowedPermission:"",
  },
  {
    label: 'Notifications',
    icon: 'pe-7s-bell',
    to: '/notifications',
    allowedPermission:"",
  },
  {
    label: 'Request New Quote',
    icon: 'pe-7s-note',
    to: '/request-new-quote',
    allowedPermission:'manageRequestQuote',
  },
  {
    label: 'My Campaigns',
    icon: 'pe-7s-portfolio',
    to: '/my-campaigns',
    allowedPermission:'manageCampaigns',
  }, 
  {
    label: 'Invoices',
    icon: 'pe-7s-cash',
    to: '/invoices',
    allowedPermission:'manageInvoices',
  },
  {
    label: 'Media Contracts',
    icon: 'pe-7s-wallet',
    to: '/contracts',
    allowedPermission:'manageContracts',
  },
  
];

class SidebarMenu extends Component {

  constructor(props){
    super(props);
    this.state = {
      candidate:{
        expandMenu:false,
      },
     
    }
  }

  componentDidMount(){
    let { header} = this.props;
    const listOfMenuItem  = ["candidate"]
    if(listOfMenuItem.includes(header.toLowerCase())){
      this.displaySubmenuOnComponentUpdate()
    }
    
  }

  componentDidUpdate(prevProps){
    const {header,isActiveClientAccountSelected,loadClientDetails,loadInvoices,setClientAndClientAccount} = this.props;
    const {header:headerPrev,isActiveClientAccountSelectedPrev} = prevProps;
    console.log(header,headerPrev)
    
    if(isActiveClientAccountSelected !== isActiveClientAccountSelectedPrev){
      setClientAndClientAccount();
      loadClientDetails();
      loadInvoices();
      loadInvoiceBalance();
    }

    if(header !== headerPrev){
      this.displaySubmenuOnComponentUpdate()
    }
    
  }

  displaySubmenuOnComponentUpdate = ()=>{
    let { header} = this.props;

    const listOfMenuItem  = ["candidate"]
    listOfMenuItem.forEach((menuItem)=>{
        if(this.state.hasOwnProperty(menuItem)){
          this.setState({
            [menuItem]:{
              expandMenu:(header.toLowerCase() ==  menuItem) ? true:false
            }
          })
        }
      }
    )
  }

  displaySubmenu = (menuItem)=>{
    if(this.state.hasOwnProperty(menuItem)){
      let currentAction = this.state[menuItem].expandMenu
      this.setState({
        [menuItem]:{
          expandMenu:!currentAction
        }
      })
    }
  }

  displaySubmenuClass = (menuItem)=>{
    if(this.state.hasOwnProperty(menuItem)){
      let action = this.state[menuItem].expandMenu
      return (action === true) ? "submenu-open":""
    }
  }



  displayInvoiceWidget = ()=>{
    const {activeView} = this.props;
    
    if(activeView){
      const {permissionList}  = activeView;
      const {manageInvoices} = permissionList;
      return manageInvoices;
    }

    return false;
  }

  getAccessToQuestionnaireModule = ()=>{
    let {activeView, jobelephantClientRecord} = this.props;
    const {client} = activeView
    const {id:clientId} = jobelephantClientRecord
    return clientId == client.id
  }

  /*
    We using this to set navigation class as active. Page type is set in the Route
  */
  getPageType = ()=>{
    
    let pageType =""
    if(this.props && this.props.urlParameters && this.props.urlParameters.pageType){
      pageType = this.props.urlParameters.pageType
    }
    return pageType
  }

  getSidebarMenuBasedOnPermissions = ()=>{
    const {activeView} = this.props;
    
    if(activeView){
      const {permissionList}  = activeView;
      return sidebarMenuContent.filter(s=>{
        const {allowedPermission} = s;
        if(allowedPermission !== ""){
          return permissionList[allowedPermission]; //will return true or false as set in the activeView.permissionList
        }
        else 
          return true //blank means its accessible
      })

    }

    return sidebarMenuContent;
  }


  render() {
    let {accessToCandidateModule, client,isUserJeAdministrator,jobelephantClientRecord,notificationList,sidebarUserbox ,totalCountOfUnpaidInvoices,totalUnpaidBalance,header,subMenu} = this.props;

    const sidebarMenuContent = this.getSidebarMenuBasedOnPermissions();
    const displayInvoiceWidget = this.displayInvoiceWidget();

    let currencySymbol = "$";
    let currenyExchangeRate = 1;
    let currencyBuffer = 0;
    let needToUseForeignExchangeConversion  = false;
    let currencyCode = "USD";
    let pageType  = this.getPageType() 

    let displayPersonalized101Link = false
    let jeClientPersonalizedUrlLink = null

    if(client){
      const {currencyCodeInvoice,CurrencyInvoice,jeClientPersonalizedUrl} = client;
      if(currencyCodeInvoice && currencyCodeInvoice !== "USD"){
          const {exchange_rate:exchangeRate,invoice_buffer:invoiceBuffer,symbol} = CurrencyInvoice;
          currencySymbol = symbol;
          currenyExchangeRate = exchangeRate;
          currencyBuffer = invoiceBuffer;
          needToUseForeignExchangeConversion = true;
          currencyCode = currencyCodeInvoice;
      }

      if(jeClientPersonalizedUrl){
        jeClientPersonalizedUrlLink = jeClientPersonalizedUrl
        displayPersonalized101Link = true
      } 
    }
    
    console.log("SidebarMenuJe",pageType)
    return (
      <Fragment>
        <PerfectScrollbar>
          <div className="sidebar-navigation">
            <div className="d-none">
              <MetisMenu
                content={sidebarMenuContent}
                LinkComponent={RouterLink}
                activeLinkFromLocation
                iconNamePrefix=""
                noBuiltInClassNames={true}
                classNameItemActive="active"
                classNameIcon="sidebar-icon"
                iconNameStateVisible="sidebar-icon-indicator"
                iconNameStateHidden="sidebar-icon-indicator"
                classNameItemHasVisibleChild="submenu-open"
                classNameItemHasActiveChild="active"
                classNameStateIcon="pe-7s-angle-down"
              />
            </div>
            <div>

              <div className="">
                <ul className="">
                  <li className={(header && header === "Dashboard") ? "active":""}>
                    
                    <a href="/Dashboard" 
                      onClick={(e)=>{
                        e.preventDefault();
                        return this.props.useHistory.push("/Dashboard")
                      }}
                    >
                      <i className="sidebar-icon pe-7s-safe"></i>Dashboard
                    </a>
                  </li>
                  <li className={(header && header === "Notifications") ? "active":""}>
                    <a className="badge-wrapper" href="/notifications" 
                      onClick={(e)=>{
                        e.preventDefault();
                        return this.props.useHistory.push("/notifications")
                      }}
                    >
                      { notificationList.length > 0 && 
                        <Badge
                          color="danger"
                          className="badge-position badge-position--top-right badge-circle notification-bubble" 
                        >
                          New notifications
                        </Badge>  
                      }
                      <i className="sidebar-icon pe-7s-bell"></i>Notifications
                    </a>
                  </li>
                  <li className="">
                    <a className="" href="/request-new-quote"
                      onClick={(e)=>{
                        e.preventDefault();
                        return this.props.useHistory.push("/request-new-quote/step-1")
                      }}
                    >
                      <i className="sidebar-icon pe-7s-note"></i>Request New Quote
                    </a>
                  </li>
                  <li className={(header && (header === "Campaign" || header ==="CampaignDetail")) ? "active":""}>
                    <a className="" href="/my-campaigns"
                      onClick={(e)=>{
                        e.preventDefault();
                        return this.props.useHistory.push("/my-campaigns")
                      }}
                    >
                      <i className="sidebar-icon pe-7s-portfolio"></i>My Campaigns
                    </a>
                  </li>
                  <li className={(header && header === "Invoice") ? "active":""}>
                    <a className="" href="/invoices"
                      onClick={(e)=>{
                        e.preventDefault();
                        return this.props.useHistory.push("/invoices")
                      }}
                    >
                      <i className="sidebar-icon pe-7s-cash"></i>Invoices
                    </a>
                  </li>
                  <li className={(header && header === "Contract") ? "active":""}>
                    <a className="" href="/contracts"
                      onClick={(e)=>{
                        e.preventDefault();
                        return this.props.useHistory.push("/contracts")
                      }}
                    >
                      <i className="sidebar-icon pe-7s-wallet"></i>Media Contracts
                    </a>
                  </li>

                  {
                    displayPersonalized101Link && 
                    <li>
                      <a className="" href={jeClientPersonalizedUrlLink}
                        target="_blank"
                      >
                        <i className="sidebar-icon pe-7s-next-2"></i>Jobelephant 101 Page
                      </a>
                    </li>
                  }

                  { 
                    accessToCandidateModule && 
                    <li className={(header && (header === "Candidate")) ? `active ${this.displaySubmenuClass('candidate')}`:`${this.displaySubmenuClass('candidate')}`}>
                        <a className={(header && (header === "Candidate")) ? "cursor-pointer":"cursor-pointer bg-white"} onClick={e=>{
                          e.preventDefault()
                          this.displaySubmenu("candidate")
                        }}>
                            <i className="sidebar-icon pe-7s-users"></i>Candidates
                            <i className="pe-7s-angle-down sidebar-icon-indicator"></i>
                        </a>
                        <ul className="">
                            <li className="">
                                <a 
                                  className={subMenu === "view-candidates" ? "active":""} 
                                  href="/candidates/view-candidates"
                                  onClick={(e)=>{
                                    e.preventDefault();
                                    return this.props.useHistory.push("/candidates/view-candidates/index")
                                  }}
                                >
                                  <i className="sidebar-icon"></i>View Candidates
                                </a>
                            </li>
                            <li className="">
                              <a 
                                  className={subMenu  === "reports" ? "active":""} 
                                  href="/candidates/reports"
                                  onClick={(e)=>{
                                    e.preventDefault();
                                    return this.props.useHistory.push("/candidates/reports/index")
                                  }}
                                >
                                  <i className="sidebar-icon"></i>Reports & Exports
                                </a>  
                            </li>
                            {
                              this.getAccessToQuestionnaireModule() && 
                              <li className="">
                                <a 
                                  className={subMenu === "questionnaire" ? "active":""} 
                                  href="/candidates/questionnaires"
                                  onClick={(e)=>{
                                    e.preventDefault();
                                    return this.props.useHistory.push("/candidates/questionnaires/index")
                                  }}
                                >
                                  <i className="sidebar-icon"></i>Questionnaire
                                </a>
                              </li>
                            }
                        </ul>
                    </li>
                  }
                  
                </ul>
              </div>

            </div>
            <div className="d-none">
              <div>
                <div className="">
                  <ul className="">
                    <li className="active"><a className="" href="/Dashboard"><i className="sidebar-icon pe-7s-safe"></i>Dashboard</a></li>
                    <li className="">
                      <a className="" href="/notifications">
                        <i className="sidebar-icon pe-7s-bell"></i>
                        Notifications
                        <Badge color="danger" className="badge-circle ml-2">
                          Offline
                        </Badge>
                      </a>
                    </li>
                    <li className=""><a className="" href="/request-new-quote"><i className="sidebar-icon pe-7s-note"></i>Request New Quote</a></li>
                    <li className=""><a className="" href="/my-campaigns"><i className="sidebar-icon pe-7s-portfolio"></i>My Campaigns</a></li>
                    <li className="">
                      <a className="" href="/invoices">
                        <i className="sidebar-icon pe-7s-cash"></i>
                        Invoices 
                        <Badge color="danger" className="badge-circle ml-2">
                          Offline
                        </Badge>
                      </a>
                    </li>
                    <li className=""><a className="" href="/contracts"><i className="sidebar-icon pe-7s-wallet"></i>Media Contracts</a></li>
                  </ul>
                </div>
              </div>
            </div>
            {
              displayInvoiceWidget && totalCountOfUnpaidInvoices > 0 && 
              <div className="dashboard-unpaid-invoice-area">
                <div className="border-top pt-2">
                  <span className="text-danger font-size-sm"><i>{`${totalCountOfUnpaidInvoices} Unpaid Invoices`}</i></span>
                  <h1 className="display-4 font-weight-bold text-danger mb-0 mt-1">
                    {`${accounting.formatMoney(
                        ((totalUnpaidBalance * currenyExchangeRate) + ((totalUnpaidBalance * currenyExchangeRate) * currencyBuffer)),
                        currencySymbol)} ${(currencyCode && currencyCode !== "USD") ? currencyCode:''}`
                    }
                  </h1>
                  <Link to="/invoices" className="p-0 font-size-md btn btn-link">
                    <span className="btn-wrapper--label">
                      <u>View Invoices</u>
                    </span>
                  </Link>
                </div>
              </div>
            }

          </div>
        </PerfectScrollbar>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeView:getActiveView(state),
  accessToCandidateModule:getAccessToCandidatesModule(state),
  client:getActiveClientDetail(state),
  isActiveClientAccountSelected:isActiveClientAccountSelected(state),
  isUserJeAdministrator:isUserJeAdministrator(state),
  jobelephantClientRecord:getJobelephantClientRecord(state),
  notificationList:getNotificationsByClientAndClientAccount(state),
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  totalUnpaidBalance:getTotalUnpaidBalance()(state),
  totalCountOfUnpaidInvoices:getTotalCountUnpaidInvoices()(state),
});

const mapDispatchToProps = dispatch=>({
    loadClientDetails:()=>dispatch(loadClientDetails()),
    loadInvoices:()=>dispatch(loadInvoices()),
    loadInvoiceBalance:()=>dispatch(loadInvoiceBalance()),
    setClientAndClientAccount:(currentClientProperties)=>dispatch(setClientAndClientAccount(currentClientProperties)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
