import axios from 'axios';

export function setJwt(jwt){
    //console.log("axios x-auth-token is being set:",jwt);
    axios.defaults.headers.common['x-auth-token'] = jwt;
}

export default {
    get:axios.get,
    post:axios.post,
    delete:axios.delete,
    put:axios.put,
    setJwt
}
  
