import React, {Fragment, useState, Component} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import cx from 'classnames';
import {Link} from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Row, Table, Col, CardBody, Card, CardTitle, CardHeader, Collapse, UncontrolledPopover, Container, InputGroup, InputGroupAddon, InputGroupText, FormText, Form, CustomInput, Label, FormGroup, Input, Badge, ListGroup, ListGroupItem, PopoverHeader, PopoverBody, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, Progress, DropdownToggle, DropdownMenu, Alert, DropdownItem } from 'reactstrap'

import {connect} from 'react-redux';

import {
    getActiveView,
    getActiveClientAccount,
} from '../../store/auth'

import { 
    downloadCampaignSummary,
    getCampaign,
    getCampaignDetails,
    stopAdForCampaignItemsList,
} from "../../store/campaigns"

import {
    getSelectedCampaignItemsPerView,
} from "../../store/ui-campaign-details";


import {
    setSidebarToggleMobile
} from '../../reducers/ThemeOptions';

import applicationConstants from "../../store/applicationConstants"

const {
    CAMPAIGN_DETAILS_TAB_PUBLISHED,  
    CAMPAIGN_DETAILS_TAB_PROCESSING,
} = applicationConstants;


class HeaderCampaignDetail extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          
        };
    }

    downloadCampaignSummary = ()=>{
        const {campaign,downloadCampaignSummary} = this.props
        const {id:campaignId} = campaign 
        return downloadCampaignSummary({campaignId})
    }

    processStopAdsForCampaign = ()=>{
        let {
            campaign,
            campaignDetails, 
            stopAdForCampaignItemList,   
        } = this.props;

        const {
            byIds,
            idListByStatus,
            itemCounts,
        } = campaignDetails;

        const campaignItems = [];

        if(itemCounts[CAMPAIGN_DETAILS_TAB_PUBLISHED] > 0){
            let statusListToProcess  = [CAMPAIGN_DETAILS_TAB_PUBLISHED];
            statusListToProcess.forEach(status=>{
                const listOfItemsIds = idListByStatus[status];
                listOfItemsIds.forEach(id=>{
                    campaignItems.push(byIds[id])
                })
            });

            return stopAdForCampaignItemList({
                campaignId:campaign.id,
                campaignItems,
                stopWholeCampaignFlag:true,
            })
        }

        //console.log(campaignItems);
        
        

    }
    
      
    toggleSidebarMobile = () => {
        let {sidebarToggleMobile, setSidebarToggleMobile} = this.props;
        setSidebarToggleMobile(!sidebarToggleMobile);
    }

    render() {
        let {
            activeView,
            headerShadow,
            headerBgTransparent,
            sidebarToggleMobile,
            campaign,  
            campaignDetails,  
            clientAccountName,
            useHistory,
        } = this.props;

        const campaignLoaded = (campaign) ? true:false;
        let [campaignId,campaignTitle,jobNumber,itemCounts] = ["","","",0];
        if(campaignLoaded){
            ({name:campaignTitle,id:campaignId,jobNumber} = campaign);
        } 

        if(campaignDetails){
            ({itemCounts} = campaignDetails);
        }

        let [allowToPerformOperations,allowToApproveAds] = [false,false];
        if(activeView && activeView.permissionList){
            allowToPerformOperations = activeView.permissionList.manageCampaigns;
            allowToApproveAds = activeView.permissionList.manageApproveAds;

        }



        return (
            <Fragment>
                <div className={cx("app-header", {'app-header--shadow': headerShadow, 'app-header--opacity-bg': headerBgTransparent})}>
                <div className="app-header--pane">
                    
                    <button className={cx("navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn", {'is-active': sidebarToggleMobile})} onClick={this.toggleSidebarMobile}>
                        <span className="hamburger-box">
                            <span className="hamburger-inner"/>
                        </span>
                    </button>
                    <div className="je-header-page-title mb-3">
                        
                        <div className=" text-center">
                            { !campaignLoaded && <div className="width-50px"><Skeleton count={1} /></div>}
                            {
                                campaignLoaded &&
                                <>
                                    <div className="float-left mt-1 je-header-campaign-detail-page-title">
                                        {campaignTitle}
                                    </div>
                                    <div className="float-left  ml-2 text-black-50 d-none d-xl-block" style={{marginTop:"0.4rem"}}>
                                        | 
                                    </div>
                                    <div  className="float-left ml-2 d-none d-xl-block" style={{marginTop:"0.5rem"}}>
                                        <span className="text-black-50">{ jobNumber !== "" && `#${jobNumber}`}</span>
                                    </div>
                                </>
                            }
                            
                            <div className="clearfix"></div>
                        </div>
                        <div className="je-header-campaign-detail-page-description">
                            { !campaignLoaded && <div className="width-50px"><Skeleton count={1} /></div>}
                            { 
                              campaignLoaded && <strong>{clientAccountName}</strong>
                            }
                        </div>
                        
                    </div>
                    
                </div>
                <div className="app-header--pane">
                    
                    {/*Campaign Options */}
                    {
                        allowToPerformOperations && allowToApproveAds &&
                        <div className="d-none d-sm-block">
                            <UncontrolledDropdown className="mx-3">
                                <DropdownToggle color="secondary" className="border bg-white" caret>
                                    <span className="btn-wrapper--label pr-2">Campaign Options</span>
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-xl p-0">
                                    <Nav className=" flex-column py-2">
                                    {
                                        (itemCounts[CAMPAIGN_DETAILS_TAB_PUBLISHED] > 0) && allowToApproveAds && 
                                        <NavItem>
                                            <NavLink 
                                                className="text-black-50"
                                                href="#" 
                                                onClick={e =>{
                                                    e.preventDefault();
                                                    return this.processStopAdsForCampaign();
                                                }}
                                            >
                                            <div className="nav-link-icon">
                                                <FontAwesomeIcon icon={['far', 'stop-circle']} />
                                            </div>
                                            <span>Stop all Published ads</span>
                                            </NavLink>
                                        </NavItem>
                                    }
                                    
                                    {
                                        allowToApproveAds &&
                                        <NavItem>
                                            <NavLink
                                            className="text-black-50"
                                            href="#"
                                            onClick={e =>{
                                                e.preventDefault();
                                                return useHistory.push(`/campaign-detail/${campaignId}/add-publications`)
                                            }}
                                            >
                                            <div className="nav-link-icon">
                                                <FontAwesomeIcon
                                                icon={['fas', 'plus']}
                                                />
                                            </div>
                                            <span>Add New Publication</span>
                                            </NavLink>
                                        </NavItem>
                                    }

                                    {
                                        allowToApproveAds &&
                                        <NavItem>
                                            <NavLink
                                            className="text-black-50"
                                            href="#"
                                            onClick={e =>{
                                                e.preventDefault();
                                                return this.downloadCampaignSummary();
                                            }}
                                            >
                                            <div className="nav-link-icon">
                                                <FontAwesomeIcon
                                                icon={['far', 'file-pdf']}
                                                />
                                            </div>
                                            <span>Campaign Summary</span>
                                            </NavLink>
                                        </NavItem>
                                    }
                                    
                                    </Nav>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    }
                    {/* End of Campaign Options */}
                    

                    {/*View Campaign Detail */}
                    {
                        allowToPerformOperations &&
                        <div className="d-none">
                            <Button 
                                color="primary"
                                className="m-2" 
                                block
                                name="deadlineType"
                                value="select-deadline"
                            >
                                <span className="btn-wrapper--label">View Ad Detail</span>
                            </Button>
                        </div>
                    }
                    {/* End of Request New */}

                    {
                        allowToPerformOperations &&
                        <div className="d-block d-sm-none">
                            <UncontrolledDropdown className="mx-3">
                                <DropdownToggle 
                                    color="secondary" 
                                    className="d-none border bg-white" 
                                    caret
                                >
                                    <span className="btn-wrapper--label pr-2">Campaign Options</span>
                                </DropdownToggle>

                                <DropdownToggle
                                    color="link"
                                    className="btn-link-second p-0 font-size-xl text-muted no-caret d-block"
                                >
                                    <FontAwesomeIcon
                                        icon={['fas', 'ellipsis-v']}
                                        className="font-size-lg"
                                    />
                                </DropdownToggle>
                                
                                <DropdownMenu right className="dropdown-menu-xl p-0">
                                    <div className="scroll-area-sm shadow-overflow">
                                    <PerfectScrollbar option={{ wheelPropagation: false }}>
                                        <Nav className=" flex-column py-2">
                                        {
                                            (itemCounts[CAMPAIGN_DETAILS_TAB_PUBLISHED] > 0) &&     
                                            <NavItem>
                                                <NavLink 
                                                    className="text-black-50"
                                                    href="#" 
                                                    onClick={e =>{
                                                        e.preventDefault();
                                                        return this.processStopAdsForCampaign();
                                                    }}
                                                >
                                                <div className="nav-link-icon">
                                                    <FontAwesomeIcon icon={['far', 'chart-bar']} />
                                                </div>
                                                <span>Stop all Ads</span>
                                                </NavLink>
                                            </NavItem>
                                        }
                                        <NavItem>
                                            <NavLink
                                            className="text-black-50"
                                            href="#"
                                            onClick={e => e.preventDefault()}
                                            >
                                            <div className="nav-link-icon">
                                                <FontAwesomeIcon
                                                icon={['far', 'question-circle']}
                                                />
                                            </div>
                                            <span>Request a change</span>
                                            </NavLink>
                                        </NavItem>

                                        <NavItem className="border-bottom">
                                            <NavLink
                                            className="text-black-50"
                                            href="#"
                                            onClick={e => e.preventDefault()}
                                            >
                                            <div className="nav-link-icon">
                                                <FontAwesomeIcon
                                                icon={['far', 'question-circle']}
                                                />
                                            </div>
                                            <span>Add New Publication</span>
                                            </NavLink>
                                        </NavItem>

                                        </Nav>
                                    </PerfectScrollbar>
                                    </div>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    }
                
                </div>
            </div>
            </Fragment>
    );
    }
}

const mapStateToProps = (state,ownProps) => ({
    activeView:getActiveView(state),
    campaign:getCampaign(ownProps.urlParameters.id)(state),
    campaignDetails:getCampaignDetails(ownProps.urlParameters.id)(state),
    clientAccountName:getActiveClientAccount(state).name || "",
    headerShadow: state.ThemeOptions.headerShadow,
    headerBgTransparent: state.ThemeOptions.headerBgTransparent,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
    downloadCampaignSummary:(campaignObject)=>dispatch(downloadCampaignSummary(campaignObject)),
    setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
    stopAdForCampaignItemList:(campaignItemObject)=>dispatch(stopAdForCampaignItemsList(campaignItemObject)),


});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCampaignDetail);
