import { combineReducers } from "redux";

import uiMyCampaignViewReducer from "./ui-campaigns";
import uiMyCampaignDetailViewReducer from "./ui-campaign-details";
import uiPendingQuoteTabViewReducer from "./ui-pending-quote";

import uiDashboardReducer from "./ui-dashboard";

import uiForgotPasswordViewReducer from "./ui-forgot-password";

import uiInvoiceViewReducer from "./ui-invoices";

import uiLoginScreenReducer from "./ui-login-screen";

import uiResetPasswordScreenReducer from "./ui-reset-password";

import uiManageAccountViewReducer from "./ui-manage-account";

import jeAdminSelectUserViewReducer from "./ui-select-user";

import uiRequestQuoteReducer from "./ui-request-quote";




export default combineReducers({
    dashboard:uiDashboardReducer,
    forgotPasswordScreen:uiForgotPasswordViewReducer,
    manageAccountView:uiManageAccountViewReducer,
    myCampaignView:uiMyCampaignViewReducer,
    myCampaignDetailView:uiMyCampaignDetailViewReducer,
    myCampaignPendingQuoteView:uiPendingQuoteTabViewReducer,
    invoiceView:uiInvoiceViewReducer,
    jeAdminSelectUserView:jeAdminSelectUserViewReducer,
    loginScreenView:uiLoginScreenReducer,
    requestQuoteView:uiRequestQuoteReducer,
    resetPasswordScreenView:uiResetPasswordScreenReducer,
})