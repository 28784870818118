import React, {Fragment, useState, Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Row, Table, Col, CardBody, Card, CardTitle, CardHeader, Collapse, UncontrolledPopover, Container, InputGroup, InputGroupAddon, InputGroupText, FormText, Form, CustomInput, Label, FormGroup, Input, Badge, ListGroup, ListGroupItem, PopoverHeader, PopoverBody, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, Progress, DropdownToggle, DropdownMenu, Alert, DropdownItem } from 'reactstrap'

import {connect} from 'react-redux';

import HeaderJeLogoBox from "../HeaderJeLogoBox"


class HeaderRequestQuote extends Component {

    constructor(props) {
        super(props);
    
        
        this.state = {
          activeTab: '1',
    
         
        };
      }
    
      

    render() {
        let {
            headerShadow,
            headerBgTransparent,
            useHistory
        } = this.props;

        return (
            <Fragment>
                <div className={cx("app-header", {'app-header--shadow': headerShadow, 'app-header--opacity-bg': headerBgTransparent})}>
                <div className="app-header--pane">

                    <div className="mt-2">
                    <a href="/Dashboard" 
                      onClick={(e)=>{
                        e.preventDefault();
                        return useHistory.push("/Dashboard")
                      }}
                      alt="Back to Homepage"
                      title="Back to Homepage"
                      
                    >
                        <i className="pe-7s-home dashboard-icon"></i>
                    </a>
                    </div>
                    <HeaderJeLogoBox />
                </div>
                <div className="app-header--pane">
                    {/*Request New */}
                    <div className="d-none">
                        <UncontrolledDropdown className="mx-3">
                            <DropdownToggle color="primary" caret>
                                <span className="btn-wrapper--label pr-2">Request</span>
                            </DropdownToggle>
                            <DropdownMenu right className="dropdown-menu-xl p-0">
                                <div className="scroll-area-sm shadow-overflow">
                                <PerfectScrollbar option={{ wheelPropagation: false }}>
                                    <Nav className=" flex-column py-2">
                                    <NavItem>
                                        <NavLink href="#" onClick={e => e.preventDefault()}>
                                        <div className="nav-link-icon">
                                            <FontAwesomeIcon icon={['far', 'chart-bar']} />
                                        </div>
                                        <span>New Job Campaign Quote</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                        href="#"
                                        onClick={e => e.preventDefault()}
                                        >
                                        <div className="nav-link-icon">
                                            <FontAwesomeIcon
                                            icon={['far', 'question-circle']}
                                            />
                                        </div>
                                        <span>Search Tearsheets</span>
                                        </NavLink>
                                    </NavItem>
                                    
                                    </Nav>
                                </PerfectScrollbar>
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    
                    {/* End of Request New */}


                                       
                </div>
            </div>
            </Fragment>
    );
    }
}

const mapStateToProps = state => ({
    headerShadow: state.ThemeOptions.headerShadow,
    headerBgTransparent: state.ThemeOptions.headerBgTransparent,
});



export default connect(mapStateToProps)(HeaderRequestQuote);
