import { createSlice } from "@reduxjs/toolkit";
import {createSelector} from "reselect";
import _ from "lodash";


//-------Slice: Create reducers and actions--------
const slice  = createSlice({
    name:"forgotPasswordScreenView",
    initialState:{
        resetPasswordFlag:false,
        data:{},
        errors:[],
        loading:false,
    },
    reducers:{
        errorUICleared:(forgotPasswordScreenView,action)=>{
            forgotPasswordScreenView.resetPasswordFlag = false;
            forgotPasswordScreenView.errors = [];
            forgotPasswordScreenView.loading = false;
        },
        errorUIDisplayed:(forgotPasswordScreenView,action)=>{
            const {error} = action.payload;
            forgotPasswordScreenView.errors = error.list;
            forgotPasswordScreenView.loading = false;
        },
        forgotPasswordUIRestPasswordSaved:(forgotPasswordScreenView,action)=>{
            forgotPasswordScreenView.resetPasswordFlag = true;
            forgotPasswordScreenView.loading = false;
        },
        loadingIndicatorUIStart:(forgotPasswordScreenView,action)=>{
            forgotPasswordScreenView.loading = true;
        },
        loadingIndicatorUIEnd:(forgotPasswordScreenView,action)=>{
            forgotPasswordScreenView.loading = false;
        },
    }

});

//--------Export reducers and actions-----------------
export const {
    errorUICleared,
    errorUIDisplayed,
    forgotPasswordUIRestPasswordSaved,
    loadingIndicatorUIStart,
    loadingIndicatorUIEnd,
} = slice.actions;

export default slice.reducer;

//---------Action creators-------------------------------

export const clearErrors = ()=>dispatch=>{
    dispatch(errorUICleared())
}

//----------Get selectors------------------------------
export const getProcessingIndicatorFlag = createSelector(
    state=>state.ui.forgotPasswordScreen,
    forgotPasswordScreen=>forgotPasswordScreen.loading
)


export const getServerErrors = createSelector(
    state=>state.ui.forgotPasswordScreen,
    forgotPasswordScreen=> forgotPasswordScreen.errors
)

export const getResetPasswordStatusFlag = createSelector(
    state=>state.ui.forgotPasswordScreen,
    forgotPasswordScreen=>forgotPasswordScreen.resetPasswordFlag
)


