import React, { lazy, Suspense, Fragment, useEffect } from 'react';
import {connect} from "react-redux";
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from "framer-motion";
import {ClimbingBoxLoader} from 'react-spinners';

//services
import auth from "./services/authService";

//Load UI action creators and selectors
import { 
    isAccessControlListPopulated,
    isActiveClientAccountSelected,
    isActiveUserIdSelected,
    isUserLoggedIn,
    setAccessControlListForUser,
    setLoggedInUserPopulatedFromLocalStorage,
} from "./store/auth";
// End of loading action creators and selectors

import AccessControl from "./components/Authentication/AccessControl"
import ProtectedRoute from "./components/Authentication/ProtectedRoute"
import Logout from "./components/Authentication/Logout"


// Layout Blueprints
import {
LeftSidebar,
LeftSidebarJe,
LeftSidebarNoHeader,
CollapsedSidebar,
MinimalLayout,
PresentationLayout,
HeaderFooterLayout
} from './layout-blueprints';



const Dashboard = lazy(() => import('./pages/Dashboard'));
const Campaign = lazy(()=>import('./pages/Campaign'));
const CampaignDetail = lazy(()=>import('./pages/CampaignDetail'));
const CampaignDetailItem = lazy(()=>import('./pages/CampaignDetailItem'));
const Candidate = lazy(()=>import('./pages/Candidate'));
const CandidateReport = lazy(()=>import('./pages/CandidateReport'));
const CandidateQuestionnaire = lazy(()=>import('./pages/CandidateQuestionnaire'));
const Contract = lazy(()=>import('./pages/Contracts'));
const ForgotPassword = lazy(() => import('./components/Authentication/ForgotPassword'));
const ManageAccount  = lazy(()=>import('./pages/ManageAccount'));
const RequestQuote = lazy(()=>import('./pages/RequestQuote'))
const RequestQuoteManualAddPublication = lazy(()=>import('./components/RequestQuote/FriendlyFormSteps/Step10/ManalAddPublication'))
const Invoices = lazy(()=>import('./pages/Invoices'));
const InvoiceDetail = lazy(()=>import('./pages/InvoiceDetail'));
const Login = lazy(() => import('./pages/Login'));
const ResetPassword = lazy(() => import('./components/Authentication/ResetPassword'));
const SelectAccount = lazy(() => import('./components/Authentication/SelectAccount'));
const SelectUser = lazy(() => import('./components/Authentication/SelectUser'));
const UserAlerts = lazy(()=>import('./pages/UserAlerts'));


const Routes = (props) => {
const {
    isAccessControlListPopulated,
    isActiveClientAccountSelected,
    isActiveUserIdSelected,
    isUserLoggedIn,
    loadAccessControlListForUser,
    setLoggedInUserPopulatedFromLocalStorage
} = props;

const location = useLocation();

const pageVariants = {
    initial: {
        opacity: 0,
        scale: 0.99
    },
    in: {
        opacity: 1,
        scale: 1
    },
    out: {
        opacity: 0,
        scale: 1.01
    }
};

const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.4
};

const SuspenseLoading = () => {
    return (
    <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
            <div className="d-flex align-items-center flex-column px-4">
                <ClimbingBoxLoader color={"#3c44b1"} loading={true} />
            </div>
            <div className="text-muted font-size-xl text-center pt-3">
                Loading .... Please wait 
            </div>
        </div>
    </Fragment>
    )
}

/*
    We need to get jwt local storage
*/
useEffect(() => {
    
    if(!isUserLoggedIn) setLoggedInUserPopulatedFromLocalStorage();
    if(!isAccessControlListPopulated && 
        isActiveClientAccountSelected && 
        isActiveUserIdSelected
    ) loadAccessControlListForUser()

});

return (
<AnimatePresence>
    <Suspense fallback={<SuspenseLoading/>}>
        <Switch>
            <Route path="/logout" component={Logout} />             
                    
            <Route path={[  "/Login","/forgot-password"  ]}>
                <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                    <motion.div
                            initial="initial"
                            animate="in"
                            exit="out"
                            variants={pageVariants}
                            transition={pageTransition}
                    >
                    <Route path="/Login" component={ Login } />
                    <Route path="/forgot-password" component={ ForgotPassword } />
                    </motion.div>
                </Switch>
                </MinimalLayout>
            </Route>

            <ProtectedRoute
                path="/reset-password"
                title="Reset Password"
                render={props =><MinimalLayout>
                                    <Switch location={location} key={location.pathname}>
                                        <motion.div
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}
                                                transition={pageTransition}
                                        >
                                            <Route path="/reset-password" component={ ResetPassword } />
                                        </motion.div>
                                    </Switch>
                            </MinimalLayout>}
                
            />

            <ProtectedRoute
                path="/select-account"
                title="Select Account"
                render={props =><MinimalLayout>
                                    <Switch location={location} key={location.pathname}>
                                        <motion.div
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}
                                                transition={pageTransition}
                                        >
                                            <Route path="/select-account" component={ SelectAccount } />
                                        </motion.div>
                                    </Switch>
                            </MinimalLayout>}
                
            />

            <ProtectedRoute
                path="/select-user"
                title="Select User"
                render={props =><MinimalLayout>
                                    <Switch location={location} key={location.pathname}>
                                        <motion.div
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}
                                                transition={pageTransition}
                                        >
                                            <Route path="/select-user" component={ SelectUser } />
                                        </motion.div>
                                    </Switch>
                                </MinimalLayout>
                }
                
            /> 

            <ProtectedRoute
                path="/Dashboard"
                title="Dashboard"
                render={props =><LeftSidebarJe header="Dashboard">
                                    <Switch location={location} key={location.pathname}>
                                        <motion.div
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}
                                                transition={pageTransition}
                                        >
                                            <Route path="/Dashboard" component={ Dashboard } />
                                        </motion.div>
                                    </Switch>
                                </LeftSidebarJe>
                }
                
            />

            <ProtectedRoute
                path="/Notifications"
                title="Notifications"
                render={props =><LeftSidebarJe header="Notifications">
                                    <Switch location={location} key={location.pathname}>
                                        <motion.div
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}
                                                transition={pageTransition}
                                        >
                                            <Route path="/Notifications" component={ UserAlerts } />
                                        </motion.div>
                                    </Switch>
                                </LeftSidebarJe>
                }
                
            />  

            <ProtectedRoute
                path="/Contracts"
                title="Contracts"
                render={props =><LeftSidebarJe header="Contract">
                                    <Switch location={location} key={location.pathname}>
                                        <motion.div
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}
                                                transition={pageTransition}
                                        >
                                            <AccessControl
                                                allow={["manageContracts"]}
                                            >
                                                <Route path="/Contracts" component={ Contract } />
                                            </AccessControl>
                                        </motion.div>
                                    </Switch>
                                </LeftSidebarJe>
                }
                
            />

            <ProtectedRoute
                path={["/request-new-quote/add-publication-manual/:quoteType/:pendingQuoteId/:pendingQuoteItemId?"]}
                title="Request new quote - Add Manual Publication"
                render={props =><MinimalLayout>
                                    <Switch location={location} key={location.pathname}>
                                        <motion.div
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}
                                                transition={pageTransition}
                                        >
                                            <AccessControl
                                                allow={["manageRequestQuote"]}
                                            >
                                                <Route path="/request-new-quote/add-publication-manual/:quoteType/:pendingQuoteId/:pendingQuoteItemId?" component={ RequestQuoteManualAddPublication } />
                                            </AccessControl>
                                        </motion.div>
                                    </Switch>
                                </MinimalLayout>
                }
            /> 

            <ProtectedRoute
                path={["/request-new-quote/:step?"]}
                title="Request new quote"
                render={props =><HeaderFooterLayout header="RequestQuote">
                                    <Switch location={location} key={location.pathname}>
                                        <AccessControl
                                            allow={["manageRequestQuote"]}
                                        >
                                            <Route path="/request-new-quote/:step?/:id?" component={ RequestQuote } />
                                        </AccessControl>
                                    </Switch>
                                </HeaderFooterLayout>
                }
                
            />

            <ProtectedRoute
                path={["/candidates/view-candidates/:action?/:secondaryAction?"]}
                title="Questionnaire - View Candidates"
                render={props =><LeftSidebarJe header="Candidate" subMenu="view-candidates">
                                    <Switch location={location} key={location.pathname}>
                                        <motion.div
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}
                                                transition={pageTransition}
                                        >
                                            <AccessControl
                                                allow={["manageCandidates"]}
                                            >
                                                <Route path="/candidates/view-candidates/:action?/:secondaryAction?" component={ Candidate } />
                                            </AccessControl>
                                        </motion.div>
                                    </Switch>
                                </LeftSidebarJe>
                }
                
            />

            <ProtectedRoute
                path={["/candidates/reports/:action?/:secondaryAction?"]}
                title="Questionnaire - Reports & Exports"
                render={props =><LeftSidebarJe header="Candidate" subMenu="reports">
                                    <Switch location={location} key={location.pathname}>
                                        <motion.div
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}
                                                transition={pageTransition}
                                        >
                                            <AccessControl
                                                allow={["manageCandidates"]}
                                            >
                                                <Route path="/candidates/reports/:action?/:secondaryAction?" component={ CandidateReport } />
                                            </AccessControl>
                                        </motion.div>
                                    </Switch>
                                </LeftSidebarJe>
                }
                
            />

            <ProtectedRoute
                path={["/candidates/questionnaires/:action?/:secondaryAction?/:id?"]}
                title="Questionnaire setup"
                render={props =><LeftSidebarJe header="Candidate" subMenu="questionnaire">
                                    <Switch location={location} key={location.pathname}>
                                        <motion.div
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}
                                                transition={pageTransition}
                                        >
                                            <AccessControl
                                                allow={["manageCandidates"]}
                                                customExecution={"accessToQuestionnaireSetup"}
                                            >
                                                <Route path="/candidates/questionnaires/:action?/:secondaryAction?/:id?" component={ CandidateQuestionnaire } />
                                            </AccessControl>
                                        </motion.div>
                                    </Switch>
                                </LeftSidebarJe>
                }
                
            />

            
            <ProtectedRoute
                path={["/manage-account/:view?/:id?"]}
                title="Manage Accounts"
                render={props =><LeftSidebarNoHeader header="ManageAccount">
                                    <Switch location={location} key={location.pathname}>
                                        <motion.div
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}
                                                transition={pageTransition}
                                        >   
                                            <Route 
                                                path={[
                                                    "/manage-account/:view?/:id?",
                                                ]} 
                                                component={ ManageAccount } 
                                            />
                                        </motion.div>
                                    </Switch>
                                </LeftSidebarNoHeader>
                }
                
            />
            

            <ProtectedRoute
                path="/my-campaigns"
                title="Campaigns"
                render={props =><LeftSidebarJe header="Campaign">
                                    <Switch location={location} key={location.pathname}>
                                        <motion.div
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}
                                                transition={pageTransition}
                                        >
                                            <AccessControl
                                                allow={["manageCampaigns"]}
                                            >
                                                <Route path="/my-campaigns" component={ Campaign } />
                                            </AccessControl>
                                        </motion.div>
                                    </Switch>
                                </LeftSidebarJe>
                }
                
            />  

            <ProtectedRoute
                path="/campaign-detail/:id/:view?"
                title="Campaign Details"
                render={props =><LeftSidebarJe header="CampaignDetail">
                                    <Switch location={location} key={location.pathname}>
                                        <motion.div
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}
                                                transition={pageTransition}
                                        >
                                            <AccessControl
                                                allow={["manageCampaigns"]}
                                            >
                                                <Route path="/campaign-detail/:id/:view?" component={ CampaignDetail } />
                                            </AccessControl>    
                                        </motion.div>
                                    </Switch>
                                </LeftSidebarJe>
                }
                
            /> 

            {
                /*
                /campaign-detail-item/:campaignId/:campaignItemId/review-artwork 
                /campaign-detail-item/:campaignId/:campaignItemId/modify-ad 
                */
            }  
             <ProtectedRoute
                path="/campaign-detail-item/:campaignId/:campaignItemId/:view?"
                title="Campaign Detail Item"
                render={props =><LeftSidebarJe header="CampaignDetailItem">
                                    <Switch location={location} key={location.pathname}>
                                        <motion.div
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}
                                                transition={pageTransition}
                                        >
                                            <AccessControl
                                                allow={["manageCampaigns"]}
                                            >
                                                <Route path="/campaign-detail-item/:campaignId/:campaignItemId/:view?" component={ CampaignDetailItem } />
                                            </AccessControl>
                                        </motion.div>
                                    </Switch>
                                </LeftSidebarJe>
                }
                
            />  

            <ProtectedRoute
                path="/invoices"
                title="Invoices"
                render={props =><LeftSidebarJe header="Invoice">
                                    <Switch location={location} key={location.pathname}>
                                        <motion.div
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}
                                                transition={pageTransition}
                                        >
                                            <AccessControl
                                                allow={["manageInvoices"]}
                                            >
                                                <Route path="/invoices" component={ Invoices } />
                                            </AccessControl>
                                        </motion.div>
                                    </Switch>
                                </LeftSidebarJe>
                }
                
            /> 

            <ProtectedRoute
                path="/invoice-detail/:id/:status?"
                title="Invoice Detail"
                render={props =><LeftSidebarJe header="InvoiceDetail" >
                                    <Switch location={location} key={location.pathname}>
                                        <motion.div
                                                initial="initial"
                                                animate="in"
                                                exit="out"
                                                variants={pageVariants}
                                                transition={pageTransition}
                                        >
                                            <AccessControl
                                                allow={["manageInvoices"]}
                                            >
                                                <Route path="/invoice-detail/:id/:status?" component={ InvoiceDetail } />
                                            </AccessControl>
                                        </motion.div>
                                    </Switch>
                                </LeftSidebarJe>
                }
                
            /> 

            <Redirect exact from="/index" to="/login" />    
            <Redirect exact from="/" to="/login" />
            <Redirect to="/index" />
            
        </Switch>
    </Suspense>
</AnimatePresence>
    );
};


const mapStateToProps = state=>({
    isUserLoggedIn:isUserLoggedIn(state),
    isAccessControlListPopulated:isAccessControlListPopulated(state),
    isActiveClientAccountSelected:isActiveClientAccountSelected(state),
    isActiveUserIdSelected:isActiveUserIdSelected(state),
});
  
const mapDispatchToProps = (dispatch)=>({
    setLoggedInUserPopulatedFromLocalStorage:()=>dispatch(setLoggedInUserPopulatedFromLocalStorage()),
    loadAccessControlListForUser:()=>dispatch(setAccessControlListForUser()),

});
  
export default connect(mapStateToProps,mapDispatchToProps)(Routes);
  
  
