import React, { Fragment, Component } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { connect } from 'react-redux';
import RouterLink from '../ReactMetismenuRouterLink';

import MetisMenu from 'react-metismenu';

import { SidebarUserbox } from '../../layout-components';

const sidebarMenuContent = [
  {
    label: 'Dashboards',
    icon: 'pe-7s-safe',
    content: [
      {
        label: 'Default',
        to: '/DashboardDefault'
      },
      {
        label: 'Analytics',
        to: '/DashboardAnalytics'
      },
      {
        label: 'Sales Management',
        to: '/DashboardSales'
      },
      {
        label: 'Reports',
        to: '/DashboardReports'
      },
      {
        label: 'Real Estate',
        to: '/DashboardRealEstate'
      },
      {
        label: 'Server Status',
        to: '/DashboardServerStatus'
      },
      {
        label: 'Projects',
        to: '/DashboardProjects'
      },
      {
        label: 'Helpdesk',
        to: '/DashboardHelpdesk'
      },
      {
        label: 'Customers',
        to: '/DashboardCustomers'
      },
      {
        label: 'Cryptocurrency',
        to: '/DashboardCryptocurrency'
      },
      {
        label: 'CRM Manager',
        to: '/DashboardCrmManager'
      },
      {
        label: 'Content Statistics',
        to: '/DashboardContent'
      }
    ]
  },
  {
    label: 'Applications',
    icon: 'pe-7s-network',
    content: [
      {
        label: 'Calendar',
        to: '/ApplicationsCalendar'
      },
      {
        label: 'Chat',
        to: '/ApplicationsChat'
      },
      {
        label: 'Contacts',
        to: '/ApplicationsContacts'
      },
      {
        label: 'File Manager',
        to: '/ApplicationsFileManager'
      },
      {
        label: 'Mail',
        to: '/ApplicationsMail'
      },
      {
        label: 'Projects',
        to: '/ApplicationsProjects'
      },
      {
        label: 'App Widgets',
        to: '/ApplicationsWidgets'
      }
    ]
  },
  {
    label: 'Pages',
    icon: 'pe-7s-portfolio',
    content: [
      {
        label: 'Login',
        to: '/PagesLogin'
      },
      {
        label: 'Register',
        to: '/PagesRegister'
      },
      {
        label: 'Recover Password',
        to: '/PagesRecoverPassword'
      },
      {
        label: 'Profile',
        to: '/PagesProfile'
      },
      {
        label: 'Invoice',
        to: '/PagesInvoice'
      },
      {
        label: 'Error 404',
        to: '/PagesError404'
      },
      {
        label: 'Error 500',
        to: '/PagesError500'
      },
      {
        label: 'Error 505',
        to: '/PagesError505'
      }
    ]
  },
  {
    label: 'Elements',
    icon: 'pe-7s-keypad',
    content: [
      {
        label: 'Buttons',
        to: '/Buttons'
      },
      {
        label: 'Dropdowns',
        to: '/Dropdowns'
      },
      {
        label: 'Navigation menus',
        to: '/NavigationMenus'
      },
      {
        label: 'Avatars',
        icon: 'icon',
        to: '/Avatars'
      },
      {
        label: 'Progress Bars',
        to: '/ProgressBars'
      },
      {
        label: 'Ratings',
        to: '/Ratings'
      },
      {
        label: 'Pagination',
        to: '/Pagination'
      },
      {
        label: 'Ribbons',
        to: '/Ribbons'
      },
      {
        label: 'Scrollable',
        to: '/Scrollable'
      },
      {
        label: 'Search Bars',
        to: '/SearchBars'
      },
      {
        label: 'Badges',
        to: '/Badges'
      },
      {
        label: 'Icons',
        to: '/Icons'
      },
      {
        label: 'Timelines',
        to: '/Timelines'
      },
      {
        label: 'Utilities & Helpers',
        to: '/UtilitiesHelpers'
      }
    ]
  },
  {
    label: 'Cards',
    icon: 'pe-7s-box2',
    content: [
      {
        label: 'Cards examples 1',
        to: '/Cards1'
      },
      {
        label: 'Cards examples 2',
        to: '/Cards2'
      },
      {
        label: 'Cards examples 3',
        to: '/Cards3'
      },
      {
        label: 'Cards examples 4',
        to: '/Cards4'
      },
      {
        label: 'Cards examples 5',
        to: '/Cards5'
      },
      {
        label: 'Cards examples 6',
        to: '/Cards6'
      },
      {
        label: 'Cards examples 7',
        to: '/Cards7'
      },
      {
        label: 'Cards examples 8',
        to: '/Cards8'
      },
      {
        label: 'Cards examples 9',
        to: '/Cards9'
      },
      {
        label: 'Cards examples 10',
        to: '/Cards10'
      },
      {
        label: 'Cards examples 11',
        to: '/Cards11'
      },
      {
        label: 'Cards examples 12',
        to: '/Cards12'
      }
    ]
  },
  {
    label: 'List Groups',
    icon: 'pe-7s-id',
    to: '/ListGroups'
  },
  {
    label: 'Presentation Blocks',
    icon: 'pe-7s-box2',
    content: [
      {
        label: 'Landing page',
        to: '/LandingPage'
      },
      {
        label: 'Feature sections',
        to: '/FeatureSections'
      },
      {
        label: 'Icon Boxes',
        to: '/IconBoxes'
      },
      {
        label: 'Pricing Tables',
        to: '/PricingTables'
      },
      {
        label: 'Header sections',
        to: '/HeaderSections'
      },
      {
        label: 'Testimonials sections',
        to: '/TestimonialsSections'
      },
      {
        label: 'Blog sections',
        to: '/BlogSections'
      },
      {
        label: 'Partners sections',
        to: '/PartnersSections'
      },
      {
        label: 'Call to action boxes',
        to: '/CtaBoxes'
      },
      {
        label: 'Footer sections',
        to: '/FooterSections'
      },
      {
        label: 'Hero sections',
        to: '/HeroSections'
      }
    ]
  },
  {
    label: 'Widgets',
    icon: 'pe-7s-display2',
    content: [
      {
        label: 'Accordions',
        to: '/Accordions'
      },
      {
        label: 'Modal dialogs',
        to: '/Modals'
      },
      {
        label: 'Notifications',
        to: '/Notifications'
      },
      {
        label: 'Drag & Drop',
        to: '/DragDrop'
      },
      {
        label: 'Calendars',
        to: '/Calendars'
      },
      {
        label: 'Carousels',
        to: '/Carousels'
      },
      {
        label: 'Popovers',
        to: '/Popovers'
      },
      {
        label: 'Tooltips',
        to: '/Tooltips'
      },
      {
        label: 'Tree View',
        to: '/TreeView'
      },
      {
        label: 'Tabs',
        to: '/Tabs'
      },
      {
        label: 'Guided Tours',
        to: '/GuidedTours'
      },
      {
        label: 'Image Crop',
        to: '/ImageCrop'
      },
      {
        label: 'Loading Indicators',
        to: '/LoadingIndicators'
      },
      {
        label: 'Count Up',
        to: '/CountUp'
      },
      {
        label: 'Context Menus',
        to: '/ContextMenus'
      }
    ]
  },
  {
    label: 'Regular Tables',
    icon: 'pe-7s-albums',
    content: [
      {
        label: 'Tables examples 1',
        to: '/RegularTables1'
      },
      {
        label: 'Tables examples 2',
        to: '/RegularTables2'
      },
      {
        label: 'Tables examples 3',
        to: '/RegularTables3'
      },
      {
        label: 'Tables examples 4',
        to: '/RegularTables4'
      },
      {
        label: 'Tables examples 5',
        to: '/RegularTables5'
      }
    ]
  },
  {
    label: 'Dynamic Tables',
    icon: 'pe-7s-edit',
    content: [
      {
        label: 'React Table',
        to: '/DynamicTables1'
      },
      {
        label: 'React Next Table',
        to: '/DynamicTables2'
      }
    ]
  },
  {
    label: 'Forms Elements',
    icon: 'pe-7s-menu',
    content: [
      {
        label: 'Layout',
        to: '/FormsLayout'
      },
      {
        label: 'Controls',
        to: '/FormsControls'
      },
      {
        label: 'Wizard',
        to: '/FormsWizard'
      }
    ]
  },
  {
    label: 'Forms Widgets',
    icon: 'pe-7s-tools',
    content: [
      {
        label: 'Clipboard',
        to: '/FormsClipboard'
      },
      {
        label: 'Colorpicker',
        to: '/FormsColorpicker'
      },
      {
        label: 'Datepicker',
        to: '/FormsDatepicker'
      },
      {
        label: 'Dual Listbox',
        to: '/FormsDualListbox'
      },
      {
        label: 'Input Mask',
        to: '/FormsInputMask'
      },
      {
        label: 'Input Select',
        to: '/FormsInputSelect'
      },
      {
        label: 'Slider',
        to: '/FormsSlider'
      },
      {
        label: 'Textarea Autosize',
        to: '/FormsTextareaAutosize'
      },
      {
        label: 'Timepicker',
        to: '/FormsTimepicker'
      },
      {
        label: 'Toggle Switch',
        to: '/FormsToggleSwitch'
      },
      {
        label: 'Typeahead',
        to: '/FormsTypeahead'
      },
      {
        label: 'Upload',
        to: '/FormsUpload'
      },
      {
        label: 'Validation',
        to: '/FormsValidation'
      },
      {
        label: 'WYSIWYG Editors',
        to: '/FormsWysiwygEditor'
      }
    ]
  },
  {
    label: 'Charts',
    icon: 'pe-7s-graph1',
    content: [
      {
        label: 'Apex Charts',
        to: '/ApexCharts'
      },
      {
        label: 'Gauges Charts',
        to: '/GaugesCharts'
      },
      {
        label: 'ChartJS',
        icon: 'icon',
        to: '/ChartjsCharts'
      },
      {
        label: 'Sparklines Charts',
        to: '/SparklinesCharts'
      }
    ]
  },
  {
    label: 'Maps',
    icon: 'pe-7s-map-2',
    to: '/Maps'
  }
];

class SidebarMenu extends Component {
  render() {
    let { sidebarUserbox } = this.props;

    return (
      <Fragment>
        <PerfectScrollbar>
          {sidebarUserbox && <SidebarUserbox />}
          <div className="sidebar-navigation">
            <div className="sidebar-header">
              <span>Navigation menu</span>
            </div>
            <MetisMenu
              content={sidebarMenuContent}
              LinkComponent={RouterLink}
              activeLinkFromLocation
              iconNamePrefix=""
              noBuiltInClassNames={true}
              classNameItemActive="active"
              classNameIcon="sidebar-icon"
              iconNameStateVisible="sidebar-icon-indicator"
              iconNameStateHidden="sidebar-icon-indicator"
              classNameItemHasVisibleChild="submenu-open"
              classNameItemHasActiveChild="active"
              classNameStateIcon="pe-7s-angle-down"
            />
          </div>
        </PerfectScrollbar>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
