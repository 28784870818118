import React, { Fragment } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import cx from 'classnames';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import {
  SidebarJe,
  HeaderAddUser,
  HeaderEditUser,
  HeaderDashboard,
  HeaderCampaign,
  HeaderCampaignDetail,
  HeaderInvoice,
  HeaderInvoiceDetail,
  HeaderManageAccount,
  Footer,
  ThemeConfigurator
} from '../../layout-components';

const getHeader = (headerType,urlParams,history)=>{
  //console.log(urlParams);
  const {view} = urlParams
  
  switch(view){
    case "add-user":
      return <HeaderAddUser history = {history}/>
    case "edit-user":
       return <HeaderEditUser history = {history}/>
        
    
    default:return <HeaderManageAccount  history = {history}/>
      
  }
}

const LeftSidebarNoHeader = props => {
  const {
    children,
    sidebarToggle,
    sidebarToggleMobile,
    sidebarFixed,
    header,
    headerFixed,
    headerSearchHover,
    headerDrawerToggle,
    footerFixed,
    contentBackground,
    themeConfiguratorToggle
  } = props;

  const urlParams = useParams();
  const useHistoryProps = useHistory();

  return (
    <Fragment>
      <div
        className={cx('app-wrapper', contentBackground, {
          'header-drawer-open': headerDrawerToggle,
          'app-sidebar-collapsed': sidebarToggle,
          'app-sidebar-mobile-open': sidebarToggleMobile,
          'app-sidebar-fixed': sidebarFixed,
          'app-header-fixed': headerFixed,
          'app-footer-fixed': footerFixed,
          'search-wrapper-open': headerSearchHover
        })}>
        <div>
          <SidebarJe useHistory={useHistoryProps} header={header} />
        </div>
        <div className="app-main">
          {getHeader(header,urlParams,useHistoryProps)}
          <div className="app-content">
            <div className="app-content--inner pt-1">
              <div className="app-content--inner__wrapper">{children}</div>
            </div>
          </div>
        </div>
        <ThemeConfigurator />
      </div>
    </Fragment>
  );
};

LeftSidebarNoHeader.propTypes = {
  header:PropTypes.string.isRequired,
  children: PropTypes.node
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,

  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

  footerFixed: state.ThemeOptions.footerFixed,

  contentBackground: state.ThemeOptions.contentBackground,
  themeConfiguratorToggle: state.ThemeOptions.themeConfiguratorToggle
});

export default connect(mapStateToProps)(LeftSidebarNoHeader);
