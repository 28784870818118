import applicationConstants from "../store/applicationConstants"
const {
    INVOICE_STATUS_OPEN,
    INVOICE_STATUS_CLOSED,
} = applicationConstants

export const scrollTop = (domArea)=>{
    document.getElementById(domArea).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
    });
};

/*
return {
    address:
    city:
    state:
    zip:
    country:
}
*/
const parseAddress = address=>{
    let returnData = "";
    let returnAddress = null;
    let [addressLine1,city,state,zip,country] = ["","","","",""];
    let addressValid = false;
    if(address){
        const explodedAddress = address.split(",");
        
        if(explodedAddress.length === 4){
            explodedAddress.forEach((value,index)=>explodedAddress[index] = value.trim())

            returnAddress = {};

            addressLine1 = explodedAddress[0]
            city = explodedAddress[1]
            country = explodedAddress[3]
            
            //get the state and postal code
            explodedAddress[2] = explodedAddress[2].replace(/\s\s+/g, ' ');
            const stateZipExploded = explodedAddress[2].split(" ");
            if(stateZipExploded.length === 2){
               state = stateZipExploded[0]
               zip = stateZipExploded[1]
               addressValid = true;
            }
            else if(stateZipExploded.length === 1){
                state = explodedAddress[2]
            }
            else if(stateZipExploded.length > 2){
                state = stateZipExploded[0];
                stateZipExploded.splice(0,1)
                zip = stateZipExploded.join(" ");
                addressValid = true;
            }
            
            returnAddress = {
                addressLine1,city,state,zip,country
            }
        }else if(explodedAddress.length !== 4 && explodedAddress.length > 0){
            //Basically we have incorrect format so we just populate the values and return as false
            explodedAddress.forEach((value,index)=>explodedAddress[index] = value.trim())

            explodedAddress.forEach((value,index)=>{
                switch(index){
                    case 0:addressLine1=value; break;
                    case 1:city=value; break;
                    case 2:state=value; break;
                    case 3:zip=value; break;
                    case 4:country=value; break;
                }
            });

            addressValid = false;
            returnAddress = {
                addressLine1,city,state,zip,country
            }
        }


    }

    return {
        address:returnAddress,
        isAddressValid:addressValid,
    };
}

/*
   <<Address>>, city, state postalcode, country
 
*/
export const parseAddressForValidation = address =>{

  const addressObject = parseAddress(address);

  return addressObject

}
