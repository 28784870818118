import React, {Fragment, useState, Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Row, Table, Col, CardBody, Card, CardTitle, CardHeader, Collapse, UncontrolledPopover, Container, InputGroup, InputGroupAddon, InputGroupText, FormText, Form, CustomInput, Label, FormGroup, Input, Badge, ListGroup, ListGroupItem, PopoverHeader, PopoverBody, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, Progress, DropdownToggle, DropdownMenu, Alert, DropdownItem } from 'reactstrap'

import {connect} from 'react-redux';

import {
    setSidebarToggleMobile
} from '../../reducers/ThemeOptions';

import {
    getActiveView,
    getActiveClientAccount,
} from '../../store/auth'




import HeaderDots from "../../layout-components/HeaderDots";
import HeaderDrawer from "../../layout-components/HeaderDrawer";
import HeaderUserbox from "../../layout-components/HeaderUserbox";
import HeaderSearch from "../../layout-components/HeaderSearch";
import HeaderMenu from "../../layout-components/HeaderMenu";

import avatar1 from '../../assets/images/avatars/avatar1.jpg';
import avatar2 from '../../assets/images/avatars/avatar2.jpg';
import avatar6 from '../../assets/images/avatars/avatar6.jpg';
import avatar7 from '../../assets/images/avatars/avatar7.jpg';
import people1 from '../../assets/images/stock-photos/people-1.jpg';
import people3 from '../../assets/images/stock-photos/people-3.jpg';

import { Grid, Users, Bell } from 'react-feather';
import Chart from 'react-apexcharts';


class HeaderCampaign extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          
        };
      }
    
      
    toggleSidebarMobile = () => {
        let {sidebarToggleMobile, setSidebarToggleMobile} = this.props;
        setSidebarToggleMobile(!sidebarToggleMobile);
    }

    render() {
        let {
            activeView,
            clientAccountName,
            headerShadow,
            headerBgTransparent,
            sidebarToggleMobile
        } = this.props;

        let allowToPerformOperations = false;
        if(activeView && activeView.permissionList){
            allowToPerformOperations = activeView.permissionList.manageInvoices
        }

        return (
            <Fragment>
                <div className={cx("app-header", {'app-header--shadow': headerShadow, 'app-header--opacity-bg': headerBgTransparent})}>
                <div className="app-header--pane">
                    
                    <button className={cx("navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn", {'is-active': sidebarToggleMobile})} onClick={this.toggleSidebarMobile}>
                        <span className="hamburger-box">
                            <span className="hamburger-inner"/>
                        </span>
                    </button>
                    
                    <div className="d-none d-lg-block je-header-page-title mb-2">
                        <div className="je-header-dashboard-page-description">
                        {clientAccountName}
                        </div>
                        <div className="je-header-text-display-area text-center">
                                <h2 className="float-left je-header-dashboard-page-title">
                                    My Invoices
                                </h2>
                        </div>
                    </div>
                    
                    <div className="d-block d-lg-none je-header-page-title mb-2">
                        <div className=" text-center">
                            <div className="float-left mt-1 je-header-campaign-detail-page-title">
                                My Invoices
                            </div>
                            <div className="float-left  ml-2 text-black-50 d-block d-sm-none d-xl-block" style={{marginTop:"0.4rem"}}>
                                 
                            </div>
                            <div  className="float-left ml-2 d-block d-sm-none d-xl-block" style={{marginTop:"0.5rem"}}>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="je-header-campaign-detail-page-description">
                            <strong>{clientAccountName}</strong>
                        </div>
                        
                    </div>

                </div>
                <div className="app-header--pane">
                    {/*Update Billing Info Button */}
                    {
                        allowToPerformOperations && 
                        <div className="d-none">
                            <Button color="primary" className="mx-3">
                                Update Billing Info
                            </Button>
                        </div>
                    }
                    {/* End of Update Billing Info */}
                </div>
            </div>
            </Fragment>
    );
    }
}

const mapStateToProps = state => ({
    activeView:getActiveView(state),
    clientAccountName:getActiveClientAccount(state).name || "",
    headerShadow: state.ThemeOptions.headerShadow,
    headerBgTransparent: state.ThemeOptions.headerBgTransparent,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({

    setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),

});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCampaign);
