import React,{useEffect} from 'react';

function FrontChat(props) {
    const {license} = props
    //console.log(license)
    
    useEffect(()=>{
      setTimeout(()=>window.FrontChat( 'init', {chatId: license, useDefaultLauncher: true}),1000 )
    },[])

    return(<></>)
    
}

export default FrontChat;