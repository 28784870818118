/*
const action =
{
    type:'apiCallBegan',
    payload:{
        url:"bugs",
        method:"get",
        data:{},
        onSuccess:"bugs/",
        onError:["apiErrorFailed"],
    }
}
*/

import axios from "axios"
import { toast, Bounce, Slide, Flip, Zoom } from 'react-toastify';
import * as actions from "../api";


axios.defaults.baseURL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL;


const api = ({dispatch})=>next=> async action=>{
  if(action.type !== actions.apiCallBegan.type) return next(action);
  
  const {payload} = action;
  const {url,method,data,onStart,onSuccess,onError}  = action.payload;
  
  if(onStart  && onSuccess.length > 0){
    onStart.map(onStartAction=>{
      return dispatch({type:onStartAction,payload})
    });
  }

  next(action);

  try{
    const response = await axios.request({
        url,
        method,
        data
    });

    //General
    dispatch(actions.apiCallSuccess(response.data));
    
    //Success
    if(onSuccess && onSuccess.length > 0){
      onSuccess.map(successAction=>{
        return dispatch({type:successAction,payload:response.data})
      });
    }
  }
  catch(error){
    if(error && error.response){
      const {status:errorStatusCode,data} = error.response;
      const {error:errorList} = data;
      //console.log(status);
      //console.log(data);
      
      //General error not specific to any specific slice
      dispatch(actions.apiCallFailed(error.message))
      

      /*
        If status code 500 then custom toast
        If status code 400 || 403 then we execute this below - PENDING
      */
      //If multiple items needs to get executed then we use the onErrorHandlerList
      if(onError && onError.length > 0){
        if(errorStatusCode !== 400){
          if(data && data.error){
            const message  = data.error[0];
            
            //we are hiding the forbidden message
            if(message !== "Access denied. Forbidden access")
              toast.error(message, {containerId: 'B', transition: Flip});
          }
          else
          toast.error('We encountered an error.', {containerId: 'B', transition: Flip});
        }
        onError.map(errorAction=>{
          return dispatch(
            {
              type:errorAction,
              payload:{
                ...payload,
                message:error.message,
                error:{
                  list:errorList,
                  status:errorStatusCode
                }
              }
            }
          )})
      }
    }
    else{
      dispatch(actions.apiCallFailed(""))
      if(onError && onError.length > 0){
        
        const errorList  = [];
        if(error.message === "Network Error" && url === "/auth"){
          errorList.push("We encountered a network error. Please make sure you have disabled your Ad Blocker on your browser.")
        }

        onError.map(errorAction=>{
          return dispatch(
            {
              type:errorAction,
              payload:{
                ...payload,
                message:"",
                error:{
                  list:errorList,
                  status:403
                }
              }
            }
        )})
      }

      if(url !== "/auth")
        toast.error('Connectivity Error: We encountered an error. Contact JobElephant.', {containerId: 'B', transition: Flip});
    
    
    }
  }


}

export default api;