import React, { Fragment } from 'react';
import { useParams,useHistory } from 'react-router-dom'
import cx from 'classnames';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import {
  SidebarJe,
  HeaderDashboard,
  HeaderCampaign,
  HeaderCampaignDetail,
  HeaderCampaignDetailAddPublication,
  HeaderCampaignDetailItem,
  HeaderCampaignDetailItemReviewArtwork,
  HeaderCandidate,
  HeaderContract,
  HeaderInvoice,
  HeaderInvoiceDetail,
  HeaderUserAlert,
  Footer,
  ThemeConfigurator
} from '../../layout-components';

const getHeader = (headerType,urlParams,useHistoryProps,subMenu)=>{
  let view ="";
  
  let candidateHeaderTitle = ""
  if(headerType === "Candidate"){
    switch(subMenu){
      case "view-candidates": candidateHeaderTitle = "Candidate"; break;
      case "reports": candidateHeaderTitle = "Candidate: Reports"; break;
      case "questionnaire": candidateHeaderTitle = "Questionnaire"; break;
      default:candidateHeaderTitle = "Candidates";
    }
  }

  switch(headerType){
    case "Candidate":
      return  <HeaderCandidate 
                useHistory={useHistoryProps}
                header={candidateHeaderTitle}
              />
    case "Dashboard":
      return  <HeaderDashboard 
                useHistory={useHistoryProps}
              />
    case "Notifications":
      return  <HeaderUserAlert 
                useHistory={useHistoryProps}
              />
      
    case "Campaign":
      return  <HeaderCampaign 
                urlParameters={urlParams}
                useHistory={useHistoryProps}
              />
    
    case "CampaignDetail":
      ({view} = urlParams)
      if(view === "add-publications"){
          return  <HeaderCampaignDetailAddPublication
                      useHistory={useHistoryProps}
                      urlParameters={urlParams}
                  />
      }
      else{
          return <HeaderCampaignDetail 
                    useHistory={useHistoryProps}
                    urlParameters={urlParams}
                 />
      }
    case "CampaignDetailItem":
      ({view} = urlParams)
      if(view === "review-artwork"){
        return <HeaderCampaignDetailItemReviewArtwork 
                  urlParameters={urlParams}
                />
      }else{
        return <HeaderCampaignDetailItem 
                  urlParameters={urlParams}
                />
      }
    
    case "Contract":
     return <HeaderContract />
    
    case "Invoice":
      return <HeaderInvoice />

    case "InvoiceDetail":
        return <HeaderInvoiceDetail 
                  urlParameters={urlParams}
                  useHistory={useHistoryProps}
               />
    default:return "" 
      
  }
}

const LeftSidebarJe = props => {
  const {
    children,
    sidebarToggle,
    sidebarToggleMobile,
    sidebarFixed,
    header,
    subMenu,
    headerFixed,
    headerSearchHover,
    headerDrawerToggle,
    footerFixed,
    contentBackground,
    themeConfiguratorToggle
  } = props;

  const urlParams = useParams();
  const useHistoryProps = useHistory();
  
  return (
    <Fragment>
      <div
        className={cx('app-wrapper', contentBackground, {
          'header-drawer-open': headerDrawerToggle,
          'app-sidebar-collapsed': sidebarToggle,
          'app-sidebar-mobile-open': sidebarToggleMobile,
          'app-sidebar-fixed': sidebarFixed,
          'app-header-fixed': headerFixed,
          'app-footer-fixed': footerFixed,
          'search-wrapper-open': headerSearchHover
        })}>
        <div>
          <SidebarJe useHistory={useHistoryProps} header={header} subMenu={subMenu} urlParameters={urlParams} />
        </div>
        <div id="domContentBodyArea" className="app-main">
          {getHeader(header,urlParams,useHistoryProps,subMenu)}
          <div className="app-content">
            <div className="app-content--inner">
              <div className="app-content--inner__wrapper">{children}</div>
            </div>
            <Footer />
          </div>
        </div>
        <ThemeConfigurator />
      </div>
    </Fragment>
  );
};

LeftSidebarJe.propTypes = {
  header:PropTypes.string.isRequired,
  children: PropTypes.node
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,

  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

  footerFixed: state.ThemeOptions.footerFixed,

  contentBackground: state.ThemeOptions.contentBackground,
  themeConfiguratorToggle: state.ThemeOptions.themeConfiguratorToggle
});

export default connect(mapStateToProps)(LeftSidebarJe);
