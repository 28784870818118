import React, { Fragment, Component } from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Row,
  Col,
  Badge,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Button,
  Modal,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';

import { connect } from 'react-redux';

import avatar1 from '../../assets/images/avatars/avatar1.jpg';
import avatar2 from '../../assets/images/avatars/avatar2.jpg';
import avatar5 from '../../assets/images/avatars/avatar5.jpg';
import avatar6 from '../../assets/images/avatars/avatar6.jpg';
import avatar7 from '../../assets/images/avatars/avatar7.jpg';

import people3 from '../../assets/images/stock-photos/people-3.jpg';

class PageTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    let {
        actionAreaItems,
        children,
      pageTitleStyle,
      pageTitleBackground,
      pageTitleShadow,
      pageTitleBreadcrumb,
      pageTitleIconBox,
      pageTitleDescription
      
    } = this.props;

    return (
      <Fragment>
        <div
          className={cx('candidate-page-title', pageTitleStyle, pageTitleBackground, {
            'app-page-title--shadow': pageTitleShadow
          })}>
          <div>
            <div >
              {pageTitleIconBox && (
                <div className="app-page-title--iconbox d-70">
                  <div className="d-70 d-flex gradient-icon align-items-center justify-content-center">
                    <i className="pe-7s-umbrella display-3 gradient-icon bg-ripe-malin"></i>
                  </div>
                </div>
              )}
              <div>
                <div className="d-flex justify-content-between">
                  <div className="app-page-title--heading">
                      <h1>{this.props.titleHeading}</h1>
                      {pageTitleDescription && (
                      <div className="app-page-title--description">
                          {this.props.titleDescription}
                      </div>
                      )}
                  </div>
                  <div>
                    {  actionAreaItems }
                  </div>
                </div>
                {children}
              </div>
                
            </div>
          
          </div>
        </div>

        
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleBreadcrumb: state.ThemeOptions.pageTitleBreadcrumb,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);
