import React, {Fragment, useState, Component} from 'react';
//import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import accounting from "accounting";
import cx from 'classnames';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Row, Table, Col, CardBody, Card, CardTitle, CardHeader, Collapse, UncontrolledPopover, Container, InputGroup, InputGroupAddon, InputGroupText, FormText, Form, CustomInput, Label, FormGroup, Input, Badge, ListGroup, ListGroupItem, PopoverHeader, PopoverBody, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, Progress, DropdownToggle, DropdownMenu, Alert, DropdownItem } from 'reactstrap'

import {connect} from 'react-redux';

import {
    setSidebarToggleMobile
} from '../../reducers/ThemeOptions';

import { 
    generateInvoicePdf,
    getInvoice,
    handlePaymentModal,
} from "../../store/invoices"

import {
    getActiveClientDetail
} from "../../store/clients"

import {
    getActiveView,
    getActiveClientAccountDetails
} from "../../store/auth";

import {getCurrencyAttributesForInvoices} from "../../store/invoices"

import applicationConstants from "../../store/applicationConstants"
const {
    INVOICE_STATUS_OPEN,
    INVOICE_STATUS_CLOSED,
} = applicationConstants

class HeaderInvoiceDetail extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          
        };
    }

    handleGenerateIndividualInvoicePdf = (item)=>{
        const {generateInvoicePdf,activeClientAccountDetails} = this.props;
        generateInvoicePdf([item.id]);   
    }

    handlePayIndividualInvoice = (item)=>{
        const {openPaymentModal} = this.props;
        openPaymentModal(true,[item.id]);   
    }
    
      
    toggleSidebarMobile = () => {
        let {sidebarToggleMobile, setSidebarToggleMobile} = this.props;
        setSidebarToggleMobile(!sidebarToggleMobile);
    }

    render() {
        let {
            activeView,
            client,
            headerShadow,
            headerBgTransparent,
            sidebarToggleMobile,
            invoice,
            urlParameters,
            useHistory,
        } = this.props;

        //This we will get from the state once we query the database for now lets get it from url params
        //console.log(urlParameters);
        const { id:invoiceId, status:invoiceStatus } = urlParameters;

        //This we will get from the state once we query the database
        //let invoiceId= "445333";
        let [invoiceNumber,balance,paid,status] = ["",0,0,""];
        if(invoice){
            ({invoiceNumber,balance,paid,status} = invoice) 
        }

        let {currencySymbol,currencyExchangeRate,currencyBuffer,currencyCode,needToUseForeignExchangeConversion} = getCurrencyAttributesForInvoices(client,invoice);
        

        let allowToPerformOperations = false;
        if(activeView && activeView.permissionList){
            allowToPerformOperations = activeView.permissionList.manageInvoices
        }



        return (
            <Fragment>
                <div className={cx("app-header", {'app-header--shadow': headerShadow, 'app-header--opacity-bg': headerBgTransparent})}>
                <div className="app-header--pane">
                    
                    <button className={cx("navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn", {'is-active': sidebarToggleMobile})} onClick={this.toggleSidebarMobile}>
                        <span className="hamburger-box">
                            <span className="hamburger-inner"/>
                        </span>
                    </button>
                    <div className="je-header-page-title mb-3">
                        
                        <div className=" text-center">
                            {   
                                allowToPerformOperations === false && 
                                <div className="float-left mt-1 je-header-campaign-detail-page-title">
                                    <span className="align-middle cursor-pointer" onClick={
                                        e=>{
                                            e.preventDefault();
                                            useHistory.push('/invoices')
                                        }
                                        }
                                    >
                                        <FontAwesomeIcon icon={['fas', 'angle-left']} className="display-3" />
                                    </span>
                                    <span className="ml-2">
                                        Invoice Details
                                    </span>
                                </div>
                            }
                                
                            {
                                allowToPerformOperations === true && 
                                <>
                                    <div className="float-left mt-1 je-header-campaign-detail-page-title">
                                        <span className="align-middle cursor-pointer" onClick={
                                            e=>{
                                                e.preventDefault();
                                                useHistory.push('/invoices')
                                            }
                                            }
                                        >
                                            <FontAwesomeIcon icon={['fas', 'angle-left']} className="display-3" />
                                        </span>
                                        <span className="ml-2">
                                            Invoice # {invoiceNumber}
                                        </span>
                                    </div>
                                    <div  className="float-left ml-2 d-none d-xl-block" style={{marginTop:"0.1rem"}}>
                                        <span className="ml-2 text-succes d-none d-md-block">
                                            <Button color="link" className="btn-link-success"
                                                onClick={e=>{
                                                    e.preventDefault();
                                                    return this.handleGenerateIndividualInvoicePdf(invoice);
                                                }}
                                            >
                                                <FontAwesomeIcon icon={['far', 'file-pdf']} className="color-success" />
                                                <span className="font-weight-bold ml-2">Invoice PDF</span>
                                            </Button>
                                        </span>
                                    </div>
                                </>
                            }
                            
                            <div className="clearfix"></div>
                        </div>
                        
                    </div>

                    <div className="d-none je-header-page-title mb-3">
                        <div className=" text-left">
                            <div className="float-left mt-1 je-header-campaign-detail-page-title">
                                {   
                                    allowToPerformOperations === false && 
                                    <>
                                        <span className="ml-2">
                                            Invoice Details
                                        </span>
                                    </>
                                }
                                {   
                                    allowToPerformOperations === true && 
                                    <>
                                        <span className="align-middle cursor-pointer" onClick={
                                            e=>{
                                                e.preventDefault();
                                                useHistory.push('/invoices')
                                            }
                                        }>
                                            <FontAwesomeIcon icon={['fas', 'angle-left']} className="display-3" />
                                        </span>
                                        <span className="ml-2">
                                            Invoice # {invoiceNumber}
                                        </span>
                                        <span className="ml-2 text-succes d-none d-md-block">
                                            <Button color="link" className="btn-link-success"
                                                onClick={e=>{
                                                    e.preventDefault();
                                                    return this.handleGenerateIndividualInvoicePdf(invoice);
                                                }}
                                            >
                                                <FontAwesomeIcon icon={['far', 'file-pdf']} className="color-success" />
                                                <span className="font-weight-bold ml-2">Invoice PDF</span>
                                            </Button>
                                        </span>
                                    </>
                                 }   
                                
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                    
                </div>
                <div className="app-header--pane">
                    {   allowToPerformOperations && status === INVOICE_STATUS_OPEN &&
                        <>
                            {/* Amount Due Display */}
                            <div className="text-right text-danger mr-3">
                                <small className="text-uppercase">Amount Due:</small>
                                <h2 className="m-0 font-weight-bold">
                                    {   
                                        `${accounting.formatMoney(
                                            ((balance * currencyExchangeRate) + ((balance * currencyExchangeRate) * currencyBuffer)),
                                            currencySymbol
                                        )} ${(currencyCode && currencyCode !== "USD") ? currencyCode:''}`
                                    }
                                </h2>
                            </div>
                            
                            {/* End of Amount Due Display */}
                            
                            {/* Make a Payment Button */}
                            <div className="">
                                <Button color="success " className="m-2 pl-3"
                                    onClick={e=>{
                                        e.preventDefault();
                                        this.handlePayIndividualInvoice(invoice);
                                    }}
                                >
                                    <span className="btn-wrapper--label"><strong>Make a Payment</strong></span>
                                </Button>
                            </div>
                            {/* End of Make a Payment Button */}
                        </>
                    }
                    {   allowToPerformOperations && status === INVOICE_STATUS_CLOSED &&
                        <>
                            {/* Amount Due Display */}
                            <div className="text-right text-success mr-3">
                                <small className="text-uppercase">Balance Paid:</small>
                                <h2 className="m-0 font-weight-bold">
                                    {   
                                        `${accounting.formatMoney(
                                                ((paid * currencyExchangeRate) + ((paid * currencyExchangeRate) * currencyBuffer)),
                                                currencySymbol 
                                        )} ${(currencyCode && currencyCode !== "USD") ? currencyCode:''}`
                                    }
                                </h2>
                            </div>
                            
                            {/* End of Amount Due Display */}
                        </>
                    }
                </div>
            </div>
            </Fragment>
    );
    }
}

const mapStateToProps = (state,ownProps) => ({
    activeView:getActiveView(state),
    activeClientAccountDetails:getActiveClientAccountDetails(state),
    client:getActiveClientDetail(state),
    invoice:getInvoice(ownProps.urlParameters.id)(state),
    headerShadow: state.ThemeOptions.headerShadow,
    headerBgTransparent: state.ThemeOptions.headerBgTransparent,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
    generateInvoicePdf:(selectedInvoices)=>dispatch(generateInvoicePdf(selectedInvoices)), 
    setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
    openPaymentModal:(displayFlag,invoicesForPayment)=>dispatch(handlePaymentModal(displayFlag,invoicesForPayment)),

});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderInvoiceDetail);
