import React, { Fragment, Component } from 'react';
import {connect} from "react-redux";
import { useHistory } from 'react-router-dom'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';


import {
  Badge,
  ListGroup,
  ListGroupItem,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';
import avatar7 from '../../assets/images/avatars/avatar7.jpg';
import jeLogo from '../../assets/jobelephant/images/je_logo.png';
import facebook from '../../assets/jobelephant/images/facebook.png';
import twitter from '../../assets/jobelephant/images/twitter.png';
import linkedin from '../../assets/jobelephant/images/linkedin.png';
import jeSite from '../../assets/jobelephant/images/website.png';



import auth from "../../services/authService"

//Load UI action creators and selectors
import { 
  getActiveView,
  getClientAccounts,
  getLoggedInUser,
  isUserJeAdministrator,
  setActiveView,
} from "../../store/auth";
// End of loading action creators and selectors


const HeaderJeLogoBox = (props) =>{
    const {activeUser,activeView,clientAccountList,isUserJeAdministrator,loggedInUser,setActiveView} = props;
    let history = useHistory();

    const {username,firstName,lastName} = loggedInUser
    
    let manageUsers = true;
    if(activeView && activeView.permissionList){
      //manageUsers = activeView.permissionList.manageUsers
    } 
    
    const handleLogout = ()=>{
      auth.logout()
    }

    const selectClientAccount = clientAccount=>{
      setActiveView(clientAccount);
      //Refresh to dashboard as we are switch views
      window.location = "/dashboard"
    }

  
    return (
      <Fragment>
        <UncontrolledDropdown className="user-box position-relative ml-2">
          <DropdownToggle
            color="link"
            className="p-0 text-left d-flex align-items-center">
            <div className="d-block p-0 avatar-icon-wrapper">
              <div className="avatar-icon rounded je-logo">
                 <img alt="Customer Portal" src={jeLogo}/>
              </div>
            </div>
            <div className="pl-2">
              <div className="font-weight-bold">JobElephant</div>
            </div>
            <span className="pl-1 pl-xl-3">
              <FontAwesomeIcon
                icon={['fas', 'angle-down']}
                className="opacity-5"
              />
            </span>
          </DropdownToggle>
          <DropdownMenu right className="dropdown-menu-lg overflow-hidden p-0">
            <ListGroup flush className="text-left bg-transparent">
              { isUserJeAdministrator && 
                <ListGroupItem className="">
                  <div className="text-primary font-weight-bold">
                    {`Welcome ${firstName} ${lastName} (JE Administrator)`}
                  </div>
                  <div className="nav-header d-flex text-black-50 pt-1 pb-2 align-items-center">
                    <div>
                      {`You are viewing ${activeUser.username} account`}
                      <Button
                        tag="a"
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          return history.push("/select-user");
                        }}
                        color="link"
                        className="btn-link-first mb-2 p-0"
                        title="Switch User">
                        <span>(Switch User)</span>
                      </Button>
                    </div>
                  </div>
                </ListGroupItem>
              }
              
              { 
                clientAccountList.length > 1 && 
                <ListGroupItem className="rounded-top">
                  <PerfectScrollbar
                    option={{ wheelPropagation: false }}
                    className="scroll-area-xs shadow-overflow">
                  <Nav pills className="nav-neutral-primary flex-column">
                    <NavItem className="nav-header d-flex text-primary pt-1 pb-2 font-weight-bold align-items-center">
                      <div>Switch Client Accounts</div>
                    </NavItem>
                    {
                      clientAccountList.map(c=>{
                        return(
                        <NavItem key={c.clientAccountId}>
                          <NavLink className="text-black-50" href="#" onClick={e => {e.preventDefault(); selectClientAccount(c)}}>
                            {c.clientAccountName}
                          </NavLink>
                        </NavItem>)
                      })
                    }
                  </Nav>
                  </PerfectScrollbar>
                </ListGroupItem>
              }

              <ListGroupItem className="">
                <Nav pills className="nav-neutral-primary flex-column">
                  <NavItem className="nav-header d-flex text-primary pt-1 pb-2 font-weight-bold align-items-center">
                    <div>{isUserJeAdministrator ? `Signed in as ${activeUser.username}`:`Signed in as ${username}`}</div>
                  </NavItem>
                  {
                    manageUsers && 
                    <NavItem>
                      <NavLink className="text-black-50" href="#" onClick={e =>{
                          e.preventDefault();
                          return history.push("/manage-account/")
                        }}>
                          <FontAwesomeIcon icon={['far', 'user-circle']} /> <span className="ml-2">Manage accounts</span>
                      </NavLink>
                    </NavItem>
                  }
                  <NavItem>
                    <NavLink 
                      className="text-black-50"
                      href="#" 
                      onClick={e =>{
                        e.preventDefault();
                        return handleLogout();
                      }}
                    >
                        <FontAwesomeIcon icon={['fas', 'sign-out-alt']} /> <span className="ml-2">Sign-out</span>
                    </NavLink>
                  </NavItem>
                  
                </Nav>
              </ListGroupItem>
              <ListGroupItem className="rounded-bottom p-3 text-center">
                <div className="d-flex justify-content-around align-items-center">
                  <div>
                    <a 
                      href="https://www.facebook.com/jobelephant/"
                      target="_blank"
                    >
                      <img src={facebook} alt="Visit our Facebook page" className="nav-dropdown-icon-image" />
                    </a>
                  </div>
                  <div>
                    <a 
                      href="https://x.com/Job_Elephant"
                      target="_blank"
                    >
                      <img src={twitter} alt="Visit our X page" className="nav-dropdown-icon-image" />
                    </a>
                  </div>
                  <div>
                    <a 
                      href="https://www.linkedin.com/company/jobelephant/"
                      target="_blank"
                    >
                      <img src={linkedin} alt="Visit our Linkedin page" className="nav-dropdown-icon-image" />
                    </a>
                  </div>
                  <div>
                    <a 
                      href="https://www.jobelephant.com/"
                      target="_blank"
                    >
                      <img src={jeSite} alt="Visit our Jobelephant site" className="nav-dropdown-icon-image" />
                    </a>
                  </div>
                </div>

                
              </ListGroupItem>
            </ListGroup>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Fragment>
    );
  
}


const mapStateToProps = state=>({
  clientAccountList:getClientAccounts(state),
  isUserJeAdministrator:isUserJeAdministrator(state),
  activeUser:getActiveView(state).user || "",
  activeView:getActiveView(state),
  loggedInUser:getLoggedInUser(state),
});

const mapDispatchToProps = (dispatch)=>({
  setActiveView:(data)=>dispatch(setActiveView(data)),
});

export default connect(mapStateToProps,mapDispatchToProps)(HeaderJeLogoBox);

