import React, {Fragment, useState, Component} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import cx from 'classnames';
import {Link} from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Row, Table, Col, CardBody, Card, CardTitle, CardHeader, Collapse, UncontrolledPopover, Container, InputGroup, InputGroupAddon, InputGroupText, FormText, Form, CustomInput, Label, FormGroup, Input, Badge, ListGroup, ListGroupItem, PopoverHeader, PopoverBody, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, Progress, DropdownToggle, DropdownMenu, Alert, DropdownItem } from 'reactstrap'

import {connect} from 'react-redux';

import {
    getActiveView,
    getActiveClientAccount,
} from '../../store/auth'

import { 
    getCampaign,
    getCampaignDetails,
    stopAdForCampaignItemsList,
} from "../../store/campaigns"

import {
    getSelectedCampaignItemsPerView,
} from "../../store/ui-campaign-details";


import {
    setSidebarToggleMobile
} from '../../reducers/ThemeOptions';

import applicationConstants from "../../store/applicationConstants"

const {
    CAMPAIGN_DETAILS_TAB_PUBLISHED,  
    CAMPAIGN_DETAILS_TAB_PROCESSING,
} = applicationConstants;


class HeaderCampaignDetailAddPublication extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          
        };
    }

    processStopAdsForCampaign = ()=>{
        let {
            campaign,
            campaignDetails, 
            stopAdForCampaignItemList,   
        } = this.props;

        const {
            byIds,
            idListByStatus,
            itemCounts,
        } = campaignDetails;

        const campaignItems = [];

        if(itemCounts[CAMPAIGN_DETAILS_TAB_PUBLISHED] > 0){
            let statusListToProcess  = [CAMPAIGN_DETAILS_TAB_PUBLISHED];
            statusListToProcess.forEach(status=>{
                const listOfItemsIds = idListByStatus[status];
                listOfItemsIds.forEach(id=>{
                    campaignItems.push(byIds[id])
                })
            });

            return stopAdForCampaignItemList({
                campaignId:campaign.id,
                campaignItems,
                stopWholeCampaignFlag:true,
            })
        }

        //console.log(campaignItems);
        
        

    }
    
      
    toggleSidebarMobile = () => {
        let {sidebarToggleMobile, setSidebarToggleMobile} = this.props;
        setSidebarToggleMobile(!sidebarToggleMobile);
    }

    render() {
        let {
            activeView,
            headerShadow,
            headerBgTransparent,
            sidebarToggleMobile,
            campaign,  
            campaignDetails,  
            clientAccountName,
            useHistory,
        } = this.props;

        const campaignLoaded = (campaign) ? true:false;
        let [campaignId,campaignTitle,jobNumber,itemCounts] = ["","","",0];
        if(campaignLoaded){
            ({name:campaignTitle,id:campaignId,jobNumber} = campaign);
        } 

        if(campaignDetails){
            ({itemCounts} = campaignDetails);
        }

        let [allowToPerformOperations,allowToApproveAds] = [false,false];
        if(activeView && activeView.permissionList){
            allowToPerformOperations = activeView.permissionList.manageCampaigns;
            allowToApproveAds = activeView.permissionList.manageApproveAds;

        }



        return (
            <Fragment>
                <div className={cx("app-header", {'app-header--shadow': headerShadow, 'app-header--opacity-bg': headerBgTransparent})}>
                <div className="app-header--pane">
                    
                    <button className={cx("navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn", {'is-active': sidebarToggleMobile})} onClick={this.toggleSidebarMobile}>
                        <span className="hamburger-box">
                            <span className="hamburger-inner"/>
                        </span>
                    </button>
                    <div className="je-header-page-title mb-3">
                        
                        <div className=" text-center">
                            { !campaignLoaded && <div className="width-50px"><Skeleton count={1} /></div>}
                            {
                                campaignLoaded &&
                                <>
                                    <div className="float-left mt-1 je-header-campaign-detail-page-title">
                                        {campaignTitle}
                                    </div>
                                    <div className="float-left  ml-2 text-black-50 d-none d-xl-block" style={{marginTop:"0.4rem"}}>
                                        | 
                                    </div>
                                    <div  className="float-left ml-2 d-none d-xl-block" style={{marginTop:"0.5rem"}}>
                                        <span className="text-black-50">{ jobNumber !== "" && `#${jobNumber}`}</span>
                                    </div>
                                </>
                            }
                            
                            <div className="clearfix"></div>
                        </div>
                        <div className="je-header-campaign-detail-page-description">
                            { !campaignLoaded && <div className="width-50px"><Skeleton count={1} /></div>}
                            { 
                              campaignLoaded && <strong>{clientAccountName}</strong>
                            }
                        </div>
                        
                    </div>
                    
                </div>
                <div className="app-header--pane">
                    
                </div>
            </div>
            </Fragment>
    );
    }
}

const mapStateToProps = (state,ownProps) => ({
    activeView:getActiveView(state),
    campaign:getCampaign(ownProps.urlParameters.id)(state),
    campaignDetails:getCampaignDetails(ownProps.urlParameters.id)(state),
    clientAccountName:getActiveClientAccount(state).name || "",
    headerShadow: state.ThemeOptions.headerShadow,
    headerBgTransparent: state.ThemeOptions.headerBgTransparent,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
    
    setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
    stopAdForCampaignItemList:(campaignItemObject)=>dispatch(stopAdForCampaignItemsList(campaignItemObject)),


});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCampaignDetailAddPublication);
