import React from 'react';
import ReactDOM from 'react-dom';
//import ReactGA from 'react-ga';
import ReactGA from "react-ga4";

import App from './App';
import * as serviceWorker from './serviceWorker';

import auth from './services/authService';



//Google Analytics setup
const trackingId = window.__RUNTIME_CONFIG__['REACT_APP_GA4_MEASUREMENT_ID']; 
ReactGA.initialize(trackingId);

const currentUser = auth.getCurrentUser();
if(currentUser !== null){
  const {userId} = currentUser;
  ReactGA.set({userId})
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
