import { createSlice } from "@reduxjs/toolkit";
import {createSelector} from "reselect";
import _ from "lodash";
import {scrollTop} from '../utils/helperUtils';


import applicationContants from "./applicationConstants";

const divErrorArea = "domContentBodyArea";

const {
    CAMPAIGN_DETAILS_TAB_READY_FOR_APPROVAL,
    CAMPAIGN_DETAILS_TAB_REVIEW_BY_JE,
    CAMPAIGN_DETAILS_TAB_PROCESSING,
    CAMPAIGN_DETAILS_TAB_PUBLISHED,
    CAMPAIGN_DETAILS_TAB_CANCELED,
    CAMPAIGN_DETAILS_TAB_EXPIRED,
    CAMPAIGN_DETAILS_TAB_ALL,

} = applicationContants;

const listOfTabView = [
    CAMPAIGN_DETAILS_TAB_READY_FOR_APPROVAL,
    CAMPAIGN_DETAILS_TAB_REVIEW_BY_JE,
    CAMPAIGN_DETAILS_TAB_PROCESSING,
    CAMPAIGN_DETAILS_TAB_PUBLISHED,
    CAMPAIGN_DETAILS_TAB_CANCELED,
    CAMPAIGN_DETAILS_TAB_EXPIRED,
    CAMPAIGN_DETAILS_TAB_ALL,
]



/*
//DEFINITION
const emptyState  = {
    campaign:{
        [campaignId]:{
            activeTabView:CAMPAIGN_DETAILS_TAB_READY_FOR_APPROVAL,
            [CAMPAIGN_DETAILS_TAB_READY_FOR_APPROVAL]:{
                data:{
                    campaignItems:[],
                },
                errors:{},
            },
            [CAMPAIGN_DETAILS_TAB_PUBLISHED]:{
                data:{
                    campaignItems:[],
                },
                errors:{},
            },
        }
    },
}
*/

const initializationObject = {
    activeTabView:CAMPAIGN_DETAILS_TAB_ALL,
    [CAMPAIGN_DETAILS_TAB_REVIEW_BY_JE]:{
        data:{
            campaignItems:[],
        },
        errors:[],
        success:"",
    },
    [CAMPAIGN_DETAILS_TAB_READY_FOR_APPROVAL]:{
        data:{
            campaignItems:[],
        },
        errors:[],
        success:"",
    },
    [CAMPAIGN_DETAILS_TAB_PROCESSING]:{
        data:{
            campaignItems:[],
        },
        errors:[],
        success:"",
    },
    [CAMPAIGN_DETAILS_TAB_PUBLISHED]:{
        data:{
            campaignItems:[],
        },
        errors:[],
        success:"",
    },
    [CAMPAIGN_DETAILS_TAB_EXPIRED]:{
        data:{
            campaignItems:[],
        },
        errors:[],
        success:"",
    },
    [CAMPAIGN_DETAILS_TAB_ALL]:{
        data:{
            campaignItems:[],
        },
        errors:[],
        success:"",
    },
}
    
    
const initialState = {
    campaign:{},
    reviewArtworkScreen:{},
    selectPublicationScreen:{},
    modifyAdScreen:{},
}

//-------Slice: Create reducers and actions--------
const slice  = createSlice({
    name:"myCampaignDetailView",
    initialState,
    reducers:{
        campaignItemInitialized:(myCampaignDetailView,action)=>{
            const {campaignId,activeTabView} = action.payload;
            if(_.isEmpty(myCampaignDetailView.campaign[campaignId])){
                myCampaignDetailView.campaign[campaignId] = {
                    ...initializationObject
                }
                myCampaignDetailView.campaign[campaignId].activeTabView = activeTabView;
            }
            else
                myCampaignDetailView.campaign[campaignId].activeTabView = activeTabView;
        },
        campaignItemErrorUIClearedPerView:(myCampaignDetailView,action)=>{
            const {campaignId,tabView} =  action.payload;
            myCampaignDetailView.campaign[campaignId][tabView].errors = [];
        },
        campaignItemErrorUIDisplayedPerView:(myCampaignDetailView,action)=>{
            const {data,error} = action.payload;
            const {campaignId,tabView} = data;
            myCampaignDetailView.campaign[campaignId][tabView].errors = error.list;
        },

        campaignItemErrorUIClearedFromModifyAdScreen:(myCampaignDetailView,action)=>{
            myCampaignDetailView.modifyAdScreen = {}
        },
        campaignItemErrorUIDisplayedFromModifyAdScreen:(myCampaignDetailView,action)=>{
            const {data,error} = action.payload;
            const {campaign,campaignDetails,campaignItemId} = data;
            const {id:campaignId} = campaign;
            //const {byIds} = campaignDetails;
            //const campaignItem = byIds[campaignItemId];
            myCampaignDetailView.modifyAdScreen = {
                [campaignId]:{
                    [campaignItemId]:{errors: error.list,success:false}
                }
            };

            setTimeout(()=>scrollTop(divErrorArea),500)
        },

        campaignItemErrorUIClearedFromReviewArtworkScreen:(myCampaignDetailView,action)=>{
            myCampaignDetailView.reviewArtworkScreen = {}
        },
        campaignItemErrorUIDisplayedFromReviewArtworkScreen:(myCampaignDetailView,action)=>{
            const {data,error} = action.payload;
            const {campaignId,campaignItems} = data;
            campaignItems.forEach(item=>{
                const {id:campaignItemId} = item;
                myCampaignDetailView.reviewArtworkScreen = {
                    [campaignId]:{
                        [campaignItemId]:{errors: error.list}
                    }
                };
            })
        },

        campaignItemErrorUIClearedFromSelectPublicationScreen:(myCampaignDetailView,action)=>{
            myCampaignDetailView.selectPublicationScreen = {}
        },
        campaignItemErrorUIDisplayedFromSelectPublicationScreen:(myCampaignDetailView,action)=>{
            const {data,error} = action.payload;
            const {campaign} = data;
            const {id:campaignId} = campaign;
            myCampaignDetailView.selectPublicationScreen = {
                [campaignId]:{errors: error.list,success:false}
            };
            setTimeout(()=>scrollTop(divErrorArea),500)
        },

        //clear success notification for all view
        campaignItemSuccessUIClearedAllView:(myCampaignDetailView,action)=>{
            const {campaignId} =  action.payload;
            listOfTabView.forEach(tabView=>{
                if(myCampaignDetailView.campaign[campaignId] && myCampaignDetailView.campaign[campaignId][tabView])
                    myCampaignDetailView.campaign[campaignId][tabView].success = "";
            })

            myCampaignDetailView.modifyAdScreen = {}
        },

        //clear success notification
        campaignItemSuccessUIClearedPerView:(myCampaignDetailView,action)=>{
            const {campaignId,tabView} =  action.payload;
            myCampaignDetailView.campaign[campaignId][tabView].success = "";
            myCampaignDetailView.modifyAdScreen = {}
        },
        //display success notification
        campaignItemSuccessUIDisplayedPerView:(myCampaignDetailView,action)=>{
            const {campaignId,tabView,successNotification} = action.payload;
            const {message} = successNotification
            myCampaignDetailView.campaign[campaignId][tabView].success = message;
        },

        campaignItemSuccessUIDisplayedFromModifyAdScreen:(myCampaignDetailView,action)=>{
            //console.log(action.payload);
            const {campaign,campaignItemId,campaignItems,successNotification} = action.payload; 
            const {message} = successNotification
            const {id:campaignId} = campaign;
            if(_.isEmpty(myCampaignDetailView.campaign[campaignId])){
                myCampaignDetailView.campaign[campaignId] = {
                    ...initializationObject
                }
            }

            const {idListByStatus} = campaignItems;
            
            let tabView  = "";
            _.forEach(idListByStatus,(listOfItems,tabStatus)=>{
                if(tabView === "" && listOfItems.includes(campaignItemId)){
                    tabView = tabStatus
                }
            })
            myCampaignDetailView.campaign[campaignId].activeTabView = tabView;
            myCampaignDetailView.campaign[campaignId][tabView].success = message;
            
            myCampaignDetailView.modifyAdScreen = {
                [campaignId]:{
                    [campaignItemId]:{errors: [],success:true}
                }
            };
            
        },

        campaignItemSuccessUIClearedFromSelectPublicationScreen:(myCampaignDetailView,action)=>{
            myCampaignDetailView.selectPublicationScreen = {}
        },
        campaignItemSuccessUIDisplayedFromSelectPublicationScreen:(myCampaignDetailView,action)=>{
            const {campaign,successNotification} = action.payload; 
            const {message} = successNotification
            const {id:campaignId} = campaign;
            if(_.isEmpty(myCampaignDetailView.campaign[campaignId])){
                myCampaignDetailView.campaign[campaignId] = {
                    ...initializationObject
                }
            }

            const tabView = CAMPAIGN_DETAILS_TAB_REVIEW_BY_JE;
            myCampaignDetailView.campaign[campaignId].activeTabView = tabView;
            myCampaignDetailView.campaign[campaignId][tabView].success = message;
            
            myCampaignDetailView.selectPublicationScreen = {
                [campaignId]:{errors: [],success:true}
            };
            
        },

        campaignItemsSelectedPerView:(myCampaignDetailView,action)=>{
            const {campaignId,campaignItem:item,tabView} = action.payload;
            if(_.isEmpty(myCampaignDetailView.campaign[campaignId])){
                    myCampaignDetailView.campaign[campaignId][tabView].data.campaignItems.push(item);
            }
            else{
                if(_.find(myCampaignDetailView.campaign[campaignId][tabView].data.campaignItems,{'id':item.id}) !== undefined)
                    myCampaignDetailView.campaign[campaignId][tabView].data.campaignItems = _.reject(
                        myCampaignDetailView.campaign[campaignId][tabView].data.campaignItems, {'id':item.id}
                    );
                else 
                    myCampaignDetailView.campaign[campaignId][tabView].data.campaignItems.push(item);
            }
        },
        campaignItemsPerViewSelectedAll:(myCampaignDetailView,action)=>{
            //console.log(action);
            const {allCampaignItems:campaignDetailItems,allCampaignItemsIdList:itemIdList,campaignId,tabView} = action.payload;
            myCampaignDetailView.campaign[campaignId][tabView].data.campaignItems = _.map(itemIdList,i=>campaignDetailItems[i])

        },
        campaignItemsPerViewDeselectedAll:(myCampaignDetailView,action)=>{
            const {campaignId,tabView} = action.payload;
            myCampaignDetailView.campaign[campaignId][tabView].data.campaignItems = []
        },

        

        selectedActiveTabView:(myCampaignDetailView,action)=>{
            const {campaignId,tabView} = action.payload;
            //console.log(action.payload);
            //console.log(myCampaignDetailView.campaign[campaignId]);
            
            myCampaignDetailView.campaign[campaignId].activeTabView = tabView;
        },
        
        
    }
});

//--------Export reducers and actions-----------------
export const {
    campaignItemErrorUIClearedPerView,
    campaignItemErrorUIDisplayedPerView,
    campaignItemErrorUIClearedFromModifyAdScreen,
    campaignItemErrorUIDisplayedFromModifyAdScreen,
    campaignItemErrorUIClearedFromReviewArtworkScreen,
    campaignItemErrorUIDisplayedFromReviewArtworkScreen,
    campaignItemErrorUIClearedFromSelectPublicationScreen,
    campaignItemErrorUIDisplayedFromSelectPublicationScreen,
    campaignItemSuccessUIClearedAllView,
    campaignItemSuccessUIClearedPerView,
    campaignItemSuccessUIDisplayedPerView,
    campaignItemSuccessUIDisplayedFromModifyAdScreen,
    campaignItemSuccessUIClearedFromSelectPublicationScreen,
    campaignItemSuccessUIDisplayedFromSelectPublicationScreen,
    campaignItemInitialized,
    campaignItemsSelectedPerView,
    campaignItemsPerViewSelectedAll,
    campaignItemsPerViewDeselectedAll,
    selectedActiveTabView,
} = slice.actions;

export default slice.reducer;

//---------Action creators-------------------------------

export const clearErrorsForCampaignItemsPerView = ({campaignId,tabView})=>dispatch=>{
    dispatch(campaignItemErrorUIClearedPerView({campaignId,tabView}))
}

export const clearErrorsForCampaignItemsFromModifyAdScreen = (campaignId)=>dispatch=>{
    dispatch(campaignItemErrorUIClearedFromModifyAdScreen({campaignId}))
}

export const clearErrorsForCampaignItemsFromReviewArtworkScreen = (campaignId)=>dispatch=>{
    dispatch(campaignItemErrorUIClearedFromReviewArtworkScreen({campaignId}))
}

export const clearErrorsForCampaignItemsFromSelectPublicationsScreen = (campaignId)=>dispatch=>{
    dispatch(campaignItemErrorUIClearedFromSelectPublicationScreen({campaignId}))
}

export const clearSuccessNotificationsForCampaignItemsPerView = ({campaignId,tabView})=>dispatch=>{
    dispatch(campaignItemSuccessUIClearedPerView({campaignId,tabView}))
}

export const clearSuccessNotificationsForCampaignItemsAllView = ({campaignId})=>dispatch=>{
    dispatch(campaignItemSuccessUIClearedAllView({campaignId}))
}


export const deselectAllCampaignItemsPerView = ({campaignId,tabView})=>dispatch=>{
    dispatch(campaignItemsPerViewDeselectedAll({campaignId,tabView}))
}

export const handleCampaignItemCheckboxTogglePerView = ({campaignId,campaignItem,tabView})=>dispatch=>{
    dispatch(campaignItemsSelectedPerView({campaignId,campaignItem,tabView}))
}

export const initializeCampaignItemUIObject = (campaignId,activeTabView)=>dispatch=>{
    dispatch(campaignItemInitialized({campaignId,activeTabView}))
}

export const selectAllCampaignItemsPerView = ({allCampaignItems,allCampaignItemsIdList,campaignId,tabView})=>dispatch=>{
    dispatch(campaignItemsPerViewSelectedAll({allCampaignItems,allCampaignItemsIdList,campaignId,tabView}))
}

export const setActiveTabView = (campaignId,tabView)=>dispatch=>{
    dispatch(selectedActiveTabView({campaignId,tabView}))
}




//----------Get selectors------------------------------
export const getActiveTabView = (campaignId)=>createSelector(
    state=>state.ui.myCampaignDetailView,
    myCampaignDetailView=>{
        return (!_.isEmpty(myCampaignDetailView.campaign[campaignId])) ? 
        myCampaignDetailView.campaign[campaignId].activeTabView
        : 
        CAMPAIGN_DETAILS_TAB_READY_FOR_APPROVAL;
    }
)

export const getProcessingErrorsPerView = (campaignId,tabView)=>createSelector(
    state=>state.ui.myCampaignDetailView,
    myCampaignDetailView=>{
        return (!_.isEmpty(myCampaignDetailView.campaign[campaignId])) ? 
        myCampaignDetailView.campaign[campaignId][tabView].errors
        :
        []
        
    }
)

export const getProcessingErrorsForModifyAdScreen = (campaignId,campaignItemId)=>createSelector(
    state=>state.ui.myCampaignDetailView,
    myCampaignDetailView=>{
        return (!_.isEmpty(myCampaignDetailView.modifyAdScreen[campaignId]) && !_.isEmpty(myCampaignDetailView.modifyAdScreen[campaignId][campaignItemId])) ? 
        myCampaignDetailView.modifyAdScreen[campaignId][campaignItemId].errors
        :
        []
        
    }
)

export const getProcessingErrorsForReviewArtworkScreen = (campaignId,campaignItemId)=>createSelector(
    state=>state.ui.myCampaignDetailView,
    myCampaignDetailView=>{
        return (!_.isEmpty(myCampaignDetailView.reviewArtworkScreen[campaignId]) && !_.isEmpty(myCampaignDetailView.reviewArtworkScreen[campaignId][campaignItemId])) ? 
        myCampaignDetailView.reviewArtworkScreen[campaignId][campaignItemId].errors
        :
        []
        
    }
)

export const getProcessingErrorsForSelectPublicationsScreen = (campaignId)=>createSelector(
    state=>state.ui.myCampaignDetailView,
    myCampaignDetailView=>{
        return (!_.isEmpty(myCampaignDetailView.selectPublicationScreen[campaignId])) ? 
        myCampaignDetailView.selectPublicationScreen[campaignId].errors
        :
        []
        
    }
)

export const getSelectedCampaignItemsPerView = (campaignId,tabView)=>createSelector(
    state=>state.ui.myCampaignDetailView,
    myCampaignDetailView=>{
        return (!_.isEmpty(myCampaignDetailView.campaign[campaignId])) ? 
        myCampaignDetailView.campaign[campaignId][tabView].data.campaignItems
        :
        []
        
    }
)

export const getSuccessMessageForNotificationPerView = (campaignId,tabView)=>createSelector(
    state=>state.ui.myCampaignDetailView,
    myCampaignDetailView=>{
        
        return (!_.isEmpty(myCampaignDetailView.campaign[campaignId])) ? 
        myCampaignDetailView.campaign[campaignId][tabView].success
        :
        ""
        
    }
)

export const proceedToNextStepFromModifyAdScreen = (campaignId,campaignItemId)=>createSelector(
    state=>state.ui.myCampaignDetailView,
    myCampaignDetailView=>{
        return (!_.isEmpty(myCampaignDetailView.modifyAdScreen[campaignId]) && !_.isEmpty(myCampaignDetailView.modifyAdScreen[campaignId][campaignItemId])) ? 
        myCampaignDetailView.modifyAdScreen[campaignId][campaignItemId].success
        :
        false
        
    }
)

export const proceedToNextStepFromSelectPublicationsScreen = (campaignId)=>createSelector(
    state=>state.ui.myCampaignDetailView,
    myCampaignDetailView=>{
        return (!_.isEmpty(myCampaignDetailView.selectPublicationScreen[campaignId]) && myCampaignDetailView.selectPublicationScreen[campaignId].success) ? 
        myCampaignDetailView.selectPublicationScreen[campaignId].success
        :
        false
        
    }
)



