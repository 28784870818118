import {UPDATE_DASHBOARD} from '../actionTypes/dashboardActionTypes';

const PROCESSING_STATUS_LOADED = "Loaded";
const PROCESSING_STATUS_RELOAD = "Reload";

const initState = {
    processingStatus:"",
    activeCampaignCount:5,
    items:[],
}

const dashboardReducer = (state=initState,action)=>{
    switch(action.type){
       case UPDATE_DASHBOARD:
           let activeCampaignCountState = state.activeCampaignCount;
           const {counter} = action.dashboard;
           state = {
               ...state,
               activeCampaignCount: activeCampaignCountState + counter
           }
           break;
    }
    
    return state;
}

export default dashboardReducer;