import { createSlice } from "@reduxjs/toolkit";
import {createSelector} from "reselect";
import moment from "moment";
import _ from "lodash";
import {scrollTop} from '../utils/helperUtils';


import applicationContants from "./applicationConstants";

const {
    INVOICE_STATUS_OPEN,
    INVOICE_STATUS_CLOSED,

    INVOICE_TAB_OPEN,
    INVOICE_TAB_CLOSED,
    INVOICE_ALL,

    PAGINATION_LIMIT,
    
    SORT_ORDER_ASCEND,
    SORT_ORDER_DESCEND,
} = applicationContants;

const DEFAULT_SORT_FIELD_1 = "invoice_number";

const divErrorArea = "errorArea";


const initialState = {
    query:{
        keyword:"",
        clientId:"",
        clientAccountId:"",
        filteredView:INVOICE_ALL,
        sortForFilter:"",   
        paginationLimit:PAGINATION_LIMIT,
        pageNumber:1,
        defaultSort:[
            {
                sortBy:DEFAULT_SORT_FIELD_1,
                sortOrder:SORT_ORDER_DESCEND,
            },
        ],
    },

    defaultSortIndicator:{},

    activeTabView:INVOICE_TAB_OPEN,

    filterProperties:{
        [INVOICE_TAB_OPEN]:{
            displayLabel:INVOICE_TAB_OPEN,
            pageNumber:1,
            sort:{
                sortBy:"", 
                sortOrder:"",
            },
            data:{
                selectAllCheckbox:false,
                invoices:[],
            },
            errors:[],
            success:"",
        },
        [INVOICE_TAB_CLOSED]:{
            displayLabel:INVOICE_TAB_CLOSED,
            pageNumber:1,
            sort:{
                sortBy:"", 
                sortOrder:"",
            },
            data:{
                selectAllCheckbox:false,
                invoices:[],
            },
            errors:[],
            success:"",
        },
        
    },

    paymentProcessedConfirmationModalDisplayFlag:false,
    paymentModalDisplayFlag:false,
    paymentModalProperties:{
        errors:[],
        success:"",
    },

}

const listOfStatusToProcess = [
    INVOICE_STATUS_OPEN,
    INVOICE_STATUS_CLOSED,
]

//-------Slice: Create reducers and actions--------
const slice  = createSlice({
    name:"invoiceView",
    initialState,
    reducers:{
        currentPageNumberSelected:(invoiceView,action)=>{
            const {activeTabView} = invoiceView;
            const {pageNumber} = action.payload;
            invoiceView.filterProperties[activeTabView].pageNumber = pageNumber;
            invoiceView.query.pageNumber = pageNumber;
        },
        invoiceSelectedPerView:(invoiceView,action)=>{
            const {invoice,tabView} = action.payload;
            let invoiceSelectedItems = invoiceView.filterProperties[tabView].data.invoices;
            if(_.isEmpty(invoiceSelectedItems)){
                invoiceSelectedItems.push(invoice.id);
            }
            else{
                const index =  invoiceSelectedItems.indexOf(invoice.id)
                if(index !== -1)
                    invoiceSelectedItems.splice(index,1);
                else 
                    invoiceSelectedItems.push(invoice.id);
            }
        },
        invoicesPerViewSelectedAll:(invoiceView,action)=>{
            const {allInvoices:invoices,allInvoicesIdList:itemIdList,tabView} = action.payload;
            const invoiceDataObject = invoiceView.filterProperties[tabView].data
            invoiceDataObject.invoices = _.map(itemIdList,i=>invoices[i].id);
            invoiceDataObject.selectAllCheckbox = true;

        },
        invoicesPerViewDeselectedAll:(invoiceView,action)=>{
            const {tabView} = action.payload;
            const invoiceDataObject = invoiceView.filterProperties[tabView].data
            invoiceDataObject.invoices = [];
            invoiceDataObject.selectAllCheckbox = false;
        },
        keywordFieldUpdated:(invoiceView,action)=>{
            const {activeTabView} = invoiceView;
            const {keyword} = action.payload;
            invoiceView.query.keyword = keyword;
            invoiceView.query.sortForFilter="";
            invoiceView.query.pageNumber = 1;
        },
        paymentModalSetDisplay:(invoiceView,action)=>{
            const {displayFlag}  = action.payload;
            invoiceView.paymentModalDisplayFlag = displayFlag
        },
        paymentProcessedConfirmationModalSetDisplay:(invoiceView,action)=>{
            const {displayFlag}  = action.payload;
            invoiceView.paymentProcessedConfirmationModalDisplayFlag = displayFlag
        },
        paymentProcessedOnSuccess:(invoiceView,action)=>{
            invoiceView.paymentModalDisplayFlag = false;
            invoiceView.paymentProcessedConfirmationModalDisplayFlag = true;
        },
        paymentProcessedErrorUICleared:(invoiceView,action)=>{
            invoiceView.paymentModalProperties.errors = []
        },
        paymentProcessedOnError:(invoiceView,action)=>{
            //console.log(action.payload);
            const {error} = action.payload;
            invoiceView.paymentModalProperties.errors = error.list;
            scrollTop(divErrorArea);
        },
        selectedActiveTabView:(invoiceView,action)=>{
            const {tabView:activeTabView}  = action.payload;
            invoiceView.activeTabView = activeTabView;
            invoiceView.query.filteredView = activeTabView;
        },
        
    },
})


//--------Export reducers and actions-----------------
export const {
    currentPageNumberSelected,
    invoiceSelectedPerView,
    invoicesPerViewSelectedAll,
    invoicesPerViewDeselectedAll,
    keywordFieldUpdated,
    paymentModalSetDisplay,
    paymentProcessedConfirmationModalSetDisplay,
    paymentProcessedErrorUICleared,
    paymentProcessedOnError,
    paymentProcessedOnSuccess,
    selectedActiveTabView,
} = slice.actions;

export default slice.reducer;

//---------Export action creators-------------------------
export const setActiveTabView = tabView=>dispatch=>{
    dispatch(selectedActiveTabView({tabView}))
}

export const clearPaymentProcessingErrors = ()=>dispatch=>{
    dispatch(paymentProcessedErrorUICleared())
}


export const deselectAllInvoicesPerView = ({tabView})=>dispatch=>{
    dispatch(invoicesPerViewDeselectedAll({tabView}))
}

export const handleInvoiceCheckboxTogglePerView = ({invoice,tabView})=>dispatch=>{
    dispatch(invoiceSelectedPerView({invoice,tabView}))
}

export const selectAllInvoicesPerView = ({allInvoices,allInvoicesIdList,tabView})=>dispatch=>{
    dispatch(invoicesPerViewSelectedAll({allInvoices,allInvoicesIdList,tabView}))
}

export const setCurrentPageNumber = pageNumber=>dispatch=>{
    dispatch(currentPageNumberSelected({pageNumber}))
}

export const setKeywordSearchField = (keyword)=>dispatch=>{
    dispatch(keywordFieldUpdated({keyword}))
}

export const setPaymentModalDisplay = (flag)=>dispatch=>{
    //console.log('setPaymentModalDisplay',flag);
    dispatch(paymentModalSetDisplay({displayFlag:flag}))
}

export const setPaymentProcessedConfirmationModalDisplay = (flag)=>dispatch=>{
    //console.log('setPaymentModalDisplay',flag);
    dispatch(paymentProcessedConfirmationModalSetDisplay({displayFlag:flag}))
}


//----------Get selectors------------------------------

export const getActiveTabView = createSelector(
    state=>state.ui.invoiceView,
    invoiceView=>invoiceView.activeTabView
)


export const getPaymentModalDisplayFlag = createSelector(
    state=>state.ui.invoiceView,
    invoiceView=>invoiceView.paymentModalDisplayFlag
)

export const getPaymentProcessedConfirmationModalDisplayFlag = createSelector(
    state=>state.ui.invoiceView,
    invoiceView=>invoiceView.paymentProcessedConfirmationModalDisplayFlag
)

export const getSelectedAllCheckboxPerView = (tabView)=>createSelector(
    state=>state.ui.invoiceView,
    invoiceView=>{
        return (!_.isEmpty(invoiceView.filterProperties[tabView])) ? 
        invoiceView.filterProperties[tabView].data.selectAllCheckbox
        :
        false
        
    }
)

export const getSelectedInvoicesPerView = (tabView)=>createSelector(
    state=>state.ui.invoiceView,
    invoiceView=>{
        return (!_.isEmpty(invoiceView.filterProperties[tabView])) ? 
        invoiceView.filterProperties[tabView].data.invoices
        :
        []
        
    }
)

export const getPaymentProcessingServerErrors = createSelector(
    state=>state.ui.invoiceView,
    invoiceView=> invoiceView.paymentModalProperties.errors
)