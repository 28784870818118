import React, {Fragment, useState, Component} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Row, Table, Col, CardBody, Card, CardTitle, CardHeader, Collapse, UncontrolledPopover, Container, InputGroup, InputGroupAddon, InputGroupText, FormText, Form, CustomInput, Label, FormGroup, Input, Badge, ListGroup, ListGroupItem, PopoverHeader, PopoverBody, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, Progress, DropdownToggle, DropdownMenu, Alert, DropdownItem } from 'reactstrap'

import {connect} from 'react-redux';

import {
    setSidebarToggleMobile
} from '../../reducers/ThemeOptions';

import {
    getActiveView,
    getActiveClientAccount,
} from '../../store/auth'


function HeaderCandidate(props) {

    let {
        activeView,
        clientAccountName,
        header,
        headerShadow,
        headerBgTransparent,
        sidebarToggleMobile,
        setSidebarToggleMobile

    } = props;
    
    const toggleSidebarMobile = () => {
        setSidebarToggleMobile(!sidebarToggleMobile);
    }

    return (
        <>
            <div className={cx("app-header", {'app-header--shadow': headerShadow, 'app-header--opacity-bg': headerBgTransparent})}>
                <div className="app-header--pane">
                    
                    <button className={cx("navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn", {'is-active': sidebarToggleMobile})} onClick={toggleSidebarMobile}>
                        <span className="hamburger-box">
                            <span className="hamburger-inner"/>
                        </span>
                    </button>
                    
                    <div className="d-none d-lg-block je-header-page-title mb-2">
                        <div className="je-header-text-display-area text-center">
                                <h2 className="float-left je-header-dashboard-page-title">
                                {header}
                                </h2>
                        </div>
                    </div>
                    
                    <div className="d-block d-lg-none je-header-page-title mb-2">
                        <div className=" text-center">
                            <div className="float-left mt-1 je-header-campaign-detail-page-title">
                                {header}
                            </div>
                            <div className="float-left  ml-2 text-black-50 d-block d-sm-none d-xl-block" style={{marginTop:"0.4rem"}}>
                                    
                            </div>
                            <div  className="float-left ml-2 d-block d-sm-none d-xl-block" style={{marginTop:"0.5rem"}}>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                       
                        
                    </div>

                </div>
                <div className="app-header--pane">
                    
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    activeView:getActiveView(state),
    clientAccountName:getActiveClientAccount(state).name || "",
    headerShadow: state.ThemeOptions.headerShadow,
    headerBgTransparent: state.ThemeOptions.headerBgTransparent,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({

    setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),

});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderCandidate);
