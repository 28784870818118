import {UPDATE_CAMPAIGN_LIST} from '../actionTypes/campaignActionTypes';

const initState = {
    currentCounter:1
}

const campaignReducer = (state = initState,action)=>{
    switch(action.type){
        case UPDATE_CAMPAIGN_LIST:
            const {counter} = action.campaign;
            let incrementedCounter = state.currentCounter + counter;
            state = {
                ...state,
                currentCounter:incrementedCounter
            }
            break;
    }
    return state;
}

export default campaignReducer;