import { createSlice } from "@reduxjs/toolkit";
import {createSelector} from "reselect";
import moment from "moment";
import _ from "lodash";

import applicationContants from "./applicationConstants";

const {
    CAMPAIGN_TAB_ACTIVE,
    CAMPAIGN_TAB_ALL,
    CAMPAIGN_TAB_ARCHIVED,
    CAMPAIGN_TAB_DRAFT,
    
    PAGINATION_LIMIT,
    
    SORT_ORDER_ASCEND,
    SORT_ORDER_DESCEND,
} = applicationContants;

const DEFAULT_SORT_FIELD_1 = "created";
const DEFAULT_SORT_FIELD_2 = "start_date";


const listOfStatusToProcess = [
    CAMPAIGN_TAB_ALL,
    CAMPAIGN_TAB_ACTIVE,
    CAMPAIGN_TAB_ARCHIVED,
    CAMPAIGN_TAB_DRAFT,
]



const initialState = {
    query:{
        keyword:"",
        clientId:"",
        clientAccountId:"",
        filteredView:CAMPAIGN_TAB_ACTIVE,
        sortForFilter:"",   
        paginationLimit:PAGINATION_LIMIT,
        pageNumber:1,
        defaultSort:[
            {
                sortBy:DEFAULT_SORT_FIELD_1,
                sortOrder:SORT_ORDER_DESCEND,
            },
            {
                sortBy:DEFAULT_SORT_FIELD_2,
                sortOrder:SORT_ORDER_DESCEND,
            },
        ],
    },
    pageNumber:1,
    campaigns:[], //List of active campaigns ids
}

//-------Slice: Create reducers and actions--------
const slice  = createSlice({
    name:"dashboardView",
    initialState:{...initialState},
    reducers:{
        clearDashboardProperties:(dashboardView,action)=>{
            dashboardView.campaigns = [];
        }, 
        currentPageNumberSelected:(dashboardView,action)=>{
            const {pageNumber,campaigns} = action.payload;
            dashboardView.pageNumber = pageNumber;
            dashboardView.query.pageNumber = pageNumber;
        },
        campaignsForDashboardUIViewReceived:(dashboardView,action)=>{
            const {view} = action.payload;
            const campaignList = action.payload[view];
            const {data} = campaignList;
            const campaignIdList  = _.map(data,'id');
            dashboardView.campaigns = campaignIdList;
            
        },
        keywordFieldUpdated:(dashboardView,action)=>{
            const {keyword} = action.payload;
            dashboardView.query.keyword = keyword;
            dashboardView.query.sortForFilter="";
            dashboardView.query.pageNumber = 1;
        },
    },
})


//--------Export reducers and actions-----------------
export const {
    clearDashboardProperties,
    currentPageNumberSelected,
    campaignsForDashboardUIViewReceived,
    keywordFieldUpdated,
} = slice.actions;

export default slice.reducer;


//---------Export action creators-------------------------
export const clearDashboardUIProperties = ()=>dispatch=>{
    dispatch(clearDashboardProperties())
}

export const setCurrentPageNumber = pageNumber=>dispatch=>{
    dispatch(currentPageNumberSelected({pageNumber}))
}

export const setKeywordSearchField = (keyword)=>dispatch=>{
    dispatch(keywordFieldUpdated({keyword}))
}

//----------Get selectors------------------------------

export const getCampaignIdListForDashboard = createSelector(
    state=>state.ui.dashboard,
    dashboard=>dashboard.campaigns
)