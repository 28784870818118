import { createSlice } from "@reduxjs/toolkit";

import {createSelector} from "reselect";
import _ from "lodash";
import moment from "moment";

import { apiCallBegan } from "./api"


import applicationConstants from "./applicationConstants";

const {
    ENDPOINT_FOR_CAMPAIGN_SLICE,
    ENDPOINT_FOR_CLIENT_SLICE,
    REFETCH_THRESHOLD_IN_MINUTES,
} = applicationConstants

/*
initializationGlobalObject = {
    jobelephantClientRecord:{
        id:1000
        active:1
        name:"Jobelephant"
        address:"5000 MacArthur Blvd"
        city:"Oakland"
        state:"California"
        zipcode:"94613"
        country:"United States"
        phone:"510-430-2071"
        fax:null
        mobile:null
        email:"hrhelp@mills.edu"
        web:"http://www.mills.edu/"
        usTaxId:null  
    },
}
*/

//Slice: Create reducers and actions
const slice = createSlice({
    name:"globalProperties",
    initialState:{
        jobelephantClientRecord:"",
        countryList:[],
        loading:false,
        lastFetch:null,
    },
    reducers:{
        countryListReceived:(globalProperties,action)=>{
            //console.log(action);
            const countryList = action.payload;
            globalProperties.countryList = countryList;
        },
        countryListRequested:(globalProperties,action)=>{
            
        },
        countryListRequestFailed:(globalProperties,action)=>{
            
        },
        jobelephantClientRecordReceived:(globalProperties,action)=>{
            const jobelephantClientRecord = action.payload;
            globalProperties.jobelephantClientRecord = jobelephantClientRecord;
            globalProperties.loading = false;
            globalProperties.lastFetch = Date.now();
        },
        jobelephantClientRecordRequested:(globalProperties,action)=>{
            globalProperties.loading = true;
        },
        jobelephantClientRecordRequestFailed:(globalProperties,action)=>{
            globalProperties.loading = false;
        },
        
        
    }

});

//---------------Actions & Reducer export setup----------------------------
const {
    countryListReceived,
    countryListRequested,
    countryListRequestFailed,
    jobelephantClientRecordReceived,
    jobelephantClientRecordRequested,
    jobelephantClientRecordRequestFailed,
    
}  = slice.actions;

//Export reducer
export default slice.reducer;
//---------------End of Actions & Reducer export setup----------------------------

//-------------Export Action Creators-----------------------

/*Fetch from country list of records */
export const loadCountryList = ()=>(dispatch,getState)=>{
    const  {globalProperties} = getState();
    const {countryList} = globalProperties;
    if(countryList.length > 0){
        return;
    }
    
    const data = {}
    dispatch(apiCallBegan({
        url:`${ENDPOINT_FOR_CAMPAIGN_SLICE}/getCountryList`,
        method:'get',
        onStart:[countryListRequested.type],
        onSuccess:[countryListReceived.type],
        onError:[countryListRequestFailed.type],
    }))

}

/*Fetch from database list of records */
export const loadJobelephantClientRecord = ()=>(dispatch,getState)=>{
        const  {globalProperties} = getState();
        const {lastFetch,jobelephantClientRecord} = globalProperties;
        if(jobelephantClientRecord !== ""){
            const diffInMinutes = moment().diff(moment(lastFetch),'minutes');
            if(diffInMinutes < REFETCH_THRESHOLD_IN_MINUTES) return;
        }
        
        const data = {}
        dispatch(apiCallBegan({
            url:`${ENDPOINT_FOR_CLIENT_SLICE}/getJobelephantClientRecord`,
            method:'get',
            data,
            onStart:[jobelephantClientRecordRequested.type],
            onSuccess:[jobelephantClientRecordReceived.type],
            onError:[jobelephantClientRecordRequestFailed.type],
        }))
    
}



//------------------Selectors-----------------------

export const getCountryList = createSelector(
    state=>state.globalProperties,
    globalProperties=>{
        return globalProperties.countryList;
    }
);


export const getJobelephantClientRecord = createSelector(
    state=>state.globalProperties,
    globalProperties=>{
        return globalProperties.jobelephantClientRecord;
    }
);



//----------------End of Selectors-----------------------


