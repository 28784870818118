import { createSlice } from "@reduxjs/toolkit";
import {createSelector} from "reselect";
import _ from "lodash";


//-------Slice: Create reducers and actions--------
const slice  = createSlice({
    name:"resetPasswordScreenView",
    initialState:{
        data:{},
        errors:[],
        loading:false,
    },
    reducers:{
        errorUICleared:(resetPasswordScreenView,action)=>{
            resetPasswordScreenView.errors = [];
            resetPasswordScreenView.loading = false;
        },
        errorUIDisplayed:(resetPasswordScreenView,action)=>{
            const {error} = action.payload;
            resetPasswordScreenView.errors = error.list;
            resetPasswordScreenView.loading = false;
        },
        loadingIndicatorUIStart:(resetPasswordScreenView,action)=>{
            resetPasswordScreenView.loading = true;
        },
        loadingIndicatorUIEnd:(resetPasswordScreenView,action)=>{
            resetPasswordScreenView.loading = false;
        },
    }

});

//--------Export reducers and actions-----------------
export const {
    errorUICleared,
    errorUIDisplayed,
    loadingIndicatorUIStart,
    loadingIndicatorUIEnd,
} = slice.actions;

export default slice.reducer;

//---------Action creators-------------------------------

export const clearErrors = ()=>dispatch=>{
    dispatch(errorUICleared())
}

//----------Get selectors------------------------------
export const getServerErrors = createSelector(
    state=>state.ui.resetPasswordScreenView,
    resetPasswordScreenView=> resetPasswordScreenView.errors
)

