import React, { Fragment } from 'react';
import cx from 'classnames';
import { useParams,useHistory } from 'react-router-dom'
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import {
  SidebarJe,
  HeaderRequestQuote,
  HeaderCampaign,
  HeaderCampaignDetail,
  Footer,
  FooterRequestQuote,
  ThemeConfigurator
} from '../../layout-components';

const getHeader = (headerType,urlParams,useHistoryProps)=>{
  switch(headerType){
    case "RequestQuote":
      return < HeaderRequestQuote useHistory={useHistoryProps}
      />
      
    
  }
}

const getBackgroundImageClass  = (headerType)=>{
  let backgroundImageClass = "";
  switch(headerType){
    case "RequestQuote":
      backgroundImageClass = "background-image-request-quote";
      break;  
  }

  return backgroundImageClass
}

const HeaderFooterLayout = props => {
  const {
    children,
    header,
    headerFixed,
    footerFixed,
    contentBackground,
    themeConfiguratorToggle
  } = props;

  const urlParams = useParams();
  const useHistoryProps = useHistory();
  

  const headerNotFixed = !headerFixed
  const backgroundImageClass = getBackgroundImageClass(header);

  return (
    <Fragment>
      <div
        className={cx('app-wrapper',backgroundImageClass, contentBackground, {
          'app-header-fixed': headerNotFixed,
          'app-footer-fixed': footerFixed,
        })}>
        <div className="app-main">
          {getHeader(header,urlParams,useHistoryProps)}
          <div className="app-content">
            {children}
          </div>
        </div>
        <ThemeConfigurator />
      </div>
    </Fragment>
  );
};

HeaderFooterLayout.propTypes = {
  header:PropTypes.string.isRequired,
  children: PropTypes.node
};

const mapStateToProps = state => ({
  headerFixed: state.ThemeOptions.headerFixed,
  footerFixed: state.ThemeOptions.footerFixed,
  contentBackground: state.ThemeOptions.contentBackground,
  themeConfiguratorToggle: state.ThemeOptions.themeConfiguratorToggle
});


export default connect(mapStateToProps)(HeaderFooterLayout);
