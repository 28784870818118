import { createSlice } from "@reduxjs/toolkit";
import {createSelector} from "reselect";
import moment from "moment";
import _ from "lodash";
import {scrollTop} from '../utils/helperUtils';


import applicationContants from "./applicationConstants";

const {

    MANAGE_ACCOUNT_TAB_PROFILE,
    MANAGE_ACCOUNT_TAB_COMPANY,
    MANAGE_ACCOUNT_TAB_USERS,

    PAGINATION_LIMIT,

    SORT_ORDER_ASCEND,
} = applicationContants;

const DEFAULT_SORT_FIELD_1 = "last_name";


const divErrorArea = "errorArea";

const initialState = {
    activeTabView:MANAGE_ACCOUNT_TAB_PROFILE,
    personalProfile:{
        resetPasswordFlag:false,
        errors:[],
        success:"", //This will display a notification message that can be removed
    },
    notifcationSetting:{
        errors:[],
        success:"", //This will display a notification message that can be removed
    },
    users:{
        query:{
            clientId:"",
            sortForFilter:"",   
            paginationLimit:PAGINATION_LIMIT,
            pageNumber:1,
            defaultSort:[
                {
                    sortBy:DEFAULT_SORT_FIELD_1,
                    sortOrder:SORT_ORDER_ASCEND,
                },
            ],
        },
        defaultSortIndicator:{},
        totalCount:0,
        currentPageNumber:1,
        userIdListPerPage:[], //We update this anytime we load from database
        errors:[], //Display any loading errors from the database
    },
    addUser:{
        userIdCreated:"",
        errors:[],
        success:"", //This will display a notification message that can be removed
    },
    editUser:{
        errors:[],
        success:"", //This will display a notification message that can be removed
    },
    loading:false, //This is to display the indicator
}

//-------Slice: Create reducers and actions--------
const slice  = createSlice({
    name:"manageAccountView",
    initialState,
    reducers:{
        addUserUICleared:(manageAccountView,action)=>{
            manageAccountView.addUser = {
                errors:[],
                success:"", 
            };
        },
        addUserUIOnError:(manageAccountView,action)=>{
            // console.log(action.payload)
            /*  Load the error array*/
            const {error} = action.payload;
            manageAccountView.addUser.errors = error.list;
            manageAccountView.loading = false;
        },
        addUserUISaveInProgress:(manageAccountView,action)=>{
            manageAccountView.loading = true;
        },
        addUserUISaved:(manageAccountView,action)=>{
            const {successNotification,userForClientViewUpdate:user} = action.payload;
            const {id:userId} = user;

            const {message} = successNotification
            //manageAccountView.addUser.success = message;
            manageAccountView.addUser.userIdCreated = userId;
            manageAccountView.users.userIdListPerPage = [];
            manageAccountView.loading = false;
        },
        editUserUICleared:(manageAccountView,action)=>{
            manageAccountView.editUser = {
                errors:[],
                success:"", 
            };
        },
        editUserUIErrorCleared:(manageAccountView,action)=>{
            manageAccountView.editUser.errors = [];
        },
        editUserUISaveInProgress:(manageAccountView,action)=>{
            manageAccountView.loading = true;
        },
        editUserUISaved:(manageAccountView,action)=>{
            const {successNotification} = action.payload;
            const {message} = successNotification
            manageAccountView.editUser.success = message;
            manageAccountView.loading = false;
        },
        editUserUIOnError:(manageAccountView,action)=>{
            // console.log(action.payload)
            /*  Load the error array*/
            const {error} = action.payload;
            manageAccountView.editUser.errors = error.list;
            manageAccountView.loading = false;
        },
        loadingIndicatorUIStart:(manageAccountView,action)=>{
            manageAccountView.loading = true;
        },
        loadingIndicatorUIEnd:(manageAccountView,action)=>{
            manageAccountView.loading = false;
        },
        notificationSettingUICleared:(manageAccountView,action)=>{
            manageAccountView.notifcationSetting = {
                errors:[],
                success:"", 
            };
        },
        notificationSettingUIErrorCleared:(manageAccountView,action)=>{
            manageAccountView.notifcationSetting.errors = [];
        },
        notificationSettingUIOnError:(manageAccountView,action)=>{
            // console.log(action.payload)
            /*  Load the error array*/
            const {error} = action.payload;
            manageAccountView.notifcationSetting.errors = error.list;
            manageAccountView.loading = false;
        },
        notificationSettingUISaveInProgress:(manageAccountView,action)=>{
            manageAccountView.loading = true;
        },
         
        notificationSettingUISaved:(manageAccountView,action)=>{
            const {successNotification} = action.payload;
            const {message} = successNotification
            manageAccountView.notifcationSetting.success = message;
            manageAccountView.loading = false;
        },
        
        personalProfileUICleared:(manageAccountView,action)=>{
            manageAccountView.personalProfile = {
                resetPasswordFlag:false,
                errors:[],
                success:"", 
            };
        },
        personalProfileUIErrorCleared:(manageAccountView,action)=>{
            manageAccountView.personalProfile.errors = [];
        },
        personalProfileUIOnError:(manageAccountView,action)=>{
           // console.log(action.payload)
           /*  Load the error array*/
           const {error} = action.payload;
           manageAccountView.personalProfile.errors = error.list;
           manageAccountView.loading = false;
        },
        personalProfileUISaveInProgress:(manageAccountView,action)=>{
             manageAccountView.loading = true;
        },
        personalProfileUISaved:(manageAccountView,action)=>{
            //console.log(action.payload)
            /* set the successIndicator to true 
            
                Need to set the following:
                JWT token needs to get updated
                Redux:
                auth.loggedInUser
                    .activeView.user
                    .accessControlList.user
            
            */
            const {successNotification} = action.payload;
            const {message} = successNotification
            manageAccountView.personalProfile.success = message;
            manageAccountView.loading = false;
        },
        personalProfileUIResetPasswordInProgress:(manageAccountView,action)=>{
            manageAccountView.loading = true;
        },
        personalProfileUIResetPasswordSaved:(manageAccountView,action)=>{
            manageAccountView.personalProfile.resetPasswordFlag = true;
            manageAccountView.loading = false;
        },
        selectedActiveTabView:(manageAccountView,action)=>{
            const {tabView:activeTabView}  = action.payload;
            manageAccountView.activeTabView = activeTabView;
        },
        userListUILoadingInProgress:(manageAccountView,action)=>{
            manageAccountView.users.errors = [];
            manageAccountView.loading = true;
        },
        userListUIReceived:(manageAccountView,action)=>{
            const {clientId,users,totalCount,pageNumber} = action.payload;
            manageAccountView.users.totalCount = totalCount;
            manageAccountView.users.currentPageNumber = pageNumber;
            manageAccountView.users.userIdListPerPage = [];
            users.forEach(u=>{
                const {id} = u;
                const index = manageAccountView.users.userIdListPerPage.indexOf(id);
                if(index === -1) 
                  manageAccountView.users.userIdListPerPage.push(id)
            })
            manageAccountView.loading = false;
        },
        userListUIOnError:(manageAccountView,action)=>{
            //  console.log(action.payload)
            /*  Load the error array  */
            const {error} = action.payload;
            manageAccountView.users.errors = error.list;
            manageAccountView.loading = false;
        },
        userListUIQueryPageNumberSelected:(manageAccountView,action)=>{
            const {pageNumber} = action.payload;
            manageAccountView.users.query.pageNumber = pageNumber;
        },
        
        
    },
})


//--------Export reducers and actions-----------------
export const {
    addUserUICleared,
    addUserUIOnError,
    addUserUISaveInProgress,
    addUserUISaved,
    editUserUICleared,
    editUserUIErrorCleared,
    editUserUISaveInProgress,
    editUserUISaved,
    editUserUIOnError,
    loadingIndicatorUIStart,
    loadingIndicatorUIEnd,
    notificationSettingUICleared,
    notificationSettingUIErrorCleared,
    notificationSettingUIOnError,
    notificationSettingUISaveInProgress,
    notificationSettingUISaved,
    personalProfileUICleared,
    personalProfileUIErrorCleared,
    personalProfileUIOnError,
    personalProfileUISaveInProgress,
    personalProfileUISaved,
    personalProfileUIResetPasswordInProgress,
    personalProfileUIResetPasswordSaved,
    selectedActiveTabView,
    userListUILoadingInProgress,
    userListUIReceived,
    userListUIOnError,
    userListUIQueryPageNumberSelected,
    userListUICurrentPageNumberSelected,
} = slice.actions;

export default slice.reducer;

//---------Export action creators-------------------------
export const clearAddUserUI= ()=>dispatch=>{
    dispatch(addUserUICleared())
}

export const clearEditUserUI= ()=>dispatch=>{
    dispatch(editUserUICleared())
}

export const clearEditUserProcessingError = ()=>dispatch=>{
    dispatch(editUserUIErrorCleared())
}

export const clearPersonalProfileUI= ()=>dispatch=>{
    dispatch(personalProfileUICleared())
}

export const clearPersonalProfileProcessingError = ()=>dispatch=>{
    dispatch(personalProfileUIErrorCleared())
}

export const clearUserNotificationSettingUI= ()=>dispatch=>{
    dispatch(notificationSettingUICleared())
}


export const setActiveTabView = tabView=>dispatch=>{
    dispatch(selectedActiveTabView({tabView}))
}

export const setCurrentPageNumberForQuery = pageNumber=>dispatch=>{
    dispatch(userListUIQueryPageNumberSelected({pageNumber}))
}


//----------Get selectors------------------------------

export const getActiveTabView = createSelector(
    state=>state.ui.manageAccountView,
    manageAccountView=>manageAccountView.activeTabView
)

export const getAddUserProcessingErrors = createSelector(
    state=>state.ui.manageAccountView,
    manageAccountView=>manageAccountView.addUser.errors
)

export const getEditUserProcessingErrors = createSelector(
    state=>state.ui.manageAccountView,
    manageAccountView=>manageAccountView.editUser.errors
)

export const getNewUserId= createSelector(
    state=>state.ui.manageAccountView,
    manageAccountView=>manageAccountView.addUser.userIdCreated
)

export const getNotificationSettingsProcessingErrors = createSelector(
    state=>state.ui.manageAccountView,
    manageAccountView=>manageAccountView.notifcationSetting.errors
)



export const getPersonalProfileProcessingErrors = createSelector(
    state=>state.ui.manageAccountView,
    manageAccountView=>manageAccountView.personalProfile.errors
)

export const getProcessingIndicatorFlag = createSelector(
    state=>state.ui.manageAccountView,
    manageAccountView=>manageAccountView.loading
)

export const getResetPasswordStatusFlag = createSelector(
    state=>state.ui.manageAccountView,
    manageAccountView=>manageAccountView.personalProfile.resetPasswordFlag
)

export const getSuccessMessageForAddUserView = createSelector(
    state=>state.ui.manageAccountView,
    manageAccountView=>manageAccountView.addUser.success
)


export const getSuccessMessageForEditUserView = createSelector(
    state=>state.ui.manageAccountView,
    manageAccountView=>manageAccountView.editUser.success
)

export const getSuccessMessageForPersonalProfileView = createSelector(
    state=>state.ui.manageAccountView,
    manageAccountView=>manageAccountView.personalProfile.success
)

export const getSuccessMessageForNotificationSettingView = createSelector(
    state=>state.ui.manageAccountView,
    manageAccountView=>manageAccountView.notifcationSetting.success
)

export const getUserListProcessingErrors = createSelector(
    state=>state.ui.manageAccountView,
    manageAccountView=>manageAccountView.users.errors
)

export const getUserIdListForPaginatedView = createSelector(
    state=>state.ui.manageAccountView,
    manageAccountView=>manageAccountView.users.userIdListPerPage
)

export const getPaginationProperiesForUserListView = createSelector(
    state=>state.ui.manageAccountView,
    manageAccountView=>{
        return{
            currentPage:manageAccountView.users.currentPageNumber,
            totalCount:manageAccountView.users.totalCount,
        }
        
    }
)





