import React,{Component,useEffect} from "react";
import {Route,Redirect} from "react-router-dom";
import auth from '../../services/authService';

/*
 <Route expects component or render 
 compnent is dynamic and lowercase components is invalid hence component:Component
 
 render is a function to be called by Route hence the function call is executed inside
 render = ()=>{
   if 
     no user then redirect to login
   else{
     <Component />
     or
     render(props)
   }
 }
 
*/
const ProtectedRoute = (props)=>{
  const {path,component:Component,render,title,...rest} = props;
    //console.log(window._paq);
	useEffect(()=>{
		if(title){
			let documentTitle = `${window.__RUNTIME_CONFIG__['REACT_APP_PAGE_TITLE']}: ${title}`
			window.document.title = documentTitle
			//console.log(window.location.pathname)
		    window._paq.push(['setCustomUrl', window.location.pathname]);
			window._paq.push(['setDocumentTitle', documentTitle]);
			window._paq.push(['trackPageView']);
		}
	},[title])

  return(
    <Route
      {...rest}
	  render={props=>{
	    //console.log(props);
	    if(!auth.getCurrentUser()){ 
		  return(
		    <Redirect 
		       to={
		         {
		           pathname:"/login",
		           state:{from:props.location}
		         }
		       }
		       
		    />
		  );   
		}

		const {pathname} = props.location;
		

		/* If JE admin then user id is not selected
			 - go to the select user screen 
		*/
		const {isJEAdministrator,resetPasswordFlag} = auth.getCurrentUser();
		const activeUserId = auth.getActiveUserId();
		//console.log(pathname);

		if(resetPasswordFlag === true && pathname !== "/reset-password"){
			return(
				<Redirect 
					to={
						{
						pathname:"/reset-password",
						state:{from:props.location}
						}
					}
				/>
			);
		}
		else if(resetPasswordFlag === false){	
			if(isJEAdministrator && !activeUserId && pathname !== "/select-user"){
				return(
					<Redirect 
						to={
							{
							pathname:"/select-user",
							state:{from:props.location}
							}
						}
					/>
				);
			}

			/* If a client and client account is not selected 
				client admin/user/billinguser -> go to the select client account screen
				je user -> go to the select user screen 
			*/
			const isActiveClientAccountSelected = auth.getActiveView();
			if(!isActiveClientAccountSelected && activeUserId && pathname !== "/select-account"){
				return(
					<Redirect 
						to={
							{
							pathname:"/select-account",
							state:{from:props.location}
							}
						}
					/>
				);
			}
		}

		
		if(Component)
		  return <Component {...props} />
		else 
		  return render(props)   
	  }}
    />
  
  )
}


  
export default ProtectedRoute;
  

