import React, {Fragment, useState, Component} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import cx from 'classnames';
import {Link} from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import accounting from "accounting";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Row, Table, Col, CardBody, Card, CardTitle, CardHeader, Collapse, UncontrolledPopover, Container, InputGroup, InputGroupAddon, InputGroupText, FormText, Form, CustomInput, Label, FormGroup, Input, Badge, ListGroup, ListGroupItem, PopoverHeader, PopoverBody, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, Progress, DropdownToggle, DropdownMenu, Alert, DropdownItem } from 'reactstrap'

import {connect} from 'react-redux';

import {
    getActiveClientAccount,
} from '../../store/auth'

import { 
    approveCampaignItemFromReviewArtwork,
    getCampaign,
    getCampaignDetails,
    stopAdForCampaignItemsList,
} from "../../store/campaigns"

import {
    clearErrorsForCampaignItemsPerView,
    clearErrorsForCampaignItemsFromReviewArtworkScreen,
    clearSuccessNotificationsForCampaignItemsPerView,
    getSelectedCampaignItemsPerView,
} from "../../store/ui-campaign-details";


import {
    setSidebarToggleMobile
} from '../../reducers/ThemeOptions';

import applicationConstants from "../../store/applicationConstants"

const {
    AD_STATUS_DB_AWAITING_APPROVAL,
    CAMPAIGN_DETAILS_TAB_READY_FOR_APPROVAL,
    CAMPAIGN_DETAILS_TAB_PUBLISHED,  
    CAMPAIGN_DETAILS_TAB_PROCESSING,
} = applicationConstants;


class HeaderCampaignDetailItemReviewArtwork extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          
        };
    }

    approveIndividualItemForPublishing = (item)=>{
        const { approveCampaignItemForPublishing,clearErrors,urlParameters  } = this.props;
        const {
            campaignId,
            campaignItemId,
        } = urlParameters;
        clearErrors(campaignId);
        return approveCampaignItemForPublishing({
            campaignId,
            campaignItems:[item]
        })

    }

    toggleSidebarMobile = () => {
        let {sidebarToggleMobile, setSidebarToggleMobile} = this.props;
        setSidebarToggleMobile(!sidebarToggleMobile);
    }

    render() {
        let {
            headerShadow,
            headerBgTransparent,
            sidebarToggleMobile,
            campaign,  
            campaignDetails,  
            clientAccountName,
            urlParameters,
        } = this.props;

        const {
            campaignId,
            campaignItemId
        } = urlParameters;
        
        const campaignDetailItem = (campaignItemId && campaignDetails && !_.isEmpty(campaignDetails)) ? campaignDetails.byIds[campaignItemId]:null;
        //console.log(campaignDetailItem);

        let [
            adStatus,
            isArtworkAcknowledgedByClient,
            isArtworkDocumentOpenedByClient,
            publicationName,
            price
        ] = ["","",0,false,false]
        if(campaignDetailItem){
            ({adStatus,isArtworkAcknowledgedByClient,isArtworkDocumentOpenedByClient,publicationName,price} = campaignDetailItem)
        }
        

        const campaignLoaded = (campaign) ? true:false;
        let [campaignTitle,jobNumber,itemCounts] = ["","",0];
        if(campaignLoaded){
            ({name:campaignTitle,jobNumber} = campaign);
        } 

        if(campaignDetails){
            ({itemCounts} = campaignDetails);
        }

        return (
            <Fragment>
                <div className={cx("app-header", {'app-header--shadow': headerShadow, 'app-header--opacity-bg': headerBgTransparent})}>
                <div className="app-header--pane">
                    
                    <button className={cx("navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn", {'is-active': sidebarToggleMobile})} onClick={this.toggleSidebarMobile}>
                        <span className="hamburger-box">
                            <span className="hamburger-inner"/>
                        </span>
                    </button>
                    <div className="je-header-page-title mb-3">
                        <div className=" text-center">
                            { !campaignDetailItem && <div className="width-50px"><Skeleton count={1} /></div>}
                            {
                                campaignDetailItem &&
                                <>
                                    <div className="float-left mt-1 je-header-campaign-detail-page-title">
                                        {`${publicationName} Publication`}
                                    </div>
                                </>
                            }
                            
                            <div className="clearfix"></div>
                        </div>
                        <div className="je-header-campaign-detail-page-description text-black-50">
                            { !campaignDetailItem && <div className="width-50px"><Skeleton count={1} /></div>}
                            { 
                              campaignDetailItem && <>{`Please review and approve your print ad`}</>
                            }
                        </div>
                        
                    </div>
                    
                </div>
                <div className="app-header--pane">
                    <>
                        {/* Price Display */}
                        <div className="text-right text-success mr-3">
                            <small className="text-uppercase">Price:</small>
                            <h2 className="m-0 font-weight-bold text-primary">{accounting.formatMoney(price)}</h2>
                        </div>
                        
                        {/* End of Price Display */}
                        
                        {/* Approve Button */}
                        <div className="">
                            {
                                adStatus === AD_STATUS_DB_AWAITING_APPROVAL && 
                                isArtworkAcknowledgedByClient === true && 
                                isArtworkDocumentOpenedByClient === true &&
                                <Button color="success " className="m-2 pl-3"
                                    onClick={e=>{
                                        e.preventDefault();
                                        this.approveIndividualItemForPublishing(campaignDetailItem)
                                    }}
                                >
                                    <span className="btn-wrapper--label"><strong>Approve</strong></span>
                                </Button>
                            }

                            {
                                adStatus === AD_STATUS_DB_AWAITING_APPROVAL && 
                                (isArtworkAcknowledgedByClient === false || isArtworkDocumentOpenedByClient === false) &&
                                <>
                                    <Button id="approveButton" color="success " onClick={e=>e.preventDefault()} className="m-2 pl-3 cursor-not-allowed"
                                    >
                                        <span className="btn-wrapper--label"><strong>Approve</strong></span>
                                    </Button>
                                    <UncontrolledTooltip
                                        placement="left"
                                        container="body"
                                        target='approveButton'>
                                        Please review the artwork and click on the checkbox to approve the artwork. 
                                    </UncontrolledTooltip>
                                </>
                            }
                            
                        </div>
                        {/* End of Approve Button */}
                    </>
                </div>
            </div>
            </Fragment>
    );
    }
}

const mapStateToProps = (state,ownProps) => ({
    campaign:getCampaign(ownProps.urlParameters.campaignId)(state),
    campaignDetails:getCampaignDetails(ownProps.urlParameters.campaignId)(state),
    clientAccountName:getActiveClientAccount(state).name || "",
    headerShadow: state.ThemeOptions.headerShadow,
    headerBgTransparent: state.ThemeOptions.headerBgTransparent,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
    clearErrors:(campaignId)=>dispatch(clearErrorsForCampaignItemsFromReviewArtworkScreen(campaignId)),
    clearSuccessNotifications:(campaignItemObject)=>dispatch(clearSuccessNotificationsForCampaignItemsPerView(campaignItemObject)),
    approveCampaignItemForPublishing:(campaignItemsObject)=>dispatch(approveCampaignItemFromReviewArtwork(campaignItemsObject)),
    setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
    stopAdForCampaignItemList:(campaignItemObject)=>dispatch(stopAdForCampaignItemsList(campaignItemObject)),


});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCampaignDetailItemReviewArtwork);
